import CardItem from "components/CardItem";
import { useTranslations } from "hooks/translation";
import { Link } from "react-router-dom";

const Shop = () => {
    const [TITLE, CONTENT, MONNEY, VISIT_SHOP, BE_CREATIVE, SMART_SPEND] =
        useTranslations(i18nKeys);

    return (
        <section className="flex items-center justify-center flex-1 mt-10">
            <div className="flex flex-col items-center">
                <CardItem bigCard>
                    <div className="flex flex-col items-center text-secondary text-center py-[15px] px-[50px]">
                        <h3 className="mt-[20px] animate__animated animate__fadeIn" style={{animationDelay: '100ms'}}>{TITLE}</h3>
                        <hr className="mt-[15px] h-[1px] w-[150px] bg-secondary" />
                        <p className="mt-[33px] animate__animated animate__fadeIn" style={{animationDelay: '300ms'}}>{CONTENT}</p>
                        <p className="mt-[20px] animate__animated animate__fadeIn" style={{animationDelay: '300ms'}}>{MONNEY}</p>
                        <p className="animate__animated animate__fadeIn" style={{animationDelay: '300ms'}}>{BE_CREATIVE},</p>
                        <p className="animate__animated animate__fadeIn" style={{animationDelay: '300ms'}}>{SMART_SPEND}.</p>
                    </div>
                </CardItem>
                <div className="sm:mt-[50px] mt-[25px]">
                    <a href="https://shop.jain-music.com/">
                        <button type="button" className="btn-contained animated-button animate__animated animate__flipInX" style={{animationDelay: '500ms'}}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <p className="font-bold text-[22px]">{VISIT_SHOP}</p>
                        </button>
                    </a>
                </div>
            </div>
        </section>
    );
};

const i18nKeys = [
    "App.Shop.DayPrediction.Title",
    "App.Shop.DayPrediction.Content",
    "App.Shop.DayPrediction.Monney",
    "App.Shop.DayPrediction.VisitShop",
    "App.Shop.DayPrediction.BeCreative",
    "App.Shop.DayPrediction.SmartSpend"
];

export default Shop;
