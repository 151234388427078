import { Easing, Tween } from "@tweenjs/tween.js"
import { useCallback, useRef } from "react"
import { Object3D } from "three"
import { ThreeDAnimationCallbackParams } from "../use3DAnimation"
import { use3DObject } from "../use3DObject"
import { use3DElementFromTexture } from "../use3DTexture"
import { useThreeJsAnimations } from "../useThreeJsAnimations"

export const useElementFortuneWheel = () => {

    const loadImage = use3DElementFromTexture()
    const getObject = use3DObject()
    const { bounce, rotateZ } = useThreeJsAnimations()

    const element = useRef<Object3D>()

    const fortuneWheelFn = useCallback(async () => {
        const [wheel, cloud, arrow] = await Promise.all([
            loadImage({
                path: '/assets/img/texture/fortune-wheel-4.png',
                width: 15
            }),
            loadImage({
                path: '/assets/img/texture/fortune-wheel-2.png',
                width: 25
            }),
            loadImage({
                path: '/assets/img/texture/fortune-wheel-3.png',
                width: 25
            }),
        ])

        const obj = getObject([wheel, cloud, arrow])
        wheel.position.x = 0.5
        wheel.position.y = 0.5
        cloud.position.z = 1.3
        arrow.position.z = 1.4

        bounce(arrow.scale, 0.8, 1.2, 2000).start()
        bounce(cloud.scale, 0.8, 1.2, 8000).start()
        rotateZ({
            rotation: wheel.rotation,
            angle: Math.PI * 2,
            duration: 15000,
            behavior: Easing.Bounce.InOut
        }).start()

        element.current = obj
        return obj

    }, [])

    const animation = useCallback((params?: ThreeDAnimationCallbackParams) => {
        
        element.current?.lookAt(params?.camera.position!)
    }, [])

    return {
        fortuneWheelFn,
        animation
    }
}

const name = 'ASTRONAUTE'
