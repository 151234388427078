import { useBreakpoints } from "hooks/utils/useBreakpoints"
import { useCallback, useRef } from "react"
import { Object3D } from "three"
import { ThreeDAnimationCallbackParams } from "../use3DAnimation"
import { use3DObject } from "../use3DObject"
import { use3DElementFromTexture } from "../use3DTexture"
import { useThreeJsAnimations } from "../useThreeJsAnimations"

export const useElementTheFool = (hideSunRay?: boolean) => {

    const loadImage = use3DElementFromTexture()
    const getObject = use3DObject()
    const { bounce, rotateZ, fadeInOut } = useThreeJsAnimations()
    const { sm } = useBreakpoints()

    const element = useRef<Object3D>()

    const theFoolLoadFn = useCallback(async () => {
        const [sunRay, moon, clouds, rose] = await Promise.all([
            loadImage({
                path: '/assets/img/texture/sun-ray-blue.png',
                width: 350
            }),
            loadImage({
                path: '/assets/img/texture/the-fool-1.png',
                width: sm ? 60 : 80
            }),
            loadImage({
                path: '/assets/img/texture/the-fool-2.png',
                width: sm ? 60 : 80
            }),
            loadImage({
                path: '/assets/img/texture/the-fool-3.png',
                width: sm ? 60 : 80
            }),
        ])

        const obj = getObject(hideSunRay ? [moon, clouds, rose] : [sunRay, moon, clouds, rose])
        obj.name = 'the-fool'
        sunRay.position.z = 0.9
        moon.name = 'the-fool'
        moon.position.z = 1
        clouds.position.z = 1.1
        rose.position.z = 1.2

        bounce(moon.scale, 0.9, 1.2, 8000).start()

        bounce(clouds.scale, 0.9, 1.2, 7000).start()
        rotateZ({
            rotation: clouds.rotation,
            angle: Math.PI / 6,
            duration: 25000
        }).start()

        bounce(rose.scale, 0.9, 1.2, 7000).start()
        rotateZ({
            rotation: rose.rotation,
            angle: Math.PI / 6,
            duration: 20000
        }).start()

        sunRay.position.z = -10;
        fadeInOut({
            material: sunRay.material as any, 
            minOpacity: 0.5, 
            duration: 5000
        }).start()
        rotateZ({
            rotation: sunRay.rotation,
            angle: Math.PI * 2,
            duration: 25000
        }).start()
        element.current = obj
        return obj
    }, [])

    const animation = useCallback((params?: ThreeDAnimationCallbackParams) => {
        
        element.current?.lookAt(params?.camera.position!)
    }, [])

    return {
        theFoolLoadFn,
        animation
    }
}

