import { useBreakpoints } from 'hooks/utils/useBreakpoints';
import { useElementAstronaute } from './useElementAstronaute';
import { useElementPlanetTwins } from './useElementPlanetTwins';
import { use3DElementFromTexture } from './../use3DTexture';
import { useCallback, useRef } from "react"
import { Object3D } from "three"
import { ThreeDAnimationCallbackParams } from "../use3DAnimation"
import { use3DObject } from "../use3DObject"
import { useElementPlanetExplosion } from "./useElementPlanetExplosion"
import { useElementTheFool } from "./useElementTheFool"
import { useElementSun } from './useElementSun';
import { useElementStarsBlink } from './useElementStarsBlink';
import { useElementLoveMissile } from './useElementLoveMissile';
import { useElementMoonSun } from './useElementMoonSun';
import { useElementAngel } from './useElementAngel';
import { useElementPlanetMissile } from './useElementPlanetMissile';
import { useElementFortuneWheel } from './useElementFortuneWheel';
import _ from 'lodash';

export const useElementOrbit = ({ hideSunRay, onlyTheFool }: ElementOrbitParams) => {


    const getObject = use3DObject()
    const { planetLoadFn, animation: planetAnimation } = useElementPlanetExplosion()
    const { theFoolLoadFn, animation: theFoolAnimation } = useElementTheFool(hideSunRay)
    const {planetTwinsLoadFn, animation: planetTwinsAnimation} = useElementPlanetTwins()
    const {planetStarsBlinkLoadFn, animation: starsBlinkAnimation} = useElementStarsBlink()
    const {sunLoadFn, animation: sunAnimation} = useElementSun()
    const {planetLoveMissileFn, animation: loveMissileAnimation} = useElementLoveMissile()
    const {planetMoonSunFn, animation: moonSunAnimation} = useElementMoonSun()
    const {astronauteFn, animation: astronauteAnimation} = useElementAstronaute()
    const {angelFn, animation: angelAnimation} = useElementAngel()
    const {planetMissileFn, animation: planetMissileAnimation} = useElementPlanetMissile()
    const {fortuneWheelFn, animation: fortuneWheelAnimation} = useElementFortuneWheel()

    const planetExplositionRotator = useRef<Object3D>()
    const planetTwinsRef = useRef<Object3D>()
    const planetStarsBlink = useRef<Object3D>()
    const loveMissileRef = useRef<Object3D>()
    const moonSunRef = useRef<Object3D>()
    const astronauteRef = useRef<Object3D>()
    const angelRef = useRef<Object3D>()
    const planetMissileRef = useRef<Object3D>()
    const fortuneWheelRef = useRef<Object3D>()
    
    const getRandomAngle = useCallback(() => {
        return _.random(Math.PI * -2, Math.PI * 2)
    }, [])

    const orbitFn = useCallback(async () => {

        const centerPoint = getObject()

        // Loading element
        const [
            theFool,
            planetExplosition,
            planetTwins,
            starsBlink,
            sun,
            loveMissile,
            moonSun,
            astronaute,
            angel,
            planetMissile,
            fortuneWheel
        ] = await Promise.all([
            theFoolLoadFn(),
            planetLoadFn(),
            planetTwinsLoadFn(),
            planetStarsBlinkLoadFn(),
            sunLoadFn(),
            planetLoveMissileFn(),
            planetMoonSunFn(),
            astronauteFn(),
            angelFn(),
            planetMissileFn(),
            fortuneWheelFn()
        ])
                

        // planet explosition rotator 
        planetExplositionRotator.current = getObject([planetExplosition])
        planetExplosition.position.z = 185
        planetExplositionRotator.current?.rotateX(getRandomAngle())
        planetExplositionRotator.current?.rotateY(getRandomAngle())
        
        // planet love missile
        loveMissileRef.current = getObject([loveMissile])
        loveMissile.position.z = -180
        loveMissileRef.current?.rotateX(getRandomAngle())
        loveMissileRef.current?.rotateY(getRandomAngle())

        // planet twins 
        planetTwinsRef.current = getObject([planetTwins])
        planetTwins.position.z = -100
        planetTwinsRef.current?.rotateX(getRandomAngle())
        planetTwinsRef.current?.rotateY(getRandomAngle())

        // stars blink
        planetStarsBlink.current = getObject([starsBlink])
        starsBlink.position.z = -90
        planetStarsBlink.current?.rotateX(getRandomAngle())

        // moon sun
        moonSunRef.current = getObject([moonSun])
        moonSun.position.z = 115
        moonSunRef.current?.rotateX(getRandomAngle())
        moonSunRef.current?.rotateY(getRandomAngle())

        // planet missile
        planetMissileRef.current = getObject([planetMissile])
        planetMissile.position.z = -115
        planetMissileRef.current?.rotateX(getRandomAngle())
        planetMissileRef.current?.rotateY(getRandomAngle())

        // astronaute
        astronauteRef.current = getObject([astronaute])
        astronaute.position.z = 115
        astronauteRef.current?.rotateY(getRandomAngle())

        // angel
        angelRef.current = getObject([angel])
        angel.position.z = -115
        angelRef.current?.rotateY(getRandomAngle())

        // fortune wheel
        fortuneWheelRef.current = getObject([fortuneWheel])
        fortuneWheel.position.z = -98
        fortuneWheelRef.current?.rotateX(getRandomAngle())
        fortuneWheelRef.current?.rotateY(getRandomAngle())

        // Add orbits
        centerPoint.add(theFool)
        if (!onlyTheFool) {
            centerPoint.add(planetExplositionRotator.current, planetTwinsRef.current, planetStarsBlink.current, loveMissileRef.current, moonSunRef.current, astronauteRef.current, angelRef.current, planetMissileRef.current, fortuneWheelRef.current)
        }
        
        return centerPoint

    }, [])

    const animation = useCallback((params?: ThreeDAnimationCallbackParams) => {
        //planet explosion
        planetExplositionRotator.current?.rotateX(0.006 / 7.3)
        planetExplositionRotator.current?.rotateY(-0.009 / 7.3)
        // planet love missile 
        loveMissileRef.current?.rotateX(0.006 / 7)
        loveMissileRef.current?.rotateY(-0.009 / 7)
        // fortune wheel
        fortuneWheelRef.current?.rotateX(0.006 / 6)
        fortuneWheelRef.current?.rotateY(-0.009 / 6)

        // planet twins
        planetTwinsRef.current?.rotateX(0.006 / 9)
        planetTwinsRef.current?.rotateY(-0.005 / 9)
        // planet stars blink
        planetStarsBlink.current?.rotateX(0.006 / 5)

        // sun
        // sunRef.current?.rotateX(-0.006 / 3)
        // sunRef.current?.rotateY(0.008 / 3)

        // moon sun
        moonSunRef.current?.rotateX(0.009 / 6.2)
        moonSunRef.current?.rotateY(0.006 / 6.2)
        // planet missile
        planetMissileRef.current?.rotateX(0.006 / 10)
        planetMissileRef.current?.rotateY(0.009 / 10)

        // astronaute
        astronauteRef.current?.rotateY(0.006)
        // angel
        angelRef.current?.rotateY(0.006)

    }, [])

    return {
        orbitFn,
        animations: [
            animation,
            theFoolAnimation,
            planetAnimation, 
            planetTwinsAnimation,
            starsBlinkAnimation,
            sunAnimation,
            loveMissileAnimation,
            moonSunAnimation,
            astronauteAnimation,
            angelAnimation,
            planetMissileAnimation,
            fortuneWheelAnimation
        ]
    }
}

export type ElementOrbitParams = {
    hideSunRay?: boolean,
    onlyTheFool?: boolean
}
