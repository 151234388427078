import { useTranslationLang } from "hooks/translation/useTranslationLang"
import { Outlet } from "react-router-dom"

export const LangInitiator = () => {

    useTranslationLang()

    return (
        <Outlet />
    )
}
