import { useCallback, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import _ from "lodash";
import { useBreakpoints } from "hooks/utils/useBreakpoints";
import { YoutubeVideo } from "../components/YoutubeVideo";

const API_KEY = "AIzaSyAMTpiZz2AfIXTu_wlZRJZ35ufOTPSDGlE";
const playlistId = "PLMBav2Mdp5SVPKcurC888Z2Bcp5Qx8WUF";
const playlistId2 = "PLMBav2Mdp5SUsEJmJsoRzzSYfkyyO9v8m";

const Clips = () => {
    const [videos, setVideos] = useState<any>();
    const [nextPageToken, setNextPageToken] = useState("NO_TOKEN");
    const { xs, sm, md } = useBreakpoints();

    const getPlaylistVideos = useCallback(
        async (pageToken = "") => {
            const response = await fetch(
                `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&key=${API_KEY}&pageToken=${pageToken}&maxResults=6`
            );
            const data = await response.json();
            setVideos({
                ...videos,
                ...data,
                items: [...(videos?.items || []), ...data.items],
            });
            setNextPageToken(data.nextPageToken);

            // fetch all videos but require more load time
            // if (data.nextPageToken) {
            //     getPlaylistVideos(data.nextPageToken); 
            // }
        },
        [videos]
    );

    useEffect(() => {
        getPlaylistVideos();
    }, []);

    const loadMoreVideos = () => {
        if (nextPageToken) {
            getPlaylistVideos(nextPageToken);
        }
    };
    
    return (
        <section className="flex flex-row flex-1 justify-center items-center md:w-[80vw] w-full">
            {(videos?.pageInfo?.totalResults <= 6 && !md) ? (
                <div className="flex lg:gap-[20px] md:gap-[0] gap-[20px] lg:flex-row flex-col">
                    {
                        videos?.items &&
                        videos?.items.map((video: TVideo, index: number) => {
                            if (index % 2 === 0) {
                                return (
                                    <div key={index} className="flex lg:flex-col lg:justify-between justify-center gap-[15px]">
                                        <YoutubeVideo video={video} key={index} />
                                        <YoutubeVideo
                                            video={videos?.items[index + 1]}
                                            key={index + 1}
                                        />
                                    </div>
                                );
                            }
                            return null;
                        })
                    }
                </div>
            ) : (
                <div className="swiper-container w-full">
                    <Swiper
                        className="clips-container h-[63vh]"
                        modules={[Navigation]}
                        navigation={{
                            prevEl: ".btn-swiper-prev",
                            nextEl: ".btn-swiper-next",
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 1.8,
                            },
                            1024: {
                                slidesPerView: 2,
                            },
                        }}
                        onSlideChange={loadMoreVideos}
                    >
                        {!md && videos?.items && // medium (laptop) and up 
                            videos?.items.map(
                                (video: TVideo, index: number) => {
                                    if (index % 2 === 0) {
                                        return (
                                            <SwiperSlide
                                                key={index}
                                            >
                                                <div className="flex flex-col lg:justify-between justify-center items-center gap-[15px] md:w-auto">
                                                    <YoutubeVideo
                                                        video={video}
                                                        key={index}
                                                    />
                                                    <YoutubeVideo
                                                        video={
                                                            videos?.items[
                                                            index + 1
                                                            ]
                                                        }
                                                        key={index + 1}
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        );
                                    }
                                    return null;
                                }
                            )}
                        {
                            md && videos?.items && videos?.items.map(
                                (video: TVideo, index: number) => {
                                    return (
                                        <SwiperSlide
                                            className={"w-full items-center"}
                                            key={index}
                                        >
                                            <YoutubeVideo
                                                video={video}
                                                key={index}
                                            />
                                        </SwiperSlide>
                                    );
                                }
                            )
                        }
                        {
                            sm &&
                            <>
                                <button className="btn-swiper-prev rounded-full">
                                    <img src="/assets/img/icons/left-arrow.svg" alt="previous clip button" />
                                </button>
                                <button className="btn-swiper-next rounded-full">
                                    <img src="/assets/img/icons/right-arrow.svg" alt="next clip button" />
                                </button>
                            </>
                        }
                    </Swiper>
                </div>
            )}
        </section>
    );
};

type TVideo = {
    id: string;
    snippet?: any;
};

export default Clips;
