import { AppRouteProps } from "components/AppRoutes";
import { useCallback } from "react";
import { matchPath } from "react-router-dom";

export const useRouterDeclaration = () => {

    const matchUrl = useCallback((routes: Array<AppRouteProps>, path: string, defaultPath?: string): AppRouteProps | undefined => {
        return routes.reduce<AppRouteProps | undefined>((result, item) => {
            const currentPath = `${defaultPath || ''}${item.path ? `/${item.path?.replace(/^\//, '')}`: ''}`
            const match = matchPath(currentPath, path)
            if (!result && !match && item.children) {
                result = matchUrl(item.children, path, currentPath)
            }

            if (!result && match) {
                result = item
            }
            return result
        }, undefined)
    }, [])

    return matchUrl
}
