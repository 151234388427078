import { useTranslations } from "hooks/translation";
import { useIsHome } from "hooks/utils/useIsHome";
import { useCallback, useState, useEffect } from "react";

const FirstExperience = ({ isLoading, onFading }: Props) => {
    const [TEXT1, TEXT2, TEXT3, TEXT4, TEXT5, TEXT6, TEXT7, TEXT8, TEXT9] =
        useTranslations(i18nKeys);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const [hide, setHide] = useState<boolean>(false);

    const handleFirstExperience = (e: any) => {
        e.preventDefault()
        setFirstLoad(false);
        setTimeout(() => {
            onFading?.()
            setHide(true);
        }, 2500);
    }

    return (
        <div
            className={`fixed z-[9999] inset-0 flex justify-center items-center text-[#a79fc9] ${firstLoad ? "" : "animate__animated animate__fadeOut"
                }`}
            style={{
                backgroundImage: "url(/assets/img/home/bg-nebula.jpg)",
                backgroundSize: "cover",
                animationDelay: "2000ms",
                display: hide ? "none" : "flex",
            }}
            onMouseDown={handleFirstExperience}
            onTouchStart={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}
        >
            <div className="w-[80vmin] text-center flex flex-col justify-center items-center">
                <h2
                    className={`animate__animated ${firstLoad
                            ? "animate__fadeIn"
                            : "animate__fadeOut"
                        }`}
                    style={{
                        animationDelay: "300ms",
                    }}
                >
                    <span className="whitespace-nowrap" style={{ fontFamily: 'KCObraLetra' }}>{TEXT1}</span>
                    <span className="whitespace-nowrap" style={{ fontFamily: 'KCObraLetra' }}>
                        {" "}
                        {TEXT2}
                    </span>{" "}
                    <br />
                    <span className="whitespace-nowrap" style={{ fontFamily: 'KCObraLetra' }}>
                        {TEXT3}
                    </span>{" "}
                    <br />
                    <span className="whitespace-nowrap" style={{ fontFamily: 'KCObraLetra' }}>
                        {" "}
                        {TEXT4}
                    </span>{" "}
                    <br />
                    <span style={{ fontFamily: 'KCObraLetra' }}>{TEXT5}</span>
                    <span style={{ fontFamily: 'KCObraLetra' }}> {TEXT6}</span> <br />
                    <span style={{ fontFamily: 'KCObraLetra' }}> {TEXT7}</span>
                    <span style={{ fontFamily: 'KCObraLetra' }}> {TEXT8}</span>
                </h2>
                <div
                    className={`flex flex-col justify-center items-center gap-[2px] mt-[60px] uppercase animate__animated ${firstLoad
                            ? "animate__fadeIn"
                            : "animate__fadeOut"
                        }`}
                    style={{
                        animationDelay: "700ms",
                    }}
                >
                    <span className="block bg-white h-[1px] w-[70%]"></span>
                    <h3 className="md:text-[26px] text-[18px] whitespace-nowrap" style={{ fontFamily: 'Granblue' }}>
                        {TEXT9}
                    </h3>
                    <span className="block bg-white h-[1px] w-[70%]"></span>
                </div>
                <div
                    className={`mt-[40px] animate__animated ${firstLoad
                            ? "animate__fadeIn"
                            : "animate__fadeOut"
                        }`}
                    style={{
                        animationDelay: "900ms",
                    }}
                >
                    {isLoading && <img src="/assets/img/icons/Spin-1s-200px.gif" className="h-10" />}
                    {!isLoading && <img
                        src="/assets/img/icons/hand-move.png"
                        alt="hand move"
                        className="h-10 animate__animated animate__wobble animate__infinite"
                        style={{ animationDuration: '10000ms' }}
                    />}
                </div>
            </div>
        </div>
    );
};

const i18nKeys = [
    "App.Home.Text1",
    "App.Home.Text2",
    "App.Home.Text3",
    "App.Home.Text4",
    "App.Home.Text5",
    "App.Home.Text6",
    "App.Home.Text7",
    "App.Home.Text8",
    "App.Home.Text9",
];

type Props = {
    isLoading?: boolean
    onFading?: () => void
}

export default FirstExperience;
