import { useMemo } from "react";

export function useGalleryPicturesList() {
    return useMemo((): Array<TPicture> => {
        return [
            {
                src: "/assets/img/pictures/jain-photo-6.jpg",
                title: "jain photo 2",
                height: 1,
            },
            {
                src: "/assets/img/pictures/jain-photo-1.jpg",
                title: "jain photo 1",
                height: 1,
            },
            {
                src: "/assets/img/pictures/jain-photo-3.jpg",
                title: "jain photo 3",
                height: 1,
            },
        ];
    }, []);
}

export type TPicture = { 
	src: string,
	height: number // value between 0 and 1 ; ex: 0.4
    title?: string
};
