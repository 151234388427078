import { useTranslationRoute } from "hooks/translation/useTranslationRoute"
import { useMemo } from "react"
import { useLocation } from "react-router-dom"

export const useAppRoutes = () => {

    const location = useLocation()
    const url = useTranslationRoute()

    const routes = useMemo(() => {
        return [
            url(''),
            url('albums'),
            url('cards'),
            url('videos'),
            url('concerts'),
            url('photos'),
            url('shop'),
        ]
    }, [url])

    const nextUrl = useMemo(() => {
        const index = routes.findIndex((item) => item === location.pathname)
        return routes[index + 1]
    }, [location, routes])

    const prevUrl = useMemo(() => {
        const index = routes.findIndex((item) => item === location.pathname)
        return routes[index - 1]
    }, [location, routes])

    return {
        nextUrl,
        prevUrl
    }
}
