import * as THREE from 'three';
import { useCallback } from "react"

export const use3DElementFromTexture = () => {

    return useCallback(({ path, width }: ElementFromTextureProps) => {
        return new Promise<THREE.Mesh>((resolve, reject) => {
            new THREE.TextureLoader().load(path, (texture) => {
                const ratio = texture.image.height / texture.image.width
                const geometry = new THREE.PlaneGeometry(width, ratio * width);
                const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true });
                const plane = new THREE.Mesh(geometry, material);
                resolve(plane)
            }, () => {
                // on progress
            }, (e) => reject(e));
        })
    }, [])
}

export type ElementFromTextureProps = {
    path: string
    width: number
}
