import { useTranslations } from "hooks/translation"
import { useTranslation } from "hooks/translation/useTranslation"
import { useBreakpoints } from "hooks/utils/useBreakpoints"
import CardMenuItem from "pages/home/components/CardMenuItem"
import { useMemo } from "react"
import { useMainMenus } from "../hooks/useMainMenus"

const Menu = () => {

    const [
        CREDIT,
        CREDIT_CONCEPT_STORE
    ] = useTranslations(i18n)
    const menus = useMainMenus()
    const { sm, md } = useBreakpoints()
    const t = useTranslation()

    const showIcon = useMemo(() => {
        return window.matchMedia("(min-width: 860px)").matches
    }, [window])

    return (
        <nav className="flex flex-row flex-1 justify-center items-center">
            <div className="flex flex-col gap-2 md:gap-[30px] justify-center items-center">
                <div>
                    {showIcon && <div className="menu-bigsun-animation h-[120px]"><img src="/assets/img/moon-menu.png" height="100%" width={sm ? '100px' : '120px'} className="animate__animated animate__fadeIn" alt="sun menu icon" /></div>}
                </div>
                <div className="grid sm:grid-cols-6 grid-cols-2 gap-4 sm:gap-2 md:gap-4 text-primary mb-[20px]">
                    {
                        menus.map((menuItem, index) => (
                            <CardMenuItem key={index} menuLabel={menuItem.label} {...menuItem} className="animate__animated animate__fadeIn" style={{ animationDelay: (200 * index).toString().concat('ms') }} />
                        ))
                    }
                </div>

                <p className="text-center text-primary">
                    <span>{ CREDIT.replace('${date}', (new Date().getFullYear()).toString()) }</span><br/>
                    <a href="http://www.leconcept.store/" target="_blank"><span>{ CREDIT_CONCEPT_STORE }</span></a>
                </p>
            </div>
        </nav>
    )
}

const i18n = [
    'App.Menu.Credit.label',
    "App.Menu.Credit.ConceptStore.label"
]

export default Menu
