import _ from "lodash";
import { useCallback, useMemo } from "react";
import * as THREE from 'three';
import { ThreeDAnimationProps } from "./use3DAnimation";
import { useThreeJsAnimations } from "./useThreeJsAnimations";

export const use3DRandomPlanets = (size = {min: 20, max: 40}, element1 = {min: 50, max: 100}) => {

    const { rotateY } = useThreeJsAnimations()

    const getDeapPosition = () => {
        const width = window.innerWidth / 3.2
        const height = window.innerWidth / 3.2

        return {
            x: _.random(width * -1, (width)),
            y: _.random(height * -1, (height)),
            z: _.random(height * -1, height)
        }
    }

    const getPlanetTexture = useCallback((texture?: THREE.Texture) => {
        const randomSize = _.random(size.min, size.max)
        const geometry = new THREE.SphereGeometry(randomSize);
        const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true });
        const mesh = new THREE.Mesh(geometry, material);
        const position = getDeapPosition()
        mesh.position.set(position.x, position.y, position.z);
        rotateY({
            rotation: mesh.rotation,
            angle: Math.PI * 2,
            duration: _.random(17000, 20000)
        }).start()
        return mesh
    }, [])

    const planet1 = useMemo(() => {
        const planet = '/assets/img/texture/TH-CHARIOT.png'
        var texture = new THREE.TextureLoader().load(planet!);
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        const planetObj = new THREE.Object3D();
        const limit = _.random(element1.min, element1.max)
        for (var i = 0; i < limit; i++) {
            const planet = getPlanetTexture(texture)
            planetObj.add(planet)
        }
        return planetObj
    }, [])


    const animation = useCallback((params?: ThreeDAnimationProps) => {
        
    }, [])

    return {
        elements: [planet1],
        animation
    }
}
