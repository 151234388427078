const BackgroundStarsLayer = () => {
    return (
        <g>
            <g>
                <path
                    className="st10"
                    style={{ fill: "#F5F3AB" }}
                    d="M5839.5,1225.1c7.6-7.4,15.9-10.2,23.4-13.9c11.2-5.4,15.6-13.4,14.9-25.2c-0.1-1.6,0-3.3,0-5
		c0-1.3,0-2.7,1.3-3.4c1.9-1,3.1,0.3,4.2,1.4c4.7,5.1,10.1,9.5,14.3,15.1c4.5,5.9,6.5,6.5,13.4,4.1c4.7-1.6,9.1-3.8,13.9-5.2
		c3.5-1.1,5.3,0.9,4,4.3c-1.5,4-2.5,8.2-4,12.2c-2.7,7.3-0.4,12.8,4.7,17.9c3.3,3.2,6.6,6.5,9.9,9.8c1.5,1.6,4.3,3,3.1,5.5
		c-1.2,2.5-4,0.8-6.1,0.4c-5.8-1.2-11.4-3.1-17.5-2.9c-12.9,0.3-23,5.2-28.7,17.3c-0.1,0.3-0.3,0.6-0.5,0.9
		c-2.3,3.8-3.8,10.4-8.8,9.3c-5.8-1.2-2.8-7.7-3-11.9c-0.8-13.9-5.2-20.6-18.2-25.1C5853.7,1228.7,5847.3,1227.3,5839.5,1225.1z"
                />
                <path
                    className="st10"
                    style={{ fill: "#F5F3AB" }}
                    d="M4042.1,561c7.6-7.4,15.9-10.2,23.4-13.9c11.2-5.4,15.6-13.4,14.9-25.2c-0.1-1.6,0-3.3,0-5
		c0-1.3,0-2.7,1.3-3.5c1.9-1,3.1,0.3,4.2,1.5c4.7,5.1,10.1,9.5,14.3,15.1c4.5,6,6.5,6.5,13.4,4.1c4.7-1.6,9.1-3.8,13.9-5.2
		c3.5-1,5.3,0.9,4,4.3c-1.5,4-2.5,8.2-4,12.2c-2.7,7.3-0.4,12.8,4.7,17.9c3.3,3.2,6.6,6.5,9.9,9.8c1.5,1.6,4.3,3,3.1,5.5
		s-4,0.8-6.1,0.4c-5.8-1.2-11.4-3.1-17.5-2.9c-12.9,0.3-23,5.2-28.7,17.3c-0.1,0.3-0.3,0.6-0.5,0.9c-2.3,3.8-3.8,10.4-8.8,9.3
		c-5.8-1.2-2.8-7.7-3-11.9c-0.8-13.9-5.2-20.6-18.2-25.1C4056.3,564.6,4049.9,563.2,4042.1,561z"
                />
                <path
                    className="st10 big-star-flicker"
                    style={{ fill: "#F5F3AB" }}
                    d="M813.9,1020.4c7.6-7.4,15.9-10.2,23.4-13.9c11.2-5.4,15.6-13.4,14.9-25.2c-0.1-1.6,0-3.3,0-5
		c0-1.3,0-2.7,1.3-3.5c1.9-1,3.1,0.3,4.2,1.5c4.7,5.1,10.1,9.5,14.3,15.1c4.5,6,6.5,6.5,13.4,4.1c4.7-1.6,9.1-3.8,13.9-5.2
		c3.5-1,5.3,0.9,4,4.3c-1.5,4-2.5,8.2-4,12.2c-2.7,7.3-0.4,12.8,4.7,17.9c3.3,3.2,6.6,6.5,9.9,9.8c1.5,1.6,4.3,3,3.1,5.5
		c-1.2,2.5-4,0.8-6.1,0.4c-5.8-1.2-11.4-3.1-17.5-2.9c-12.9,0.3-23,5.2-28.7,17.3c-0.1,0.3-0.3,0.6-0.5,0.9
		c-2.3,3.8-3.8,10.4-8.8,9.3c-5.8-1.2-2.8-7.7-3-11.9c-0.8-13.9-5.2-20.6-18.2-25.1C828.1,1023.9,821.7,1022.5,813.9,1020.4z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M4472.2,462.1c0,2.6,0,5.3-2,7.3s-3.7,5.5-7.9,4.3c-5.8-1.7-9.6-5.1-9.9-9.5c-0.4-4,2.1-7.8,6-9.1
		c0.9-0.4,2-0.5,2.8-0.8C4469.5,451.7,4472.3,453.7,4472.2,462.1z"
                />
                 <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M4158.8,1614.8c0,9.4-6.5,17.4-14.1,17.4c-8.3,0-17.2-8.6-16.9-16.3s9.7-15.3,18.2-15.3
		C4154.1,1600.6,4158.8,1605.8,4158.8,1614.8z M4153.1,1615.5c0-8.4-2.8-10.4-11-7.8c-0.9,0.3-2,0.5-2.8,0.8c-3.9,1.3-6.4,5-6,9.1
		c0.2,4.3,4,7.8,9.9,9.5c4.3,1.2,5.8-2.1,7.9-4.3s1.8-4.7,1.8-7.3L4153.1,1615.5z"
                />
                <path
                    className="st9 big-star-flicker2"
                    style={{ fill: "#F8F5AB" }}
                    d="M1484.2,378.4c-15-6.2-25.6-16.7-35.9-27.8c-9-9.6-24.4-9.3-34.4-0.5c-9.7,8.5-18.5,17.9-29,25.3
		c-2,1.4-3.9,3.2-6.2,1.5c-1.9-1.4-0.8-3.5-0.5-5.5c1.8-10.7,4.4-21.2,7.9-31.5c2.5-6.3,0.9-13.6-4.1-18.2
		c-4.6-4.8-10.5-7.9-15.5-12.2c-3.4-2.8-3-3.8,1-6c4.6-2.5,9.9-3.7,15.1-3.3c4.9,0.3,9.9-0.5,14.9,0.2c7.6,1,11.9-2.9,14.7-9
		c9.1-20,18.2-40.1,21-62.3c0.2-2,0.4-4.1,3.1-4.3c2.1,0,3.9,1.4,4.4,3.3c5.4,12.3,10.2,24.8,14.4,37.6c2.4,7.5,3.6,15.3,5.7,22.9
		s6.4,10.6,14.6,12.1c11.9,2.2,23.9,4,36,5.4c1.7,0.2,3.5,0.8,4,2.5s-1.2,3-2.7,3.6c-4.6,1.8-8.4,4.8-13.3,6.4
		c-17.6,5.7-22.7,15.8-17.1,33.5C1484.5,360.7,1485.2,369.6,1484.2,378.4z"
                />

                <path
                    className="st10"
                    style={{ fill: "#F5F3AB" }}
                    d="M2908.4,126.5c2.9,5,0.3,9.9-1.1,14.1c-2.6,7.8-1.6,14.2,4.2,19.9c0.5,0.4,0.9,0.9,1.2,1.6
		c0.8,3,6,4.9,3.3,8.4c-1.8,2.4-5.5,0.8-8.4,0.1c-9.4-2-13.5,0.5-15.7,9.7c-0.3,1.3-0.2,2.6-0.5,4c-0.1,1.4-1,2.5-2.2,2.9
		c-1.7,0.4-3.4-0.3-4.2-1.8c-2.8-3.8-4-8.3-5.2-12.7c-0.9-2.8-1.2-5.8-2-8.7c-1.4-5.8-3.2-7-9-6.2c-2.9,0.4-5.6,2.2-8.7,1.3
		c-0.5-0.2-0.9-0.8-2-1.6c1.4-3.6,4.2-6.5,7.8-7.9c6-2.8,7.9-7.5,7.6-13.6c-0.1-4,0-7.9,0-11.9c0-1.8-0.9-4.3,1.4-5.2
		s3.2,1.4,4.4,2.9c2,2.6,4.2,5.1,6.4,7.5c5.4,5.4,8.4,5.6,14.7,1.8C2903.3,129.3,2904.7,126.1,2908.4,126.5z"
                />
                <path
                    className="st11"
                    style={{ fill: "#F0EEA7" }}
                    d="M3556.8,1736.6c-4.6-4.7-9.2-9.4-13.9-13.9c-5.9-5.8-11.8-11.7-21.5-7.6c-3.5,1.1-7.2,0.8-10.5-0.8
		c4.1-5.9,6.3-12.8,6.5-20c0.6-8.2-7.7-11.9-7.9-19.4c3.8-0.8,6.1,1.4,8.7,2.5c11.9,5.3,18,3.4,24.4-7.7c2.5-4.3,5-8.5,7.4-12.9
		c1.6-2.9,3.2-5.6,7.7-6.5c1.1,6.2,1.1,12.6,0,18.9c-1.7,10.4,1,16,10.3,21c4.3,2.3,8.6,5,13.6,5.6c1.4,0.1,2.4,1.3,2.4,2.7
		c0,1.4-1,2.6-2.3,2.8c-1.3,0.3-2.6,0.5-4,0.8c-11.4,2.2-15.3,8.6-17.2,22.1C3560,1728.2,3561.2,1732.7,3556.8,1736.6z"
                />
                <path
                    className="st11"
                    style={{ fill: "#F0EEA7" }}
                    d="M271.1,1620.1c0.2,1.4-0.5,2.7-1.8,3.2c-3.3,2.2-7,3.8-10.8,4.8c-2.3,0.4-4.5,1-6.7,1.8
		c-12.4,4.5-15.9,11.2-12,23.8c0.8,2.9,1.8,5.7,2.7,8.5c0.6,1.8,2.2,3.8-0.3,5.2c-2,1.1-3.8-0.3-5.2-1.8c-3.7-3.8-8.1-6.8-12.1-10.2
		c-3.4-3.2-8.4-4.4-12.9-2.9c-7.3,2.1-15.1,1.8-22.2,4.8c-1.6,0.6-3.4,1-4.6-0.5c-1.5-1.9-0.6-3.8,0.8-5.3c1.5-1.8,3.1-3.5,4.8-5
		c6.9-5.5,6.8-12.3,3.8-19.7c-1.5-3.6-5-7.6-3.1-11c2.1-3.8,7.3-1.3,11-1.4c6.8-0.1,9.7-5.2,13-9.6c3.8-5,6.8-10.6,11.6-14.7
		c1.7-1.4,3.1-4.2,5.7-3c2.2,1,2.5,3.7,2.9,6c0.2,1.6,0,3.3,0.3,4.9c1.4,7.4,5.1,12.6,13,14.3c6.4,1.4,12.7,3.6,19,5.5
		C269.4,1618.3,270.8,1618.5,271.1,1620.1z"
                />
                <path
                    className="st10"
                    style={{ fill: "#F5F3AB" }}
                    d="M3586.6,484.7c-4.8-0.4-9.4-1.9-13.6-4.4c-4.7-2.4-9.9-3.6-15.2-3.4c-5.7-0.2-10.3,1.1-13.9,5.7
            c-5.6,7.1-14.7,9.9-20.7,16.7c-0.7,1-2.2,1.2-3.2,0.5c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.3-0.2c-0.6-0.8-0.8-1.8-0.6-2.7
            c2.5-6.2,2.7-12.9,5-19.1c3.3-9,2.7-18.2,2.5-27.4c-0.1-4.7-2.3-9.1-6-12c-5.4-4.5-8.9-10.6-13.1-16c-1.7-2.2-4.2-5.2-1.7-7.9
            s5.6-1.3,8.4-0.2c6.4,2.6,12.7,5.5,19,8.4c9.5,4.4,15.2,2.5,20.2-7c1.6-3.6,3.5-7.1,5.5-10.5c2.4-3.3,6.2-2.4,7.3,1.5
            c1.2,4.4,1.8,9,2.8,13.5c2.3,9.8,5.5,12.6,15.5,13c5.9,0.2,11.5,2.7,17.6,2.4c2.3-0.1,4.8,0.5,5.3,3.4c0.4,2.4-1.6,3.4-3.2,4.5
            c-7,4.2-13.8,8.9-20.3,13.9c-5.8,4.9-5.8,4.8-2.7,12C3583.3,473.8,3584.6,478.5,3586.6,484.7z"
                />
                 <path
                    className="st11"
                    style={{ fill: "#F0EEA7" }}
                    d="M5019,310.7c-4.6-4.7-9.2-9.4-13.9-13.9c-5.9-5.8-11.8-11.7-21.5-7.6c-3.5,1.1-7.2,0.8-10.5-0.8
		c4.1-5.9,6.3-12.8,6.5-20c0.6-8.2-7.7-11.9-7.9-19.4c3.8-0.8,6.1,1.4,8.7,2.5c11.9,5.3,18,3.4,24.4-7.7c2.5-4.3,5-8.5,7.4-12.9
		c1.6-2.9,3.2-5.6,7.7-6.5c1.1,6.2,1.1,12.6,0,18.9c-1.7,10.4,1,16,10.3,21c4.3,2.3,8.6,5,13.6,5.6c1.4,0.1,2.4,1.3,2.4,2.7
		c0,1.4-1,2.6-2.3,2.8c-1.3,0.3-2.6,0.5-4,0.8c-11.4,2.2-15.3,8.6-17.2,22.1C5022.2,302.3,5023.4,306.8,5019,310.7z"
                />
            </g>
            <g className="flicker">
                
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M1677,1784.8c0.3-2.6,0.8-5.1,1.6-7.6c2.9-7.9,8.1-10.8,17.9-10.2c7.4,0.4,10.5,3.6,12.6,12.3
		c0.5,2,1.2,3.7,1.8,5.6h-7.9c0.1-1.3,0.1-2.7,0-4c-0.6-6.9-1.5-7.8-8.2-7.9c-8.4-0.2-9.9,1.3-11.7,11.9L1677,1784.8z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M1683,1784.8c1.8-10.7,3.3-12.1,11.7-11.9c6.7,0.2,7.6,1.1,8.2,7.9c0.1,1.3,0.1,2.7,0,4L1683,1784.8z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M6934.5,1461.3c-8.9,2.4-12.3,0.4-11.8-6.7c0.2-2.4,0.7-4.7,3.1-5.8c2.2-1,4.8-0.2,6.1,1.8
		c0.8,1,1.8,1.8,2.7,2.7V1461.3z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M1066.8,379.5c-0.5,4.7-4.6,8.1-9.3,7.6h-0.4c-4.9-0.7-9.9-7.4-9.2-12.4c0.8-4.4,4.9-7.4,9.3-7
		C1062.8,368.7,1066.9,373.7,1066.8,379.5z"
                />
                <path
                    className="st4"
                    style={{ fill: "#AFB195" }}
                    d="M6838.9,842.3c0,3,0.9,6.3-0.2,8.8c-4.4,11.2,3,16.3,10.5,21.5c3,2,6,4,9.1,6c1.1,0.7,2.2,1.4,3.3,2.2
		c3.3,3,6.3,6.6,4.5,11.1c-1.7,4.1-6.2,5.4-10.1,4.7c-6-1-11.8,0.2-17.6,0.8c-3.6,0.4-7.1,1.3-10.7,1.8c-7,0.4-12.4,6.2-12.3,13.1
		c-0.6,11-8.3,19.2-11.1,29.3c-1,3.6-4.5,2.8-6,0.2c-2.6-4.6-7.2-7.8-8.5-13.2c-0.3-0.9-0.7-1.8-1.2-2.7c-3.8-8.3-8.9-14-19.5-13
		c-9.1,0.8-18.5,0.1-27.8,0c-3.3,0-6.8,0.4-8.5-3.4c-1.5-3.3,1.3-5.3,2.6-7.9c4-7.9,11.2-12.8,16.3-19.7c4-5.4,4.2-5.6-0.5-10.6
		c-6.8-7.2-14.7-13.2-19.9-21.9c-3.4-5.8-3-7.4,3-9c4.8-1.2,9.3-2,14.3,0.5c5.1,2.3,10.7,3.1,16.3,2.3c8.9-0.7,17.8-0.8,26.7-0.2
		c6,0.6,11.9-1.4,16.2-5.7c6.3-6.2,15-8.2,22.6-11.9c4.8-2.3,7.7-0.3,8.3,4.9c0.3,4,0.3,7.9,0.1,11.9L6838.9,842.3z M6740.9,847.9
		c1.6,4.8,4.5,6.5,6.7,8.8c3.2,3.3,6.8,6.3,9.7,9.9c6.5,7.7,6,14.8-1,21.7l-11.9,11.9c-1.1,1.1-2.5,2.4-1.4,4.3
		c0.8,1.3,2.2,1.3,3.5,1.2c8.2-0.4,16.5,0.9,24.6-1.4c6.3-1.8,11.4-0.3,15.1,6c3,5,7.9,8.8,9.5,14.8c0.6,2.4,2.7,4,5.2,4
		c2.8,0,3.4-2.9,4.1-5c2.3-6.8,4.5-13.8,6.4-20.8c2.2-7.9,6.5-12.8,13.9-13.4c8.5-0.6,16.9-3,25.6-2.1c1.6,0.2,4.1,1.1,4.9-1.9
		c-1.8-3.5-5.4-4.8-8.7-6.2c-5.1-2.4-9.6-6-13.1-10.3c-4-4.5-6-9.4-2.7-15.3c3-5.5,2.2-11.6,2.2-17.5c0-3.6-1.8-4.9-5.1-3.1
		c-6,3.3-12.6,5.6-18,10.2c-4.7,4-9.6,8.4-16.5,8.2c-10.8-0.3-21.7,1.1-32.5-1.5C6755.1,849,6748.5,848.8,6740.9,847.9L6740.9,847.9
		z"
                />

                <path
                    className="st4"
                    style={{ fill: "#AFB195" }}
                    d="M3513.3,500.4c-1-5.1,3.3-10.1,4-15.9c1.2-8.8,2.4-17.6,3.8-26.4c0.6-3.6-0.3-7.4-2.6-10.3
		c-4.2-6-8.2-11.9-12.9-17.5c-5.3-6.2-7.9-13.6-11-20.9c-0.8-2-1.2-3.8,0.3-5.5c2-2.2,4.2-1.4,6.4-0.5c11.5,5.1,23.8,7.8,35.9,10.8
		c6.1,1.5,9.4,0,12.4-4.6c2.9-5.2,6.5-10,10.6-14.3c4-3.8,5.5-3.5,8.1,1.5c2.3,4.4,4,9.1,5.2,13.9c3.2,12.3,8.2,16.7,21.2,17.1
		c6.2,0.3,12.4,1.6,18.3,3.7c5.2,1.7,6.2,4.2,3.2,8.7c-2.2,3.3-5,6.1-8.3,8.4c-5.6,3.5-10.8,7.5-15.7,11.9
		c-3.4,3.2-4.7,6.2-3.1,11.3c1.4,4.3,4,8.3,4.2,13.1c0.5,8.5-1.7,10.4-9.4,7c-2.4-1.1-4.8-2.3-7.2-3.2c-13-4.5-25.2-3.9-36.1,5.6
		c-4.1,3.4-8.3,6.5-12.8,9.4c-2.1,1.5-4.5,2.8-7,3.6C3514.9,509.4,3513,507.9,3513.3,500.4z M3586.7,484.6
		c-2.1-6.1-3.4-10.9-5.3-15.4c-3.1-7.3-3.1-7.1,2.7-12c6.5-5,13.3-9.7,20.3-13.9c1.6-1.1,3.6-2,3.2-4.5c-0.5-2.9-3-3.5-5.3-3.4
		c-5.9,0.4-11.6-2.2-17.6-2.4c-9.9-0.3-13.2-3.2-15.5-13c-1.1-4.5-1.6-9.1-2.8-13.5c-1.1-4-5-4.7-7.3-1.5c-2.1,3.4-3.9,6.9-5.5,10.5
		c-5,9.5-10.7,11.4-20.2,7c-6.3-2.9-12.6-5.7-19-8.4c-2.7-1.1-6.1-2.3-8.4,0.2s0,5.8,1.7,7.9c4.2,5.5,7.7,11.5,13.1,16
		c3.7,2.9,5.9,7.3,6,12c0.2,9.2,0.8,18.4-2.5,27.4c-2.2,6.2-2.5,12.9-5,19.1c-0.2,0.9,0,1.9,0.6,2.7c0.9,0.9,2.3,0.9,3.2,0
		c0.1-0.1,0.2-0.2,0.2-0.3c6-6.8,15.1-9.5,20.7-16.7c3.6-4.6,8.3-5.8,13.9-5.7c5.3-0.2,10.5,0.9,15.2,3.4
		C3577.2,482.7,3581.9,484.2,3586.7,484.6L3586.7,484.6z"
                />
                <path
                    className="st4"
                    style={{ fill: "#AFB195" }}
                    d="M4137.1,531.3c0.1,4.5-0.8,9-2.6,13.1c-2.2,4.2-1.4,9.4,2,12.7c4,5.2,8.4,10.1,13.3,14.7
		c1.6,1.2,3,2.6,4.2,4.2c2.1,3.3,4.9,7,2.6,10.9c-2.5,4.3-6.7,1.7-9.9,0.8c-7.4-1.7-15-2.5-22.6-2.3c-15.4-0.3-26.9,5.4-32.1,20.8
		c-0.6,2.3-2,4.2-4,5.5c-4.4,2.9-7.5,2.5-9.4-2.5c-2.1-5.6-3.7-11.3-4.8-17.1c-2.4-12.9-8.3-19.2-21.4-21.8
		c-5.2-1-10.4-1.7-15.5-3.1c-6.3-1.6-7.2-5.2-2.9-9.9c3.9-4.1,8.6-7.3,13.9-9.4c6.5-2.6,12-6.7,18-10.2s7.4-7.9,7.2-14
		s-0.5-12.5,1.5-18.7c1.2-3.8,3-4.5,6.5-2.4c4.9,2.9,9.9,5.5,12.1,11.2c2.3,6.1,8.6,7.9,12.9,11.9c1.3,1.2,3.1,0.7,4.8,0.4
		c5.5-1.2,11-2.2,16.4-3.5C4136.4,520.3,4137.6,521.2,4137.1,531.3z M4042.1,561c7.9,2.2,14.2,3.6,20.3,5.7
		c13.1,4.5,17.5,11.1,18.2,25.1c0.2,4.1-2.8,10.7,3,11.9c5,1,6.5-5.5,8.8-9.3c0.2-0.3,0.3-0.6,0.5-0.9c5.8-12.1,15.8-17,28.7-17.3
		c6.1-0.2,11.7,1.8,17.5,2.9c2.1,0.4,4.7,2.5,6.1-0.4s-1.6-4-3.1-5.5c-3.2-3.3-6.5-6.6-9.9-9.8c-5.2-5-7.4-10.5-4.8-17.9
		c1.5-4,2.4-8.1,4-12.2c1.3-3.4-0.5-5.3-4-4.3c-4.7,1.5-9.2,3.6-13.9,5.2c-6.9,2.4-9,1.8-13.4-4.1c-4.2-5.6-9.6-9.9-14.3-15.1
		c-1.1-1.2-2.4-2.5-4.2-1.5c-1.4,0.8-1.3,2.2-1.3,3.5v5c0.7,11.8-3.7,19.8-14.9,25.2C4058,550.8,4049.7,553.6,4042.1,561L4042.1,561
		z"
                />
                <path
                    className="st4"
                    style={{ fill: "#AFB195" }}
                    d="M180,1605.2c5.3-0.9,10.8-0.5,16,0.9c6.3,1.7,11.1,0,14.1-6c2.7-5.5,7.1-9.7,11.4-13.7
		c5.2-4.6,9.7-9.9,13.3-15.7c1-1.7,2.2-3.8,4.5-2.8c2,0.8,2.2,3.1,2.2,5.2c0.1,6,0.5,11.9,0.2,17.9c-0.4,7.5,3.1,11.9,9.9,14
		c8.5,2.7,16.4,7.1,25.3,8.5c1.6,0.3,3.2,0.8,4.7,1.6c3.5,2,3.8,5.5,0.6,7.9c-4.9,3.8-9.6,7.8-16.1,9.3c-6.2,1.2-11.9,4.4-16,9.2
		c-4,4.8-5.3,9.5-2.4,15.5c2.1,4.5,3.6,9.2,4.6,14.1c0.8,2.6-0.1,5.5-2.4,7.1c-1.9,1.1-3.7-0.5-5.4-1.4c-5.3-2.6-10.6-5.1-15.1-9.3
		c-5.2-5.3-12.9-7.5-20.1-5.7c-9.4,2.2-19.1,1.2-28.6,2.6c-4.8,0.7-6.5-1.8-5.3-6.7c1.8-6.8,5.7-12.8,11-17.3
		c5.1-4.8,4.2-9.2,1.6-13.9c-3.2-5.4-5.9-11.1-8.2-16.9C179.1,1608.2,179.2,1606.6,180,1605.2z M271.2,1620.1
		c-0.3-1.6-1.7-1.8-3-2.2c-6.3-1.9-12.6-4.1-19-5.5c-7.9-1.7-11.6-6.9-13-14.3c-0.3-1.6,0-3.3-0.3-4.9c-0.3-2.3-0.7-5-2.9-6
		c-2.6-1.2-4,1.6-5.7,3c-4.8,4.2-7.9,9.7-11.6,14.7c-3.3,4.3-6.2,9.4-13,9.6c-3.8,0-8.9-2.4-11,1.4c-2,3.3,1.6,7.3,3.1,11
		c3,7.3,3.1,14.1-3.8,19.7c-1.8,1.5-3.4,3.2-4.8,5c-1.4,1.6-2.3,3.4-0.8,5.3c1.2,1.5,3,1.1,4.6,0.5c7.1-2.9,14.9-2.6,22.2-4.8
		c4.5-1.4,9.4-0.3,12.9,2.9c4,3.4,8.4,6.3,12.1,10.2c1.4,1.5,3.2,2.9,5.2,1.8c2.5-1.3,0.8-3.4,0.3-5.2c-0.9-2.8-1.9-5.6-2.7-8.5
		c-3.8-12.6-0.3-19.3,12-23.8c2.2-0.8,4.4-1.3,6.7-1.8c3.8-1,7.5-2.6,10.8-4.8C270.6,1622.9,271.4,1621.5,271.2,1620.1L271.2,1620.1
		z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M1009.2,228.7c-6-0.2-11.4-5.2-11.2-10.3c0.1-4.2,7.4-11.5,11.1-11.2s10.5,8,10.4,11.9c0,5.3-4.2,9.6-9.5,9.6
		c0,0,0,0,0,0L1009.2,228.7z"
                />

                <path
                    className="st4"
                    style={{ fill: "#AFB195" }}
                    d="M3557.7,1745.7c-0.8-0.6-1.8-1.4-3-2c-4-2.4-7.6-5.4-10.5-9c-9.3-11-21.1-14-34.8-11.2
		c-4.4,0.9-9.9,3.5-12.8-1.1c-3.4-5.6,3.4-7.7,6.2-10.7c10.5-11.4,11.1-16.7,2.6-29.7c-1.4-1.8-2.5-3.9-3.2-6.1
		c-0.7-2.8-1.8-6.2,0.6-8.4s4.9,0.3,7.4,0.9c1.3,0.3,2.5,1,3.8,1.2c5.5,1.1,11.1,1.7,16.7,1.9c6.8-0.2,7.2-7.8,11.1-11.4
		c4.3-4,7.3-9.4,11-13.9c1.6-2,3.6-4.4,6.5-3.8c3.6,0.7,4,4.3,4.3,7.1c0.6,5.2,0.5,10.5,0.6,15.9c0.2,15,4.9,20.9,19.7,24.9
		c4.1,1.1,8.4,1.8,12.2,4.1c5.1,3.1,5,6,0.2,9.8c-3,2-6.4,3.5-9.9,4.4c-2.8,1-5.7,1.8-8.5,2.6c-6.1,1.9-10.3,4.7-10.1,12.3
		c0.1,6.5-2.7,12.9-5.3,18.9C3561.7,1744.4,3559.8,1745.7,3557.7,1745.7z M3556.8,1736.6c4.4-4,3.2-8.4,3.8-12.4
		c2-13.5,6-19.8,17.2-22.1c1.3-0.2,2.6-0.5,4-0.8c1.4-0.2,2.4-1.4,2.3-2.8c0-1.4-1-2.6-2.4-2.7c-5.1-0.6-9.3-3.3-13.6-5.6
		c-9.4-5-11.9-10.6-10.3-21.1c1.1-6.2,1.1-12.6,0-18.8c-4.5,0.9-6.1,3.6-7.7,6.5c-2.4,4.3-4.9,8.6-7.4,12.9
		c-6.4,11.1-12.5,13-24.4,7.7c-2.6-1.2-4.9-3.4-8.7-2.5c0.2,7.4,8.6,11.2,7.9,19.4c-0.2,7.2-2.5,14.1-6.5,20c3.3,1.6,7,1.9,10.5,0.8
		c9.7-4.1,15.6,1.8,21.5,7.6C3547.6,1727.3,3552.2,1731.9,3556.8,1736.6z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M6321.1,613.6c3.6-1.7,17.8,2.9,19.6,6.2c3.3,6-0.1,16.5-6.4,19.8c-4.4,2.2-8.5,0.4-12.2-1.8
		c-7.2-4.2-8.4-17.1-2.6-23.1C6320.1,614.4,6320.6,614,6321.1,613.6z"
                />
                <path
                    className="st4"
                    style={{ fill: "#AFB195" }}
                    d="M2916.4,178.4c-3.4-0.2-6.8,0.1-10.1,0.8c-4,1.1-7.5,2.4-8.2,7.2c-0.3,1.3-0.9,2.5-1.6,3.6
		c-5.8,9.6-9.4,9.6-14.6-0.1c-2.5-4.6-5.5-9.3-6.7-14.3c-2-7.7-7.2-9.6-13.8-10.2c-3.6-0.4-7.3,0-10.9-0.4s-5.2-3.3-3-6.4
		c1.7-2.4,3.8-4.5,6.3-6.2c10.2-6.4,15-15.4,14.4-27.4c0-3.3,0.1-6.6,0.5-9.9c0-2.4,1.3-4.6,3.4-5.6c2.7-1.3,4.4,0.6,6,2.3
		c2.7,2.9,5.6,5.5,8.9,7.8c7.1,5,10.5,5.4,17.9,1c3.3-2,6.4-6.2,10.4-4c4.7,2.7,1.4,7.8,1.1,11.8c-0.1,4.3-0.7,8.6-1.9,12.7
		c-2.9,9.4,0.5,16.8,6.3,23.6c2.1,2.1,3.9,4.5,5.3,7.1c2.2,5,0.7,7.3-5,7.1C2919.6,178.8,2918,178.7,2916.4,178.4z M2908.5,126.4
		c-3.8-0.4-5.1,2.9-7.5,4.4c-6.2,4-9.3,3.6-14.7-1.8c-2.3-2.3-4.5-4.8-6.5-7.4c-1.2-1.5-2-3.8-4.4-2.9s-1.4,3.4-1.4,5.2
		c-0.1,4-0.2,7.9,0,11.9c0.2,6.1-1.6,10.8-7.6,13.6c-3.6,1.5-6.4,4.3-7.8,7.9c1,0.8,1.4,1.4,2,1.6c3.1,0.8,5.8-0.9,8.7-1.3
		c5.8-0.8,7.5,0.4,8.9,6.2c0.7,2.9,1.1,5.8,2,8.7c1.4,4.4,2.4,8.9,5.2,12.7c0.9,1.5,2.6,2.2,4.2,1.8c1.3-0.4,2.2-1.6,2.2-2.9
		c0.2-1.3,0.1-2.7,0.5-4c2.2-9.2,6.3-11.9,15.7-9.7c2.9,0.6,6.6,2.2,8.4-0.1c2.6-3.4-2.5-5.4-3.3-8.4c-0.3-0.6-0.7-1.1-1.2-1.6
		c-5.8-5.7-6.8-12.1-4.2-19.9C2908.8,136.2,2911.3,131.4,2908.5,126.4L2908.5,126.4z"
                />
                <path
                    className="st3"
                    style={{ fill: "#B5B599" }}
                    d="M4353,1335.3c-3.3-0.8-5.2-1.3-7.1-1.6c-4.7-1-9.5,1.5-11.3,6c-0.7,1.5-1.1,3.1-2,4.5
		c-0.8,1.9-2.7,3.1-4.7,3.1c-2.5-0.1-2.9-2.3-3.4-4.2c-0.4-1.2-0.7-2.5-0.8-3.8c0.2-9.1-3.6-14.9-12.8-17.3
		c-4.6-1.2-5.4-5.3-1.4-7.9c7.1-4.6,9.6-11,10.2-19c0.7-8.7,5-10.6,11.6-5.4c4.7,3.7,10.4,5.7,16.4,5.8c2.3,0.1,4.6,0.4,6.8,0.9
		c7.6,1.8,8.7,4.1,5.4,11c-0.5,1.2-1.1,2.3-1.7,3.5c-3,6-4.9,11.9-1.9,18.8C4358.1,1333.8,4356,1335.7,4353,1335.3z M4325.5,1293.1
		c-1.3,7.4-1.6,14.7-5.6,20.9c-1,1.1-0.9,2.9,0.2,3.9c0,0,0,0,0,0c0.2,0.2,0.4,0.3,0.5,0.4c5.1,3,7,8.4,9.3,14
		c4-2.4,4.1-8.3,8.9-7.8c5.4,0.5,7.6-1.8,8.3-6.9c0.7-2.2,1.8-4.2,3.3-6c1.5-2.3,5-4.2,3.1-7.5c-1.5-2.7-4.8-1.8-7.3-1.5
		c-6.1,0.8-11-0.8-14.7-6C4330.2,1294.5,4327.9,1293.3,4325.5,1293.1L4325.5,1293.1z"
                />
                <path
                    className="st4"
                    style={{ fill: "#AFB195" }}
                    d="M5776.6,461.7c0.9,0.2,1.8,0.6,2.6,1c7,5,14.6,4.9,22.6,2.7c5.6-1.5,9.5,0.5,8.8,5.3c-1.1,7.9,3.7,12,7.8,17
		c5.3,6.5,4.7,9.9-2.8,12.2c-7.5,2.1-13.9,7.2-17.7,14c-3,5.1-5.8,5.5-8.7,0.6c-5.2-8.8-13.1-12.4-22.4-14.6c-5.2-1.3-6-4.9-1.9-8.6
		c7.4-6.5,10.4-14,7.8-23.7c-0.9-2.2,0.2-4.8,2.4-5.7C5775.7,461.8,5776.1,461.7,5776.6,461.7z M5770.6,494.2
		c11-0.4,15.7,4.9,18.6,12.4c0.2,0.6,0.6,1.2,1,1.7c1.9,2,4.3,3.2,4.8-0.6c1.3-9.7,7-13.5,16.2-13.5c0.4,0,0.7-1,1.5-2.1
		c-9.6-3.8-2.9-14.5-9-20.8c-7.5,5.3-15.5,4.9-24.6,0.2C5784.5,481.6,5779.7,487.9,5770.6,494.2L5770.6,494.2z"
                />
                <path
                    className="st4"
                    style={{ fill: "#AFB195" }}
                    d="M5019.9,319.8c-0.8-0.6-1.8-1.4-3-2c-4-2.4-7.6-5.4-10.5-9c-9.3-11-21.1-14-34.8-11.2
		c-4.4,0.9-9.9,3.5-12.8-1.1c-3.4-5.6,3.4-7.7,6.2-10.7c10.5-11.4,11.1-16.7,2.6-29.7c-1.4-1.8-2.5-3.9-3.2-6.1
		c-0.7-2.8-1.8-6.2,0.6-8.4s4.9,0.3,7.4,0.9c1.3,0.3,2.5,1,3.8,1.2c5.5,1.1,11.1,1.7,16.7,1.9c6.8-0.2,7.2-7.8,11.1-11.4
		c4.3-4,7.3-9.4,11-13.9c1.6-2,3.6-4.4,6.5-3.8c3.6,0.7,4,4.3,4.3,7.1c0.6,5.2,0.5,10.5,0.6,15.9c0.2,15,4.9,20.9,19.7,24.9
		c4.1,1.1,8.5,1.8,12.2,4.1c5.1,3.1,5,6,0.2,9.8c-3,2-6.4,3.5-9.9,4.4c-2.8,1-5.7,1.8-8.5,2.6c-6.1,1.9-10.3,4.7-10.1,12.3
		c0.1,6.5-2.7,12.9-5.3,18.9C5024,318.5,5022.1,319.9,5019.9,319.8z M5019,310.7c4.4-4,3.2-8.4,3.8-12.4c2-13.5,6-19.8,17.2-22.1
		c1.3-0.2,2.6-0.5,4-0.8c1.4-0.2,2.4-1.4,2.3-2.8c0-1.4-1-2.6-2.4-2.7c-5.1-0.6-9.3-3.3-13.6-5.6c-9.4-5-11.9-10.6-10.3-21.1
		c1.1-6.2,1.1-12.6,0-18.8c-4.5,0.9-6.1,3.6-7.7,6.5c-2.4,4.3-4.9,8.6-7.4,12.9c-6.4,11.1-12.5,13-24.4,7.7
		c-2.6-1.2-4.9-3.4-8.7-2.5c0.2,7.4,8.6,11.2,7.9,19.4c-0.2,7.2-2.5,14.1-6.5,20c3.3,1.6,7,1.9,10.5,0.8c9.7-4.1,15.6,1.8,21.5,7.6
		C5009.9,301.4,5014.4,306,5019,310.7z"
                />
                <path
                    className="st5"
                    style={{ fill: "#9BB1A2" }}
                    d="M3695.5,1599.6c-2.1,0-4.2-0.2-6.2-0.6c-2.6-0.6-4.8-2.4-6-4.9c-3.3-6.8,1.8-21.7,8.6-25.1
		c7.9-4,21.5-0.3,25.6,7c5.1,9.2,0.6,19.5-9.6,22.4C3703.8,1599.3,3699.6,1599.7,3695.5,1599.6z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M2963.1,1697.3c0-4.8,2.3-7.4,6.9-7.6c4.8-0.2,8.6,3,8.5,7.3c0,4.4-3.4,8.1-7.8,8.4
		C2966,1705.6,2963.2,1702.7,2963.1,1697.3z"
                />
                <path
                    className="st5"
                    style={{ fill: "#9BB1A2" }}
                    d="M1343,606c-9.4,0.1-15.7-5.3-15.9-13.8c-0.2-9.2,8.7-17.5,19-17.6c9.4-0.1,16.6,6.2,16.7,14.6
		C1362.8,598,1353.7,605.7,1343,606z"
                />
                <path
                    className="st6 "
                    style={{ fill: "#A9B9B2" }}
                    d="M3512.9,1760.7c0.6,11.2-4.9,15.7-17.6,16.1c-7.3,0.2-13.5-7.8-13.3-16.7c0.2-9.9,6.3-17.3,15.3-16.8
		C3507.2,1743.7,3512.9,1749.6,3512.9,1760.7z"
                />
                <path
                    className="st3"
                    style={{ fill: "#B5B599" }}
                    d="M6148.5,960.4c3.5,6.5,8.4,8.1,15.1,5c7.4-3.4,7.5-3.4,11.7,3.8c1.8,3.5,4.8,6.2,8.4,7.7
		c5.6,2.4,6,6.8,1.4,10.5s-9.6,6.7-15.3,5.6c-4.9-0.9-8.9-0.7-12.9,2.5c-1.8,1.4-4.2,2.2-6.3,0.1c-1.8-1.6-2-4.4-0.4-6.2v-0.1
		c2.4-2.9,2-6,1.6-9.3c-0.8-7.3-0.6-7.3-8.9-5.8c-2.5-1-4.1-3.6-3.8-6.3c0.3-3.3-0.4-6.6-2-9.5c0.9-1.3,2.4-2,4-1.8
		C6142.9,958.8,6145.6,960.2,6148.5,960.4z M6180.6,982.2c-2.6-1.4-4.5-2.3-6.2-3.5c-2.3-1.5-3.1-4-4.2-6.5s-3.1-2-5.1-1.6
		c-5.2,1.3-6.7,4.6-6.1,12.4c0.3,3.3,1.6,4.3,4.7,4.8C6170.1,989,6174.8,985.4,6180.6,982.2L6180.6,982.2z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M3122.9,607.3c-10.7-0.1-13.9-2.6-16.9-11.9c-3.6-11.6,1.7-25.8,11.6-30.5c9.5-4.4,20.8-0.2,25.2,9.2
		c0.2,0.5,0.4,1,0.6,1.5s0,1.5,0.4,2c7,8.2,0.9,14.8-3,21.2C3136.5,605.5,3128.9,606.4,3122.9,607.3z M3137.6,583.3
		c0-4.2,0.8-8.4-4-11.2c-7.6-4.6-14.9-3.7-20.9,3.3c-3.7,4.3-4,18.4-0.6,22.5c0.8,1,2,1.7,3.3,2
		C3128.7,603.5,3137.5,596.9,3137.6,583.3L3137.6,583.3z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M6747.6,447.6c6.3,0,9.3,2.3,10.1,8.5c0.6,5.1,2.5,9.9,0.7,15.5c-1.8,6.5-8.5,10.2-15,8.4
		c-1.9-0.5-3.7-1.5-5.1-2.9c-6-5.7-5.3-22,1.3-26.9C6741.8,448.4,6744.7,447.5,6747.6,447.6z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M2204.6,733.8c-0.5,4.2,1.2,9.5-2.5,14.3c-6.4,8.1-20.9,9.5-29.4,2.3c-9.2-7.8-10.7-18.6-7.7-28.8
		c1.3-5.7,6-9.9,11.7-10.8C2198.5,707,2206,710.7,2204.6,733.8z M2200.8,733.4c0.2-6.8,1.2-13.1-5.8-16.3c-5.6-2.9-12.3-3.1-18-0.3
		c-8.2,4.2-10.6,17.5-5.2,25s18.2,9.3,25.5,4.2C2202.7,742.2,2200.1,736.9,2200.8,733.4L2200.8,733.4z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M5154.9,1430.1c5.8-0.6,11.2,3.4,12.2,9.2c2.8,11.1-5.6,21.1-16.5,19.8c-2-0.1-3.8-0.9-5.3-2.3
		c-3.5-3.2-4.9-13-2.7-18.6C5144.6,1433.3,5149.5,1430,5154.9,1430.1z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M2116.4,1256.6c0.4-4.4-2.3-9.7,4.8-11.1c7.6-1.5,15.4,1,18.1,6.5c2.5,4.9,2.2,10.2,1.6,15.4
		c-0.2,1.3-0.8,2.5-1.8,3.3c-5.1,3.6-10.8,2.3-16.2,1.4c-4.7-0.8-5.8-5-6.4-8.9C2116.2,1261,2116.2,1258.8,2116.4,1256.6z"
                />
                <path
                    className="st5"
                    style={{ fill: "#9BB1A2" }}
                    d="M1359.1,712.8c-5.5,0.2-10.6-0.3-13.2-6.2c-1.8-4-3-8,0.2-12s7.1-6.9,12.6-6c4.5,0.8,8.9,1.9,12.3,5.5
		c3.5,3.2,4.8,8.1,3.4,12.6c-1.4,4.6-5.5,5.2-9.5,5.7C1363,712.6,1361.1,712.8,1359.1,712.8z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M3175.4,1344.2c-7.8,0-9.9-3.2-9.8-14.5c0-9.6,4.3-14.3,13.4-14.5c7.3-0.2,12.2,5.4,11.9,13.7
		C3190.6,1338.3,3184.3,1344.4,3175.4,1344.2z"
                />
                <path
                    className="st4"
                    style={{ fill: "#AFB195" }}
                    d="M5600.2,1076.9c1.7-0.3,3.3,0.4,4.2,1.9c3.2,4.6,6.4,9.1,9.6,13.7c1.7,2.1,1.6,5.1-0.1,7.2
		c-2,2.9-5.3,3.7-7.7,5.7c-3.2,2.7-5.7,1.6-9,0.6c-3.8-1-7.7-1.7-11.6-2c-3.4-0.4-4.5-2.3-4.2-5.4c0.1-2,0.2-4,0.5-6
		c0.5-3.9,0.3-8.2,2-11.5c2.1-4.2,7.2-1.3,10.8-2.5C5596.4,1077.9,5598.3,1077.4,5600.2,1076.9z M5598.4,1082.9
		c-1.4,1.1-3,2-4.6,2.7c-4.5,1.2-5.5,4.1-4.4,8.1c0.3,1.3,10.9,7.8,11.9,7.4c0.6-0.3,1.1-0.7,1.5-1.2c2.3-2.6,0.9-14.1-2-16.2
		C5599.9,1083.4,5599.2,1083.1,5598.4,1082.9L5598.4,1082.9z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M2373.7,619.8c-11.1-0.2-19.4-7.9-19.3-18.1s9.8-19,21.2-18.9s18.1,6.3,18,17.4
		C2393.4,611.9,2385.2,619.9,2373.7,619.8z M2375.2,590.1c-3.4,0.4-7-0.6-10.3,2c-3.8,2.7-5.8,7.3-5.1,11.9c0.7,5.4,4.4,8.1,8.9,9.3
		c5,1.2,10.5,2.3,14.7-2.3c4.1-4.4,5.5-10.8,3.6-16.6c-1.8-6.2-7.5-4-11.9-4.5L2375.2,590.1z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M4531.3,66.4c-0.3-3.8,0.5-7.6,2.2-11.1c2.9-6.3,7.3-10.6,14.9-11s12.5,4,15.5,10.1c1.9,4.6,3.2,9.3,3.9,14.2
		c0.8,4.2-1.1,7.7-4,10.7c-6,6.5-14,7.9-23.6,4.3C4533.6,81.2,4531.2,76.6,4531.3,66.4z M4550.5,50.2c-8.2-0.2-11.7,3.2-14.4,13.5
		c-1.6,6.2-0.2,11,4.1,13.9c5,3.3,11.6,3.2,16.5-0.4c6.4-5.3,7-7.8,4-15.3c-0.9-2.1-1.6-4.3-2.4-6.4
		C4557,51.6,4554.4,49.6,4550.5,50.2z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M3407.4,531.9c-8.9,0-14.9-4-16.6-10.7c-2.1-6.2,0.7-12.9,6.5-15.9c1.1-0.7,2.3-1.2,3.6-1.5
		c5-1.1,11.4,4,14.1,11.4c2.6,7,1.3,12.8-3.8,15.5C3410,531.3,3408.7,531.7,3407.4,531.9z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M2435.3,794.5c0-8.1,5-13.1,12.7-13c6.4,0.1,13.5,7.7,13.6,14.5c0.2,7.6-4.9,12-13.6,11.9
		C2438.9,807.8,2435.2,803.9,2435.3,794.5z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M3983.6,1184.5c-0.4-5.5,1.1-10.9,4.3-15.4c4.6-7.3,14.1-9.4,21.4-4.9c2,1.3,3.7,3,5,5c2.3,4,4.1,8.2,5.4,12.6
		c3.9,10.1-7.9,22.8-17.9,21.4C3988.2,1201.3,3983.4,1197.2,3983.6,1184.5z M4001.5,1197.4c10.2,0.2,15.6-7.5,11.9-17.1
		c-0.8-2.5-1.8-5-2.9-7.4c-2.8-5.1-9.2-7.1-14.4-4.3c0,0,0,0,0,0c-0.9,0.5-1.7,1.1-2.4,1.8c-1.9,2.2-3.5,4.8-4.6,7.5
		C3984.2,1189.1,3989.4,1197.1,4001.5,1197.4L4001.5,1197.4z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M4954.1,1181.2c-8.6,0.1-15.2-5.5-15.6-13.3s5.4-14.1,12.2-13.9c6.5,0,13.9,9.8,14,18.1
		C4964.8,1177.5,4960.7,1181.1,4954.1,1181.2z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M3854.2,1662c0.2,7.3-5.6,13.3-12.8,13.5c-6.8,0-13.6-6.6-13.7-13c0-6,8.1-13.8,14.6-13.9
		C3847.8,1648.6,3854.2,1655.7,3854.2,1662z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M4328.6,1128.5c0,9.2-10.3,20.3-19.2,20.7c-8.5,0.3-17.9-10.2-18-20.2c-0.1-9.2,8.6-19.8,16.1-19.6
		C4315.7,1109.6,4328.6,1121.3,4328.6,1128.5z M4306.6,1114.6c-0.6,0.2-1.2,0.4-1.8,0.6c-7.6,3.5-11,16-5.8,22.5
		c2.6,3.2,5.8,6.4,10.7,6c7-0.8,13.9-9.5,13-16.2C4322.2,1123.7,4310.5,1114.4,4306.6,1114.6L4306.6,1114.6z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M5271.9,1312c-10.1-0.1-20.1-8.3-21.3-17.1c-0.6-4.4,1.7-8.8,5.8-10.6c8.1-4,17.3-5,26-2.6
		c6,1.4,7.9,7.2,8.4,13.2c0.6,6.6-1.2,11.9-7.4,14.7C5279.9,1311.4,5275.9,1312.3,5271.9,1312z M5271.5,1306.4h5.5
		c3.8,0.1,7.1-2.5,7.8-6.2c0.9-4.2,1.1-8.6-3-11.7c-5-3.8-17-4-22.4-0.4c-4.5,3.1-5.2,7.3-1.6,11.7S5265.6,1308,5271.5,1306.4z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M2717,602.4c0,10.8-7,13.9-17.3,14.3c-7.4,0.6-13.8-5-14.4-12.4c0,0,0,0,0,0c0-0.5,0-1.1,0-1.6
		c0-9.4,6.9-16.7,15.7-16.5c8.8-0.1,15.9,7,16,15.7c0,0,0,0,0,0C2717,602.1,2717,602.2,2717,602.4z M2701.3,609.9
		c5.6,0,9.4-3.1,9.7-7.6s-5.5-10.3-10.1-10.2c-4.2,0.1-9.6,5.4-9.7,9.6C2691.1,606.7,2695.2,609.9,2701.3,609.9L2701.3,609.9z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M3066.3,1087.1c0,6.6-6.1,13.7-11.7,13.9s-13.5-8.1-13.6-13.7c0.1-6.5,5.4-11.7,11.9-11.6h0.3
		C3061.6,1075.7,3066.3,1079.8,3066.3,1087.1z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M3297.1,1166.1c10.8,0,16.4,5,16.9,14.4c0.4,9.9-5,15.6-15.5,16.4c-11.3,0.8-17.5-2.6-22.5-12.5
		c-2.8-4.8-1.3-10.9,3.5-13.8c0,0,0,0,0,0c0.3-0.2,0.7-0.4,1-0.5C3285.5,1167.2,3291.3,1165.9,3297.1,1166.1z M3295.8,1191.3h4.7
		c4,0,7.1-0.8,7.9-5.5c1.1-6.4-0.7-12.4-5.3-13.4c-6.4-1.4-13-2-19.3,0.7c-6,2.6-6.6,6.5-2.6,11.7S3290,1192.6,3295.8,1191.3
		L3295.8,1191.3z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M6098.6,26.4c0,6.7-7.1,14.1-12.9,13.8c-5.4-0.3-12.3-7.8-12.5-13.4c-0.1-6.3,5-11.5,11.4-11.6c0,0,0,0,0,0
		h0.7C6093.7,16,6098.8,18.9,6098.6,26.4z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M3153.2,436.1c-0.2,12.1-2.9,15.1-13,15s-17.6-6.8-17.7-15.9s7.1-15.5,16.9-15.2
		C3149.6,420.3,3153.4,424.8,3153.2,436.1z M3147.8,435.3c0.9-7.9-3.6-9.6-9-9.8c-6.4-0.1-10.8,3.5-10.9,8.7
		c0,5.8,5.1,10.8,11.5,10.9C3146.2,445.3,3147.8,443.5,3147.8,435.3z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M1670.4,535.2c6.2-0.4,11.7,4.1,12.4,10.3c0.5,6.4-7,14.2-13.7,14.3c-5.6,0-9.5-4.5-9.9-11.4
		C1658.8,540.1,1662.7,535.5,1670.4,535.2z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M829.1,1720c0,8-7.6,17-14.7,17.2c-8.6-0.1-15.8-6.8-16.4-15.4c-0.2-7.3,8.8-15.9,17.2-16.2
		S829.1,1710.9,829.1,1720z M813.4,1731.3c5,0.1,10.3-5.8,10.2-11.2c-0.1-4.4-3.1-8.1-6.7-8.3c-6.5-0.5-13.1,4.8-13.1,10.6
		C804,1727.4,808.3,1731.4,813.4,1731.3L813.4,1731.3z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M3071.2,526.6c-6.6,0-10.7-5-10.5-12.7c0.2-5.3,6.6-12.4,11.1-12.2c6,0.3,12.5,7.9,12.3,14.5
		C3083.9,522.8,3079.1,526.6,3071.2,526.6z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M1509.2,358.3c7-0.2,12.8,5.3,13,12.3v0.7c-0.2,6.7-5.7,11.5-13.3,11.6c-4.2,0-9.7-7.1-9.9-13
		C1498.8,362.8,1502.6,358.4,1509.2,358.3z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M2341.4,1704.4c0,8.7-6.5,16.2-14.1,16.4c-8.2,0.2-16.2-7.9-16.4-16.8c-0.2-7.5,8.6-16.2,16.1-15.9
		C2334,1688.2,2341.4,1696.6,2341.4,1704.4z M2336.1,1704.7c0-4.8-5-10.7-9.5-11s-10.4,5.3-10.4,9.9c0,5.6,5.3,11.7,10,11.6
		S2335.9,1709.7,2336.1,1704.7L2336.1,1704.7z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M4699.7,1719.8c0,9.4-6.5,17.4-14.1,17.4c-8.3,0-17.2-8.6-16.9-16.3s9.7-15.3,18.2-15.3
		C4695,1705.6,4699.7,1710.8,4699.7,1719.8z M4694,1720.6c0-8.4-2.8-10.4-11-7.8c-0.9,0.3-2,0.5-2.8,0.8c-3.9,1.3-6.4,5-6,9.1
		c0.2,4.3,4,7.8,9.9,9.5c4.3,1.2,5.8-2.1,7.9-4.3s1.8-4.7,1.8-7.3L4694,1720.6z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M204.7,383.1c-5.8-0.2-10.7-4.5-11.6-10.2c-0.5-5.2,6.2-12.1,11.9-12.5c6.2-0.4,11.5,4.4,11.9,10.6l0,0
		C217.3,376.5,210.8,382.9,204.7,383.1z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M86.7,298.5c0.1,6.2-4.7,11.4-10.9,11.8c-6.5,0-10.4-4.9-10.3-12.9c0.1-6.9,4.6-11.8,10.6-11.7
		C82.4,285.8,86.9,291.2,86.7,298.5z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M1878.3,177.2c6.6,0,10.3,3.5,10.6,10.2c0.2,5.7-5.8,12.2-11.7,12.6s-11.2-5.2-11.3-11.4
		S1871.5,177.4,1878.3,177.2z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M6215.6,272.2c0.2,3-0.6,6-2.2,8.5c-3,4.9-11.7,5-14.1,0c-2.9-5.8-3.8-11.9-0.9-18.1s6.5-6.6,12.3-2.5
		C6215.1,263.3,6215.9,267.4,6215.6,272.2z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M2203.5,578.2c7.9,0.1,13,6,12.6,14.4c-0.4,9.9-6.4,17.2-14.2,17.2c-7.9-0.3-14.2-6.9-13.9-14.9
		c0-0.1,0-0.2,0-0.2C2188.2,584.2,2194,578,2203.5,578.2z M2210.8,593.7c0.2-7.6-1.3-9.5-7.7-9.6s-9.7,2.8-9.9,9.4
		c-0.2,6.2,3,10.2,8.1,10.2c5.7,0,9.4-3.8,9.6-10L2210.8,593.7z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M5963.3,170.2c0.2,9.3-6.1,16.6-14.5,16.7c-7.6,0.1-15.6-7.9-15.9-16s7.7-16.4,15.5-16.4
		C5955.6,154.6,5963.2,162.5,5963.3,170.2z M5938.2,170.1c-0.2,6.5,3.7,11,9.7,11.1s11.4-4.7,11.6-9.8c0.2-4.5-7-11.9-11.4-11.9
		S5938.3,165.2,5938.2,170.1L5938.2,170.1z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M4478.7,1016c-0.5,10.1-5.3,15-14.5,14.6s-15.2-7.9-14.6-17.6c0.5-6.8,8.7-15.1,14.8-14.8
		C4472.5,998.6,4479.2,1006.8,4478.7,1016z M4465,1024.8c5.3,0.4,9.9-3.5,10.3-8.8c0,0,0,0,0,0c0-0.2,0-0.5,0-0.7
		c0.1-4-6.6-11.7-10.4-11.9s-11,7-11.1,11.2C4453.6,1019.6,4459.1,1024.7,4465,1024.8L4465,1024.8z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M3831.8,1105.4c-10.1,0-15.2-4.3-15.1-12.8c0.2-9.7,6.6-15.9,16.7-15.6c7.9,0,14.3,6.3,14.3,14.2
		c0,0.2,0,0.3,0,0.5C3847.9,1100,3841.6,1105.4,3831.8,1105.4z M3831.1,1100.1c8.1,0.1,11.3-2.7,11.2-9.9c0-6.2-1.7-7.7-8.4-7.9
		c-7.4-0.2-13.2,4.3-13.1,10C3820.9,1097.2,3824.5,1099.9,3831.1,1100.1L3831.1,1100.1z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M2476,699.2c-9.4-0.3-13.1-4.3-12.9-13.5s6-14.2,15.3-14.1c8.9,0.2,14.1,6.2,13.6,15.6
		C2491.6,695.3,2486,699.4,2476,699.2z M2477.1,693.5c7.4,0,9.3-1.5,9.4-6.9c0.2-6.2-2.6-9-8.6-9.1c-6.7,0-10.9,3.6-10.9,9.8
		C2467.1,692,2469.4,693.5,2477.1,693.5L2477.1,693.5z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M1684,1690.5c-0.6,7.4-6.5,13.3-13.9,13.9c-7.2,0-12.4-5.7-12.4-13.7s6.2-14.7,13.1-14.8
		S1684,1682.8,1684,1690.5z M1677.1,1690.1c0-6-1.7-8.3-5.8-8.4c-4.7,0-8.2,4-8,9.2c-0.2,4.4,3.2,8.1,7.6,8.3h0.2
		C1675.5,1699.2,1677.2,1696.7,1677.1,1690.1L1677.1,1690.1z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M561.6,79.3c1.3-3.7-4.1-7.9-0.6-11.7c2.9-3,7.5-0.3,11.3-1.6c6.9-2.3,7.2-2,9.2,4.5c1,3.1,2.9,5.9,5.4,7.9
		c3.2,2.8,1.7,4.7-1.4,6.7c-7.5,4.9-15.3,1-22.8,1C559.4,86.4,561.4,82.2,561.6,79.3z M575.3,70.5c-1.9,1.2-4.1,2.4-6,4
		c-1.9,1.3-2.4,3.8-1.2,5.7c0.1,0.1,0.2,0.2,0.2,0.3c1.9,2.8,5.4,4,8.6,3.1c3.1-0.9,2.5-3.5,1.4-5.6s0.1-5.4-3-7.6L575.3,70.5z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M5423.8,1302.1c0.2,6.8-6,14.5-12.5,15.7c-3.3,0.6-6.7-0.2-9.4-2.1c-4.3-2.9-5.3-8.5-2.8-14.1
		c3.4-7.5,10.2-12.7,15.4-11.9S5423.6,1295.8,5423.8,1302.1z M5419.5,1302c-0.9-2.3-1.5-5.1-4.1-6.3s-4.2,1.1-5.8,2.6
		c-2.2,2-4.3,4.1-6.1,6.5c-1.6,2.1-1.1,5.1,1,6.6c0.7,0.5,1.5,0.8,2.3,0.9C5411.2,1313.3,5419.7,1306.4,5419.5,1302L5419.5,1302z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M850.8,318.6c4-0.3,15.6,9,16.1,12.8c1,6.8-6,15.4-13,16.2c-4.9,0.5-8.1-2.7-10.7-6c-5.2-6.4-1.8-19,5.8-22.5
		C849.5,318.9,850.2,318.7,850.8,318.6z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M5776,976.7c-3.7-2.4-8.9-3-12.3-7c-4.4-5.2-3.5-6.9,2.3-10.9c5.4-3.7,9-2.4,14,0.6c9.9,6,10.1,6.3,1.4,14.3
		C5779.9,975.1,5778.8,976.8,5776,976.7z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M5251.7,963.2c6.8,0,9.9,3.2,9.7,9.5c-0.6,6.8-6.3,12-13.1,12.2c-5-0.1-8.7-4.3-8.6-9.9
		C5239.8,968.4,5245.2,963.1,5251.7,963.2z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M2540.4,600c0-10.9,3-13.9,13.2-13.9c9.2,0,12.7,3.8,12.5,13.7c-0.2,8.8-6.7,15.4-14.9,15.1
		C2544.4,614.6,2540.4,609.1,2540.4,600z M2553,592.1c-5.7,0.1-8.6,3-8.4,8.4c0.2,4.9,3.6,9.2,7.2,9.1c5.8-0.5,10.3-5.4,10.2-11.2
		C2561.7,593.3,2559.6,591.9,2553,592.1L2553,592.1z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M3499,65.6c0.3-7.5,5.5-13.8,12.8-15.6c5-1.2,10.1,1.2,12.4,5.8c3.7,6.3,2.4,13.2-3.5,18.7
		c-3.4,3.2-7.1,6.4-12.3,3.7S3498.5,72.4,3499,65.6z M3511.7,73.7c3.3,0,10.1-6.9,10.4-10.8c0.3-3.1-5.1-8.5-8.3-8.4
		s-10.6,7.5-10.6,10.7S3508.6,73.6,3511.7,73.7L3511.7,73.7z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M4664.2,1217.7c-0.2-7.9,3.6-12.3,10.6-12.4c6,0,10.3,2.9,10.3,7c0,5.8-7.3,14.2-12.9,14.6
		C4667.7,1227.2,4664.3,1223.3,4664.2,1217.7z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M3582.2,1125.1c8.1-0.1,11.9,6.1,8.2,13c-0.7,1.1-1.4,2.2-2.2,3.2c-3.8,5-8.9,7.1-12.5,5.2s-4.6-6.2-3.2-12.2
		S3577.4,1125.2,3582.2,1125.1z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M2259,522.6c9.2,0,12.8,3,12.9,10.8c0,10-6.2,16.8-15.5,16.5c-7.9-0.3-11.9-5.3-11.9-14.8
		S2247.9,522.6,2259,522.6z M2256.4,544.4c6.1,0.1,11.9-5.3,11.7-10.9c-0.2-4-4-6.7-9.4-6.7c-6.4,0-10,4-9.9,10.8
		c0.1,4.9,2.1,6.6,7.6,6.7L2256.4,544.4z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M2965.7,265.7c-6.3,0.4-11.7-4.4-12.1-10.6c0-0.3,0-0.6,0-0.8c-0.2-7.2,5.4-12.6,13.3-12.7
		c6.6,0,13.4,5.7,13.7,11.6C2981,259.9,2974.2,265.5,2965.7,265.7z M2964.5,258.6h3c4,0,7.2-0.9,7.5-5.6c0.2-4-2.3-6.1-6.9-6.1
		c-5.4,0-10.4,3.2-10.4,6.8c-0.1,2,1.2,3.9,3.2,4.4C2962,258.4,2963.3,258.5,2964.5,258.6L2964.5,258.6z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M2463.2,561.6c-0.1-7.9,6.3-14.4,14.2-14.4c0,0,0,0,0,0h0.2c5.9,0,10.7,4.7,10.8,10.6
		c0.3,7.9-6.2,15.5-13.4,15.8c-6.4,0.1-11.7-5-11.8-11.4c0,0,0,0,0,0C2463.2,562,2463.2,561.8,2463.2,561.6z M2474.9,568.1
		c4,0,7.9-4.8,7.9-9.7c0-4.1-2.4-6-6.3-5.8c-5.1,0.1-9.5,4-9.3,8.2C2467.5,564.8,2470.8,567.9,2474.9,568.1L2474.9,568.1z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M3822,1000c0,7.5-5.7,13.8-13.2,14.4c-7.9,0.2-12.2-4.3-12.2-13s4.6-14.3,12.4-14.4c7-0.2,12.7,5.3,12.9,12.3
		C3822.1,999.6,3822,999.8,3822,1000z M3816.4,1001.8c0.4-6.2-2.1-10.5-6.2-10.6c-5.2,0.2-9.3,4.4-9.5,9.6c-0.5,5.5,1.7,7.9,7.3,8.1
		C3814.1,1009.1,3816.1,1007.4,3816.4,1001.8L3816.4,1001.8z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M3447,619.7c-5.2-0.1-9-4.6-8.9-10.8c0-4.9,3.3-8.5,7.6-8.4c6.1,0,13.2,6.2,13.1,11.1
		C3458.6,615.9,3452.9,619.8,3447,619.7z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M3836.2,1368.9c-7.1,0-11.6-4.6-11.6-11.9c0-8.2,5.2-14.3,12.1-14.1c6,0.2,12.9,6.9,13.1,12.8
		C3849.9,1362.3,3843.1,1368.9,3836.2,1368.9z M3836.2,1348.4c-4.4,0.2-7.8,3.9-7.6,8.3c0,0,0,0.1,0,0.1c0.5,4,3.9,7,7.9,7
		c4.3-0.1,7.6-3.7,7.5-7.9S3840.5,1348.3,3836.2,1348.4L3836.2,1348.4z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M2684.7,928.1c9.3,0,13.1,2.9,13.4,10.7c0.6,6.4-4,12.1-10.4,12.8c-0.5,0.1-1,0.1-1.5,0.1
		c-6.5,0-13-6-13.2-12.2C2672.7,932.3,2676.9,928.1,2684.7,928.1z M2693.8,939.6c0.3-3.1-3.2-6.4-7-6.8s-8.2,2.6-8.5,6
		c-0.3,4.4,1.6,6.7,5.9,7.1C2689.1,946.4,2693.4,943.5,2693.8,939.6L2693.8,939.6z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M3429.5,1094.8c-0.1,5.2-4,9.2-8.6,8.9c-5-0.4-10.7-6.4-10.7-11.4c0-4.1,4.9-8.8,9.1-9
		C3424.4,1083.2,3429.5,1089.1,3429.5,1094.8z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M3642.8,1762c4.6-0.2,9.8,5,10.2,10.1c0.4,4.9-3.1,9.9-7,10.3c-4.9,0.4-11.2-5-11.6-9.9
		C3634.1,1767.8,3638.6,1762.2,3642.8,1762z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M5440.8,1146.2c4.4-0.5,8.3,2.7,8.7,7.1c0,0.5,0,0.9,0,1.4c-0.1,6.2-4.9,11.3-11.1,11.7
		c-3.5,0-7.9-4.8-7.9-8.8C5430.5,1152.2,5435.6,1146.4,5440.8,1146.2z"
                />
                <path
                    className="st8"
                    style={{ fill: "#C0A4AC" }}
                    d="M1481.9,540.1c0,5-4.8,9.3-10.5,9.6c-5.1-0.1-9.3-4.1-9.5-9.2c0-4.5,5.6-9.4,10.8-9.7c4.9-0.1,9.1,3.8,9.2,8.8
		C1481.9,539.8,1481.9,539.9,1481.9,540.1z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M3395,594c-5.2-0.2-9.2-4.6-8.9-9.8c0-0.3,0-0.6,0.1-0.9c0.3-5.2,4.8-9.2,10.1-8.9c5.2,0.3,9.2,4.8,8.9,10.1
		C3404.8,590.5,3400.7,594.4,3395,594z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M4817,1759.3c0-6,5-10.9,11-10.9h0.6c6.5,0.4,11.5,5.9,11.4,12.4c0,6.5-5,12.1-10.7,11.9
		S4816.8,1765,4817,1759.3z M4829,1766.8c3.1,0,5.7-2.6,5.6-5.7c0,0,0,0,0,0c0-0.3,0-0.6-0.1-0.9c-0.4-3.5-4.1-7.3-7.1-6.8
		c-3.6,0.5-4.7,3.1-4.7,6.4C4822.7,1764.3,4825.1,1767,4829,1766.8L4829,1766.8z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M1845.7,1684.9c0.3-8.3,3.6-11.5,11.6-11.2c7.2,0.3,10.4,4,9.9,11.7s-6.1,13.7-11.9,13.4
		S1845.5,1692.6,1845.7,1684.9z M1862.8,1684.1c-0.1-3.2-2.4-4.2-5.3-4.4c-5.1-0.4-7.7,1.6-7.9,6.4c-0.1,3.6,0.6,6.8,5,7.1
		C1858,1693.4,1862.7,1688.1,1862.8,1684.1L1862.8,1684.1z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M5317.1,1375c0.8-6.5,6.5-11.2,13-10.7c5.8,0.5,10.6,6,9.9,11.3c-0.8,6.1-7.4,12.4-12.5,11.8
		C5321.5,1386.4,5317.1,1381.1,5317.1,1375z M5328.7,1370.1c-3.4,0-6.3,2.4-7,5.6c-0.6,2.5,3.6,6.5,6.5,6.1c3.4-0.3,6.2-3,6.4-6.5
		C5334.8,1371.6,5332.1,1370.3,5328.7,1370.1L5328.7,1370.1z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M538.8,454.6c-7,0-12.8-5.1-12.6-11.1c0.2-6.5,4.4-10.1,11.9-10.1s12.7,4.3,12.7,10.7
		C550.8,449.9,545.5,454.6,538.8,454.6z M538.4,439.2c-3.7,0.2-8.2,0-7.8,5c0.4,4.4,4.8,4.7,8.4,4.8s6.6-1.4,6.5-5.6
		C545.5,438.4,541.4,439.4,538.4,439.2L538.4,439.2z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M4223.4,181.2c0.3,4.5-3,8.4-7.5,8.9c-4.6,0.3-11-6-11.2-10.8c-0.1-4.4,4.3-9.3,8.5-9.5
		C4218.1,169.7,4223.4,175.6,4223.4,181.2z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M3522.8,1596.6c0.1,6.2-3,10.4-7.9,10.5c-5.1-0.2-9.3-4.3-9.5-9.5c-0.3-6.3,3.4-10.9,8.8-10.9
		S3522.7,1590.5,3522.8,1596.6z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M968.5,1761.2c-0.1,6.8-4,11.2-9.7,11.3s-13.3-7.3-13.2-12.6c0-5.8,6.7-12.1,12.5-11.8
		C964.5,1748.5,968.7,1753.7,968.5,1761.2z M958.6,1766.9c3.1,0.2,5.8-2.1,6-5.2c0,0,0,0,0,0c0-0.3,0-0.6,0-0.9
		c-0.1-3.4-3.6-7.2-6.7-7.3c-3.6,0.5-6.4,3.4-6.8,7C952,1763.9,954.9,1766.5,958.6,1766.9L958.6,1766.9z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M4522.6,1175.6c-0.5,4.7-4.6,8.1-9.3,7.6h-0.4c-4.9-0.7-9.9-7.4-9.2-12.4c0.8-4.4,4.9-7.4,9.3-7
		C4518.7,1164.8,4522.8,1169.8,4522.6,1175.6z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M1282.1,1311.6c4.8,0.4,8.5,4.3,8.7,9.2c0,5.3-4.2,10.3-8.7,10.4c-4.9,0-9.9-6.2-9.8-11.9
		C1272.6,1314.7,1276.8,1311.4,1282.1,1311.6z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M6846.9,1316.2c0.3,4.8-3.3,9-8.1,9.5c-4.6,0.3-10.4-5-10.7-9.9s4.4-9.1,9.7-9.2S6846.8,1310.5,6846.9,1316.2z
		"
                />
                <path
                    className="st8"
                    style={{ fill: "#C0A4AC" }}
                    d="M5269.3,1661.8c0,4.7-3.3,9.2-7,9.2c-4.9,0-10.3-6.1-10.1-11.5c0.1-4.7,3.5-8.9,7.3-9
		C5264.8,1650.4,5269.2,1655.4,5269.3,1661.8z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M5749.2,1647.8c-6.2-0.2-11.1-5.1-11.3-11.3c0.3-5.9,5.3-10.4,11.2-10.3c6.5,0.2,11.8,5.4,12,11.9
		C5760.9,1643,5755,1647.9,5749.2,1647.8z M5755.3,1637.4c0.1-2.8-2.1-5.2-5-5.3c0,0,0,0,0,0c-0.2,0-0.4,0-0.6,0
		c-2.9-0.4-5.6,1.7-5.9,4.6c0,0.1,0,0.2,0,0.4c-0.4,3.7,2,5.3,5,6.1C5751.3,1643.8,5755.1,1640.3,5755.3,1637.4z"
                />
            </g>
            <g className="flicker-2">
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M6343.1,1632c-0.2,5.9-4.7,10.6-10.6,11c-6.2,0.4-11.6-4.3-12.1-10.5c-0.3-6.1,4.4-11.2,10.4-11.5
		c0,0,0,0,0.1,0h0.5C6337.6,1620.9,6342.8,1625.7,6343.1,1632z M6338.5,1632c-0.5-2.9-2.8-5.1-5.7-5.6c-3.1-0.7-7.5,2.4-7.2,5
		c0.3,3,2.6,5.5,5.6,6.2C6333.7,1638.4,6338.1,1635,6338.5,1632L6338.5,1632z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M4005.1,995.7c-0.3,9-4,13.2-11.3,13c-6.6-0.2-10.3-5-10.1-13c0.2-7.3,4.9-12.1,11.6-11.9
		S4005.4,987.8,4005.1,995.7z M4001.1,995.7c0-4.2-1.9-6.4-5.6-6.5c-4.3-0.1-7.8,3.3-7.9,7.6c0,0,0,0,0,0v0.4c0.2,4,2.6,5.7,6.4,5.8
		C3998.6,1003,4001.1,1000.6,4001.1,995.7L4001.1,995.7z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M2511.8,1643.9c-6,0.1-10.3-3.1-10.6-7.9c0-5.3,4.3-9.6,9.6-9.6h0.6c4.8-0.2,8.9,3.6,9.1,8.4
		c0,0.3,0,0.6,0,0.9C2520.5,1641.3,2517.9,1643.8,2511.8,1643.9z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M2395.3,1278.8c-5.1,0.1-9.3-4-9.4-9.1l0,0c0-5.3,3.6-8.4,9.7-8.3s9.4,3.2,9.3,8.7
		S2400.9,1278.9,2395.3,1278.8z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M5336.8,1178.4c-0.4,6.1-4,9.5-9.3,9c-5-0.5-8.6-5.6-7.9-11.6c0.3-4.7,4.3-8.3,8.9-8c0,0,0,0,0.1,0h0.3
		C5333.7,1168.3,5337.1,1172.9,5336.8,1178.4z"
                />
                
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M3121.6,1662.4c0.3,5-5.4,11-10.6,11.2c-4.5,0-8.3-3.4-8.6-7.9c-0.4-4.9,3.2-9.2,8.1-9.7c0.2,0,0.5,0,0.7,0
		C3117.8,1655.6,3121.3,1657.8,3121.6,1662.4z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M4649.4,937.4c6,0,10.4,3.8,10,8.5s-5.7,9-10.3,8.9c-4.9,0-8.9-3.9-9-8.8c0,0,0,0,0,0c0-0.3,0-0.6,0-0.9
		C4640.4,939.6,4643.1,937.4,4649.4,937.4z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M2709.5,1007.5c4.1,0.1,7.9,4.7,7.9,9.7c0,5.6-3.8,10-8.6,9.9c-5.1-0.4-8.9-4.8-8.5-9.8v-0.1
		C2700.3,1012,2704.4,1007.8,2709.5,1007.5z"
                />
                <path
                    className="st8"
                    style={{ fill: "#C0A4AC" }}
                    d="M6686.6,169c0-5.4,3.6-9.5,7.9-9.2s9.1,6.3,9.1,11.4c0,3.8-4.4,8.9-7.8,8.8
		C6691.3,179.9,6686.6,174.3,6686.6,169z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M2371.1,1268.6c0,4.2-4.7,9.1-8.8,9.1c-5,0-9.6-3.6-9.8-7.8c-0.1-5.2,4.9-10.5,9.9-10.3
		C2367.3,1260,2371,1263.8,2371.1,1268.6z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M3363.4,1260.9c-0.5,6.5-5.9,11.6-12.4,11.6c-5-0.2-10.3-6.2-10.2-11.6s6.8-11.1,12.2-11.2
		S3363.4,1255.2,3363.4,1260.9z M3351.5,1255.1c-3.3,0.2-6,2.7-6.5,6c-0.4,2.3,4.9,7.2,7.2,7.2c2.9,0,6.2-4.7,5.8-7.9
		S3355.2,1255.1,3351.5,1255.1L3351.5,1255.1z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M3100.1,192c0,6.8-4.9,12.8-10.7,12.9c-5.3,0-10.6-6-10.5-12.1s4.7-11.3,9.9-11.5c5.7-0.5,10.8,3.7,11.3,9.4
		C3100.1,191.2,3100.1,191.6,3100.1,192L3100.1,192z M3088.8,185.6c-4.8,0.9-4.7,4.6-4.6,8.2c0,3,0.3,6.2,4.2,6.4
		c3.1,0.2,5.9-4.5,5.6-8.9C3093.8,188.1,3092.5,185.8,3088.8,185.6L3088.8,185.6z"
                />
                <path
                    className="st12"
                    style={{ fill: "#A4BBC2" }}
                    d="M4243.2,631.9c0,6-5.4,11.7-11,11.7s-10.6-6-10.5-11.9c0-5.5,5.5-11.5,10.2-11.3
		C4237.5,620.5,4243.2,626.4,4243.2,631.9z M4231.3,624.5c-3.3,0.8-5.6,3.7-5.7,7.1c-0.3,3,2,5.7,5,6c0.3,0,0.6,0,0.9,0
		c3,0.1,5.6-2.2,5.7-5.2c0-0.4,0-0.8-0.1-1.2C4237,627.9,4234.6,625.2,4231.3,624.5z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M1028.1,1720.4c0,6.4-4.7,12.2-10,12.3s-9.9-5.1-9.7-11.3c0.3-5.7,4.7-10.4,10.4-11.2
		C1023.9,1710,1028.1,1714.4,1028.1,1720.4z M1023.8,1721c0.3-2.5-1.3-4.8-3.8-5.2c-3.2-0.7-5.7,1.9-5.8,6c0,2.3,0,5,3.1,5
		C1021,1726.8,1022.9,1724.2,1023.8,1721L1023.8,1721z"
                />
                <path
                    className="st12"
                    style={{ fill: "#A4BBC2" }}
                    d="M4788.2,1610.9c4.4,0.4,7.9,3.7,8.4,8.1c0.2,5.3-4.6,10.9-9.2,10.7c-4.4-0.3-7.9-4-7.8-8.4
		C4779.5,1616.6,4784.2,1611,4788.2,1610.9z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M6863,616.2c-0.1,4.6-4.5,9-8.8,8.8s-8.8-6-8.5-10.4c0.3-4.2,4-7.2,8.9-7.3c4.6,0,8.3,3.6,8.4,8.2
		C6863.1,615.7,6863.1,615.9,6863,616.2z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M3431.6,1201c-4,0.2-7.9-4-7.9-8.4s5.6-10.8,9.8-10.9c3.4,0,7,4.6,7.2,9.2S3436,1200.8,3431.6,1201z"
                />
                <path
                    className="st12"
                    style={{ fill: "#A4BBC2" }}
                    d="M2066.8,1606.1c0,4.1-3,7.5-7.1,7.9c-4.7,0.3-10.1-4.7-10.7-9.7c-0.2-4,2.8-7.5,6.9-7.7h0.2
		C2061.5,1596.8,2066,1600.8,2066.8,1606.1z"
                />
                <path
                    className="st12"
                    style={{ fill: "#A4BBC2" }}
                    d="M909.1,1619.5c0.5-4.8,4.9-8.9,9.1-8.5s7.9,6,7.3,10.2s-5.7,8.9-9.4,8.5
		C911.7,1629.2,908.6,1624.6,909.1,1619.5z"
                />
                <path
                    className="st12"
                    style={{ fill: "#A4BBC2" }}
                    d="M2652.2,1501c4.7-0.4,8.7,3.1,9.1,7.7c0,0.1,0,0.1,0,0.2c0.4,4.9-3.2,9.2-8.1,9.6c0,0,0,0,0,0
		c-0.2,0-0.5,0-0.7,0c-4.4-0.2-8-3.8-8.2-8.2c-0.5-4.6,2.9-8.8,7.5-9.3L2652.2,1501z"
                />
                <path
                    className="st12"
                    style={{ fill: "#A4BBC2" }}
                    d="M5677.8,62.7c4.6-0.2,10.1,5.2,10.3,10.2c0.2,4.1-3.2,8.3-6.7,8.3s-10.2-6.6-10.5-10.5
		C5670.6,66.6,5673.7,63.1,5677.8,62.7z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M4899.5,1721.9c-0.8,6.3-6.6,11.4-12,10.7c-4.5-0.6-9-6.9-8.5-11.9s7.2-10.6,11.8-10.1
		C4896.3,1711.4,4900.2,1716.4,4899.5,1721.9z M4895.8,1720.5c0.1-2.6-2-4.8-4.6-4.9h-0.4c-3-0.6-5.9,1.4-6.4,4.3c0,0,0,0,0,0
		c-0.1,0.5-0.1,0.9-0.1,1.4c-0.2,2.8,0.1,5.5,3.8,5.7C4891.9,1726.9,4895.1,1724.2,4895.8,1720.5L4895.8,1720.5z"
                />
                <path
                    className="st12"
                    style={{ fill: "#A4BBC2" }}
                    d="M4197.7,924.8c-5.4-0.4-10.6-6.3-9.9-11.4c0.5-4,3.7-7.1,7-6.9c4.3,0.2,10.7,7.5,10.2,11.7
		C4204.5,921.9,4201.4,924.6,4197.7,924.8z"
                />
                <path
                    className="st12"
                    style={{ fill: "#A4BBC2" }}
                    d="M3434.7,1331.5c-4.4,0-8.2-3.2-8.9-7.5c-0.5-5.1,3.4-10.3,8-10.7c3.6-0.3,7.9,4,8.5,8.2
		S3438.7,1331.2,3434.7,1331.5z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M2847.9,1737.8c0.2,4.2-4.8,10.5-8.6,10.6c-3.3,0.1-8.2-5.7-8.3-9.8c0-3.7,4.4-8.4,8.2-8.5
		C2843.6,1730.1,2847.4,1733.4,2847.9,1737.8z"
                />
                <path
                    className="st8"
                    style={{ fill: "#C0A4AC" }}
                    d="M660.7,177c-0.1,6-2.5,8.3-8.4,7.9c-5.3-0.2-7.3-2.4-7.3-8.1s2.6-8.8,7.9-8.8S660.9,171,660.7,177z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M5592.8,1446.3c-0.3,4.4-3.6,8-7.9,8.7c-3.8,0.2-9.3-5.1-9.2-8.8c0.3-4.4,3.7-8,8.1-8.4
		C5588.6,1437.8,5592.6,1441.5,5592.8,1446.3z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M5269.1,1442.8c0,4.5-3.5,8.2-7.9,8.4c-4.7-0.3-8.5-4.1-8.8-8.8c0.4-4.8,4.5-8.4,9.2-8.3
		C5266,1434.6,5269.3,1438.4,5269.1,1442.8z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M4986.3,1048.1c0.3,4-4.2,9.5-8.1,9.9s-7.9-3.6-8.2-8.1c-0.4-4.5,2.9-8.5,7.4-8.9c4.3-0.6,8.4,2.4,9,6.8
		C4986.3,1047.9,4986.3,1048,4986.3,1048.1z"
                />
                <path
                    className="st8"
                    style={{ fill: "#C0A4AC" }}
                    d="M3335.1,1624.1c0.3,4.3-4.7,9.4-9.5,9.6c-4.4,0.2-8.1-3.3-8.2-7.7c0-0.1,0-0.2,0-0.3c-0.2-4,3-7,7.7-7.4
		C3330.3,1617.8,3334.8,1620.4,3335.1,1624.1z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M3220.5,1071.4c0,4.8-3.9,8.7-8.7,8.6c-4.4-0.5-7.7-4.2-7.8-8.6c0.3-4.4,3.8-7.9,8.2-8.1
		C3216.6,1063.5,3220.2,1067,3220.5,1071.4z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M4383.6,233.1c0,4.1-5.3,9.3-8.9,8.9c-3.3-0.3-7.9-5.6-7.9-8.9c0.3-4.5,4-7.9,8.5-7.9
		C4379.7,225.1,4383.4,228.6,4383.6,233.1z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M5108.9,1016.3c-4.4-0.3-7.9-4-7.9-8.5c0.1-4.5,3.8-8.1,8.3-8.2c4.1-0.1,7.7,3,8.2,7.1
		C5117.4,1011.2,5113,1016.2,5108.9,1016.3z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M3276.7,1328c0.1-5.2,2.8-7.7,8.1-7.6s7.7,2.7,7.6,8.1c0.3,4.5-3.1,8.3-7.6,8.7
		C3280.2,1337.3,3276.7,1333.3,3276.7,1328z"
                />
                <path
                    className="st8"
                    style={{ fill: "#C0A4AC" }}
                    d="M2635.6,709.6c-0.1,4.5-2.6,7.2-6.4,6.9c-4-0.3-8.8-6-8.7-10.5c0-4,4-7.9,7.4-7.7
		C2631.8,698.9,2635.9,704.7,2635.6,709.6z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M4689.4,1032.5c0,4.1-3.3,7.5-7.4,7.7c-4.4-0.1-8.2-3.1-9.2-7.4c-0.5-3.4,4-8.8,7.4-9.2
		S4689.2,1028.4,4689.4,1032.5z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M5197,1185.9c0.1,3.6-4.4,8.6-7.9,8.8s-8.3-4.2-8.4-8.1c-0.1-4.5,3.3-8.2,7.7-8.5
		C5192.8,1178.2,5196.5,1181.5,5197,1185.9z"
                />
                <path
                    className="st8"
                    style={{ fill: "#C0A4AC" }}
                    d="M5619.5,1695.8c4.1-0.1,8.1,3.2,8.2,6.9c-0.3,4.8-4.2,8.6-9,8.7c-4.9,0.1-7.8-2.3-7.9-6.7
		C5610.9,1700,5614.7,1696.1,5619.5,1695.8z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M4667.1,827.5c-3.5-0.2-7-4.4-7-8.4c0.1-4.7,4-8.5,8.7-8.5h0.3c4.4,0.2,6.8,3.1,6.7,8
		C4675.8,823.5,4671.9,827.4,4667.1,827.5z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M5410.8,1445.2c-4.4-0.1-8.1-3.5-8.4-7.9c-0.1-4.5,3.3-8.3,7.7-8.8c4-0.5,7.7,3.2,7.9,8.2
		c0.5,4.2-2.5,8-6.7,8.5c0,0,0,0,0,0C5411.2,1445.2,5411,1445.2,5410.8,1445.2z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M3785.9,1491.9c4.4,0,9.9,4.2,10.1,7.8s-4.4,6.8-9.4,6.7c-4.4,0.1-8.2-3.2-8.8-7.6
		C3777.6,1495.5,3781.8,1492,3785.9,1491.9z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M3659.2,1465.5c-0.5,4.4-4.1,7.8-8.6,7.8c-4.4-0.3-7.6-4.2-7.3-8.5c0.3-4.4,4.2-7.6,8.5-7.3
		C3656.1,1457.9,3659.3,1461.4,3659.2,1465.5z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M2449,1271c4-0.2,7.6,3.7,7.7,8.3c0.1,4.4-3.3,8.1-7.7,8.5c-3.6,0.2-7-4.2-7-8.9
		C2442,1274.9,2445,1271.5,2449,1271z"
                />
                
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M3596,953.5c-0.3,3.7-3.1,6.7-6.8,7.1c-4.7-0.1-8.5-3.9-8.7-8.7c0.2-4.1,3.6-7.9,7.2-7.8
		C3591.8,944.2,3596.1,949.1,3596,953.5z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M1449.5,1634c4.7,0.3,8.5,4.2,8.7,8.9c0,3.6-4.1,6.9-8.2,6.8c-4.4-0.1-8-3.7-8.1-8.1
		C1442.2,1637.5,1445.4,1634.2,1449.5,1634z"
                />
                <path
                    className="st4"
                    style={{ fill: "#AFB195" }}
                    d="M908.9,990.6c0.1,4.5-0.8,9-2.6,13.1c-2.2,4.2-1.4,9.4,2,12.7c4,5.2,8.4,10.1,13.3,14.6c1.6,1.2,3,2.6,4.2,4.2
		c2.1,3.3,4.9,7,2.6,10.9c-2.5,4.3-6.7,1.7-9.9,0.8c-7.4-1.7-15-2.5-22.6-2.3c-15.4-0.3-26.9,5.4-32.1,20.8c-0.6,2.3-2,4.2-4,5.5
		c-4.4,2.9-7.5,2.5-9.4-2.5c-2.1-5.6-3.7-11.3-4.8-17.1c-2.4-12.9-8.3-19.2-21.4-21.8c-5.2-1-10.4-1.7-15.5-3.1
		c-6.3-1.6-7.2-5.2-2.9-9.9c3.9-4.1,8.6-7.3,13.9-9.4c6.5-2.6,12-6.7,18-10.2s7.4-7.9,7.2-14s-0.5-12.5,1.5-18.7
		c1.2-3.8,3-4.5,6.5-2.4c4.9,2.9,9.9,5.5,12.1,11.2c2.3,6.1,8.6,7.9,12.9,11.9c1.3,1.2,3.1,0.7,4.8,0.4c5.5-1.2,11-2.2,16.4-3.5
		C908.2,979.6,909.4,980.6,908.9,990.6z M813.9,1020.4c7.9,2.2,14.2,3.6,20.3,5.7c13.1,4.5,17.5,11.1,18.2,25.1
		c0.2,4.1-2.8,10.7,3,11.9c5,1,6.5-5.5,8.8-9.3c0.2-0.3,0.3-0.6,0.5-0.9c5.8-12.1,15.8-17,28.7-17.3c6.1-0.2,11.7,1.8,17.5,2.9
		c2.1,0.4,4.7,2.5,6.1-0.4c1.4-2.8-1.6-4-3.1-5.5c-3.2-3.3-6.5-6.6-9.9-9.8c-5.2-5-7.4-10.5-4.8-17.9c1.5-4,2.4-8.1,4-12.2
		c1.3-3.4-0.5-5.3-4-4.3c-4.7,1.5-9.2,3.6-13.9,5.2c-6.9,2.4-9,1.8-13.4-4.1c-4.2-5.6-9.6-9.9-14.3-15.1c-1.1-1.2-2.4-2.5-4.2-1.5
		c-1.4,0.8-1.3,2.2-1.3,3.5v5c0.7,11.8-3.7,19.8-14.9,25.2C829.8,1010.1,821.5,1013,813.9,1020.4L813.9,1020.4z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M2543.9,1729.3c-4.4,0.2-8.3-2.9-9-7.3c0-4.1,2.9-7.6,6.9-8.3c3.5-0.4,9.4,4.6,9.8,8.3
		S2547.9,1729.1,2543.9,1729.3z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M3264.4,1711c0.1,4.4-3.4,8-7.7,8.1c0,0,0,0,0,0h-0.2c-4.5,0-7.2-2.5-7.3-7.3s2.1-7.8,6.5-8.1
		C3260,1703.4,3263.9,1706.6,3264.4,1711z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M3233,1285.7c0,4.2-3.3,8.2-6.8,8.2s-8.6-5.6-8.4-9.4c0.2-3.5,4.4-7,8.3-6.9S3232.9,1281.5,3233,1285.7z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M4830.6,856.2c-0.1,5-2.8,7.7-7.2,7.4c-4.4-0.5-7.8-4.2-7.8-8.7c0.7-4,4.3-6.9,8.4-6.8
		C4828.3,848.4,4830.7,851.4,4830.6,856.2z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M5209.5,1401.1c-0.2,4.4-2.5,6.7-6.7,6.6c-4.4-0.1-8-3.4-8.4-7.8c0.1-4.4,3.7-7.9,8.1-7.8h0.1
		C5206.8,1392.3,5209.7,1396,5209.5,1401.1z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M2724.6,203.4c0,3.7-1.2,6.5-5.3,7.1s-9.9-5.1-9.9-9.3c0.5-3.7,3.5-6.6,7.3-6.9
		C2720.2,194.4,2724.4,199.3,2724.6,203.4z"
                />
                <path
                    className="st8"
                    style={{ fill: "#C0A4AC" }}
                    d="M3194.9,287.3c4.4,0.1,8.1,3.6,8.4,8.1c0.1,3.6-2.7,6.5-6.2,6.6c-0.2,0-0.5,0-0.7,0c-4.5,0-8.2-3.5-8.4-7.9
		C3188,290.3,3191.1,287.3,3194.9,287.3z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M2315.3,1045.2c0.4,3.6-2.2,6.9-5.8,7.3h-0.3c-4.4-0.1-8-3.6-8.2-8.1c0.1-4.5,3.5-6,7.1-6.8
		S2315.1,1040.8,2315.3,1045.2z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M4260.8,1032.5c-0.4,3.8-3.7,7.4-6.8,7.3c-3.5-0.1-6.8-4.4-6.5-8.5c0.4-3.7,3.7-6.3,7.4-5.9
		c3.5,0.4,6.1,3.5,5.9,7C4260.8,1032.4,4260.8,1032.5,4260.8,1032.5z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M2932.6,488.1c-4-0.2-7.2-3.6-7-7.6c0.2-4,3.6-7.2,7.6-7c0.1,0,0.3,0,0.4,0c3.6,0.2,5.7,3.1,5.6,7.7
		c0.1,3.7-2.7,6.7-6.4,6.9H2932.6z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M4211.2,524.9c4.1,0.1,7.4,3.3,7.7,7.3c-0.2,3.7-3.2,6.7-6.9,7c-3.9-0.2-5.6-3-6.2-6.4
		c-0.8-3.5,1.3-6.9,4.8-7.7c0,0,0,0,0,0C4210.7,524.9,4210.9,524.9,4211.2,524.9z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M2122.7,1720.5c-0.1,4.5-3.7,8.4-7.2,8.3s-5.9-3.1-5.8-7.6s2.9-7.2,6.6-6.6
		C2119.7,1715.4,2122.6,1716.8,2122.7,1720.5z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M150.1,1282.3c-3.4,0.5-6.5-1.8-7-5.2c0,0,0,0,0-0.1c-0.1-0.4-0.1-0.8-0.1-1.2c-0.5-3.2,1.7-6.3,4.9-6.8
		c0.5-0.1,1-0.1,1.5-0.1c3.6-0.3,6.8,2.4,7.1,6c0,0.2,0,0.3,0,0.5c0.5,3.2-1.8,6.3-5,6.8C151.1,1282.3,150.6,1282.4,150.1,1282.3z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M6433.9,1639.3c-2.9,0.3-6.1-3.3-6.6-7.2c-0.4-3.5,2.8-7.5,6.2-7.2s6.2,1.8,6.6,5.8S6437.2,1639,6433.9,1639.3
		z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M5743.1,186c3.7,0.6,6.3,3.9,6.2,7.6c-0.3,3.6-4,7.1-7,6.9c-3.6-0.8-6.2-4-6.2-7.7
		C5736.4,189.1,5739.4,186.1,5743.1,186z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M1372.8,1468.1c-3.7-0.2-6.7-3.2-6.8-7c0-3.8,3.3-7.4,6.6-7c3,0.3,6.5,4.5,6.4,7.7
		C1378.8,1465.1,1376.2,1467.8,1372.8,1468.1z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M3315.6,575c0.2,3.6-2.6,6.8-6.2,7h-0.4c-3.5,0-6.4-2.8-6.5-6.4c0-0.3,0-0.5,0-0.8c-0.3-3.3,2.2-6.1,5.4-6.4
		h0.5c3.5-0.4,6.6,2.1,7,5.6C3315.6,574.3,3315.6,574.7,3315.6,575z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M2163,1370.8c-3.1-0.2-7.1-5.3-6.7-8.4c0.6-3.7,4-6.3,7.7-6c3.9,0.6,5.4,3.2,5.2,6.9S2166,1371,2163,1370.8z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M2916,649c0.3-3.6,2.8-5.2,6.5-5.3s7.7,2.8,7.6,6s-4.3,6.8-7.3,6.7C2919,656.1,2916,652.9,2916,649z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M5858.1,1636.6c-0.4,3.7-3.3,6.6-7,7.1c-4,0-5.8-2.6-6-6.4s1.3-6.4,5.4-6.9
		C5854.2,1630.3,5857.5,1633,5858.1,1636.6z"
                />
                <path
                    className="st8"
                    style={{ fill: "#C0A4AC" }}
                    d="M2835.8,754.8c-0.2,3.7-2.5,5.5-6.3,5.5s-6.5-0.9-7-5.1c0-3.7,2.6-6.8,6.3-7.4
		C2832.6,748,2835.6,751,2835.8,754.8z"
                />
                <path
                    className="st8"
                    style={{ fill: "#C0A4AC" }}
                    d="M3614.4,207.6c3.6,0.1,7.5,4,6.8,6.9c-0.5,3.4-3.4,6-6.9,6c-4,0-5.6-2.8-5.8-6.5c-0.4-3.1,1.8-5.9,4.9-6.3
		C3613.7,207.7,3614.1,207.6,3614.4,207.6z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M541.8,249c3.1,0,5.7,2.5,5.7,5.6c0,0,0,0,0,0c0,0.2,0,0.3,0,0.5c0,3.6-1.4,6.3-5.4,6.8
		c-3.7,0.2-6.9-2.4-7.5-6.1C534.5,252.7,538.1,249.1,541.8,249z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M4696.3,1128.2c4.2-0.3,6,2.2,6.4,5.8c0.6,3.2-1.5,6.4-4.8,7l-0.5,0.1c-3.1,0.7-7-3-7.1-6.6
		c-0.3-3.1,1.9-5.9,5-6.2C4695.7,1128.2,4696,1128.2,4696.3,1128.2z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M5321,340.1c3.1,0,5.6,2.5,5.7,5.6c0,0.1,0,0.2,0,0.3c0.2,3.3-3.4,6.7-7,6.8c-3.7-0.3-6.5-3.6-6.2-7.3
		C5313.7,342.1,5316.4,340,5321,340.1z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M5973.9,328.5c3.3,0.1,5.9,2.8,5.9,6.1c0,0.1,0,0.2,0,0.3c0.1,4.1-2.7,5.7-6.2,6.2c-3.4,0.2-6.4-2.3-6.7-5.7
		C5967.2,331.7,5970.2,328.8,5973.9,328.5z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M2942.4,779.9c-4.2-0.9-4.9-4-4.7-7.5s2-6.3,6-6.4c3,0,5.8,4.5,5.4,8.1
		C2948.7,778.3,2945.7,779.3,2942.4,779.9z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M263.6,131.2c-2.9-0.2-5.2-2.8-4.9-5.7c0-0.1,0-0.2,0-0.2c0-3.7,3.6-7.4,6.8-6.8c3,0.5,5.3,2.9,5.5,6
		C271.3,127.8,267.3,131.4,263.6,131.2z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M3097.3,692.5c-0.3,2.9-4.6,6.8-7.7,6.5c-3,0-5.4-2.5-5.3-5.5c0,0,0,0,0,0c0-0.2,0-0.3,0-0.5
		c0.3-3.7,3.1-6.7,6.8-7.2C3094.5,686.4,3097.1,689.1,3097.3,692.5z"
                />
                <path
                    className="st8"
                    style={{ fill: "#C0A4AC" }}
                    d="M2304.2,694.8c-4.3-0.1-7.3-1.2-7.2-5.5s2.8-5.7,6.4-5.8s6.3,1.7,6.5,5.6
		C2310,693.4,2306.9,694.5,2304.2,694.8z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M75.6,1566.8c3.4,0.4,6,3.4,5.7,6.8c0.1,3.3-2.5,6-5.8,6.1h-0.4c-3.5-0.4-6-3.6-5.6-7.1c0,0,0,0,0,0
		c0-0.2,0.1-0.4,0.1-0.7C69.9,1569,72.6,1566.6,75.6,1566.8L75.6,1566.8z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M406,1571.4c3.7,0.6,6.5,3.8,6.6,7.5c-0.2,3.1-2.9,5.5-6,5.4c-0.2,0-0.5,0-0.7-0.1c-4-0.8-5.1-3.6-4.9-7.2
		C401.2,1573.7,402.7,1571.5,406,1571.4z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M1471.5,1745c-0.2,3.1-2.7,5.5-5.8,5.6c-2.8,0.2-6.4-3.6-6.3-6.4s3.6-6.5,6.5-6.2
		C1469.9,1738.4,1470.9,1741.6,1471.5,1745z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M193.9,1693.6c-0.2,3.5-1.8,5.6-5.4,5.8s-6.7-2.1-6.6-5.6c0.3-3.4,3.2-6,6.6-6c3.1,0.1,5.5,2.6,5.4,5.6
		c0,0,0,0,0,0V1693.6z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M3604.2,1048.8c3.2,0.1,5.7,2.7,5.6,5.8c0,0.4-0.1,0.7-0.1,1.1c-0.6,3.1-2.2,5.7-5.7,5.5
		c-3.4-0.3-6-3.4-5.6-6.8C3598.6,1051.4,3601.1,1049,3604.2,1048.8L3604.2,1048.8z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M2182.1,1122c-0.6,3-1.4,6-4.8,6.6c-3.4,0.3-6.4-1.9-7.3-5.2c-0.1-3.4,2.5-6.3,5.9-6.5
		C2178.9,1116.9,2181.6,1119,2182.1,1122z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M1367,1307.3c-2.9-0.3-5.7-4.1-5.2-7.4c0.1-2.8,2.4-5,5.2-4.9c0,0,0,0,0,0c3.2,0.2,5.8,2.8,5.9,6
		c0,0.2,0,0.4-0.1,0.6C1372.6,1304.6,1369.5,1307.5,1367,1307.3z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M3032.2,445c0,3.8-3.3,7.5-6,6.9c-3.1-0.5-5.2-3.4-4.7-6.5c0.3-3,1.5-6,5.3-5.8c2.8-0.1,5.2,2,5.3,4.8
		c0,0,0,0,0,0C3032.2,444.6,3032.2,444.8,3032.2,445z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M1742.1,217c0.2,3-2.1,5.5-5.1,5.6h-0.2c-3.8,0.3-5-2.9-5.5-5.8c-0.6-3,1.4-6,4.5-6.5h0.2
		C1738.6,209.5,1742,213.2,1742.1,217z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M6922.7,1289.8c3.4,0.5,5.6,2,5.7,5.7s-1.1,6-4.5,6.6c-2.7,0.5-6.6-3.3-6.4-6.2
		C6917.4,1292.8,6919.7,1290.2,6922.7,1289.8z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M1831.7,502.7c3.3,0.3,5.6,1.7,5.9,5.2c0.3,3-3.1,6-6.6,5.8c-2.6,0-4.7-2.1-4.7-4.7c0,0,0,0,0,0
		c0-0.2,0-0.3,0-0.5c-0.4-2.8,1.6-5.4,4.5-5.8c0,0,0,0,0,0C1831,502.7,1831.3,502.7,1831.7,502.7z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M2857.8,1508.9c-2.6-0.2-3.9-2.3-3.7-5.3s1.6-6,5.3-5.7c2.9,0,5.2,2.3,5.2,5.2v0c0,0.3,0,0.5-0.1,0.8
		C2864.2,1507.1,2862.2,1509.2,2857.8,1508.9z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M4515.6,798.5c3.1,0.5,4.9,2.3,4.7,5.4s-1.5,6-5.2,6.1c-3,0.2-5.7-3.7-5.1-7.1
		C4510.4,800.1,4512.9,798.2,4515.6,798.5z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M5902.1,99.6c0.2,3-2.1,5.5-5,5.7h-0.2c-3.8,0.3-5-2.7-5-5.7c0-2.7,1.2-5.2,4.4-5.3c3-0.3,5.6,1.9,5.9,4.9
		C5902,99.3,5902,99.5,5902.1,99.6z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M1085.4,34.7c-0.1,2.8-2.5,5.1-5.3,5c0,0,0,0,0,0c-0.3,0-0.5,0-0.8-0.1c-3.6-0.4-3.6-3.3-3.6-6
		c0-3,1.2-5.3,4.6-5c3,0.1,5.3,2.7,5.2,5.7c0,0,0,0,0,0C1085.4,34.5,1085.4,34.6,1085.4,34.7z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M2274.2,1639.2c-3.3-1.4-5.2-3.5-4.8-6.8c0.2-2.5,2.4-4.3,4.9-4.1c0,0,0,0,0,0c0.2,0,0.4,0.1,0.7,0.1
		c2.9,0.4,5,2.1,4.7,5.2C2279.3,1636.4,2277.1,1638.7,2274.2,1639.2z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M4413.1,949.1c-2.9-0.3-5-2.8-4.9-5.6c0-3,1.4-5.4,4.4-5.4c3-0.3,5.6,1.9,5.8,4.9c0,0.2,0,0.3,0,0.5
		C4418.5,946.9,4415.6,948.1,4413.1,949.1z"
                />
                <path
                    className="st10"
                    style={{ fill: "#F5F3AB" }}
                    d="M6740.9,847.9c7.6,0.9,14.2,1.1,20.5,2.6c10.8,2.6,21.7,1.2,32.5,1.5c6.9,0.2,11.7-4.3,16.5-8.2
		c5.5-4.5,11.9-6.9,18-10.2c3.3-1.8,5.1-0.5,5.1,3.1c0,6,0.9,11.9-2.2,17.5c-3.3,6-1.3,10.9,2.7,15.3c3.6,4.4,8.1,7.9,13.2,10.3
		c3.2,1.4,6.8,2.6,8.7,6.2c-0.8,3-3.2,2-4.9,1.9c-8.7-0.8-17,1.5-25.6,2.1c-7.4,0.5-11.7,5.4-13.9,13.4c-2,7-4.1,13.9-6.4,20.8
		c-0.7,2-1.2,4.9-4.1,5c-2.4,0-4.6-1.6-5.2-4c-1.6-6-6.6-9.8-9.5-14.8c-3.7-6.3-8.8-7.8-15.1-6c-8.1,2.3-16.4,1.1-24.6,1.4
		c-1.3,0-2.7,0-3.5-1.2c-1.1-1.9,0.3-3.1,1.4-4.3l11.9-11.9c7-7,7.5-14.1,1-21.7c-3-3.5-6.5-6.5-9.8-9.9
		C6745.3,854.4,6742.4,852.6,6740.9,847.9z"
                />
                <path
                    className="st4"
                    style={{ fill: "#AFB195" }}
                    d="M5934.6,1195.4c0.1,4.5-0.8,9-2.6,13.1c-2.2,4.2-1.4,9.4,2,12.7c4,5.2,8.4,10.1,13.3,14.7
		c1.6,1.2,3,2.6,4.2,4.2c2.1,3.3,4.9,7,2.6,10.9c-2.5,4.3-6.7,1.7-9.9,0.8c-7.4-1.7-15-2.5-22.6-2.3c-15.4-0.3-26.9,5.4-32.1,20.8
		c-0.6,2.3-2,4.2-4,5.5c-4.4,2.9-7.5,2.5-9.4-2.5c-2.1-5.6-3.7-11.3-4.8-17.1c-2.4-12.9-8.3-19.2-21.4-21.8
		c-5.2-1-10.4-1.7-15.5-3.1c-6.3-1.6-7.2-5.2-2.9-9.9c3.9-4.1,8.6-7.3,13.9-9.4c6.5-2.6,12-6.7,18-10.2c6-3.5,7.4-7.9,7.2-14
		c-0.3-6.1-0.5-12.5,1.5-18.7c1.2-3.8,3-4.5,6.5-2.4c4.9,2.9,9.9,5.5,12.1,11.2c2.3,6.1,8.6,7.9,12.9,11.9c1.3,1.2,3.1,0.7,4.8,0.4
		c5.5-1.2,11-2.2,16.4-3.5C5933.8,1184.4,5935,1185.4,5934.6,1195.4z M5839.5,1225.1c7.9,2.2,14.2,3.6,20.3,5.7
		c13.1,4.5,17.5,11.1,18.2,25.1c0.2,4.1-2.8,10.7,3,11.9c5,1,6.5-5.5,8.8-9.3c0.2-0.3,0.3-0.6,0.5-0.9c5.8-12.1,15.8-17,28.7-17.3
		c6.1-0.2,11.7,1.8,17.5,2.9c2.1,0.4,4.7,2.5,6.1-0.4c1.4-2.8-1.6-4-3.1-5.5c-3.2-3.3-6.5-6.6-9.9-9.8c-5.2-5-7.4-10.5-4.8-17.9
		c1.5-4,2.4-8.1,4-12.2c1.3-3.4-0.5-5.3-4-4.3c-4.7,1.4-9.2,3.6-13.9,5.2c-6.9,2.4-9,1.8-13.4-4.1c-4.2-5.6-9.6-9.9-14.3-15.1
		c-1.1-1.2-2.4-2.5-4.2-1.4c-1.4,0.8-1.3,2.2-1.3,3.4v5c0.7,11.8-3.7,19.8-14.9,25.2C5855.4,1214.9,5847.1,1217.7,5839.5,1225.1
		L5839.5,1225.1z"
                />
            </g>
            <g className="flicker-3">
                <path
                    className="st10"
                    style={{ fill: "#F5F3AB" }}
                    d="M6484.6,686.2c8.3-18.5,16.2-19.5-9.5-51.9c7.9-1,15,2,22.3,2.3c1.6,0,3.2,1,4.8,1
		c11.9,0.1,15.2,2.6,18.7-12.6c1.2-5.3,1.7-10.9,4.9-16.7c4.2,4.3,4.9,9.2,5.6,13.8c1.4,9.5,5.3,16.2,14.6,20.5
		c5.8,2.7,11.1,6.8,16.9,10.1c2.3,1.3,3.1,3.6-0.4,5c-1.5,0.6-3.1,1.1-4.7,1.5c-11.9,2.9-15.9,7-17.9,18.8c-0.4,2.6-0.4,5.2-0.5,7.6
		c-3,1.6-4.7-0.3-6.3-1.8c-7.9-7.4-16.9-7.9-26.5-3.8c-5.5,2.3-11.1,4.2-16.6,6.3C6488.2,687.1,6486.4,687.8,6484.6,686.2z"
                />
                <path
                    className="st5"
                    style={{ fill: "#9BB1A2" }}
                    d="M5157.7,173.7c-2.1,0-4.2-0.2-6.2-0.6c-2.6-0.6-4.8-2.4-6-4.9c-3.3-6.8,1.8-21.7,8.6-25.1
		c7.9-4,21.5-0.3,25.6,7c5.1,9.2,0.6,19.5-9.6,22.4C5166,173.4,5161.9,173.8,5157.7,173.7z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M4975.1,334.8c0.6,11.2-4.9,15.7-17.6,16.1c-7.3,0.2-13.5-7.8-13.3-16.7c0.2-9.9,6.3-17.3,15.3-16.8
		C4969.4,317.8,4975.1,323.7,4975.1,334.8z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M5316.5,236.1c0.2,7.3-5.6,13.3-12.8,13.5c-6.8,0-13.6-6.6-13.7-13c0-6,8.1-13.8,14.6-13.9
		C5310.1,222.7,5316.5,229.8,5316.5,236.1z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M5105,336.1c4.6-0.2,9.8,5,10.2,10.1c0.4,4.9-3.1,9.9-7,10.3c-4.9,0.4-11.2-5-11.5-9.9
		C5096.3,341.9,5100.9,336.3,5105,336.1z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M4985,170.7c0.1,6.2-3,10.4-7.9,10.5c-5.1-0.2-9.3-4.3-9.5-9.5c-0.3-6.3,3.4-10.9,8.8-10.9
		C4981.6,160.9,4984.9,164.6,4985,170.7z"
                />
                <path
                    className="st8"
                    style={{ fill: "#C0A4AC" }}
                    d="M4797.3,198.2c0.3,4.3-4.7,9.4-9.5,9.6c-4.4,0.2-8.1-3.3-8.2-7.7c0-0.1,0-0.2,0-0.3c-0.2-4,3-7,7.7-7.4
		C4792.5,191.9,4797,194.5,4797.3,198.2z"
                />
                <path
                    className="st12"
                    style={{ fill: "#EBF1B7" }}
                    d="M4325.5,1293.1c2.5,0.2,4.7,1.5,6.1,3.6c3.7,5.1,8.6,6.6,14.7,6c2.4-0.3,5.7-1.2,7.3,1.5
		c2,3.3-1.5,5.2-3.1,7.5c-1.5,1.8-2.6,3.8-3.3,6c-0.7,5.1-3,7.5-8.3,6.9c-4.8-0.5-5,5.4-8.9,7.8c-2.3-5.7-4.1-11.1-9.3-14
		c-1.3-0.7-1.9-2.3-1.2-3.7c0,0,0,0,0,0c0.1-0.2,0.2-0.4,0.4-0.6C4323.9,1307.8,4324.1,1300.6,4325.5,1293.1z"
                />
                <path
                    className="st12"
                    style={{ fill: "#EBF1B7" }}
                    d="M5770.6,494.2c9.1-6.4,13.9-12.6,8.5-22.8c9.1,4.7,17,5.2,24.6-0.2c6.1,6.3-0.6,17,9,20.8
		c-0.8,1.2-1.2,2.1-1.5,2.1c-9.2,0-14.9,3.8-16.2,13.5c-0.5,3.8-2.9,2.6-4.8,0.6c-0.4-0.5-0.8-1.1-1-1.7
		C5786.3,499.2,5781.6,493.9,5770.6,494.2z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M1186.8,661c-0.2,4.4-2.6,6.3-6.9,6.3c-4.7,0-7-2.5-6.8-7.4c0.2-4.5,2.6-6.3,6.8-6.3
		C1184.6,653.8,1187,656.3,1186.8,661z"
                />
                <path
                    className="st12"
                    style={{ fill: "#EBF1B7" }}
                    d="M2423.4,920.2c0,14.5-9.3,22-22.7,19.1c-5.5-1.2-10.1-9.3-9-16.6c0.5-3.9,1.3-7.7,2.4-11.5
		c2.2-7.7,11.9-11.2,19-7.2C2419.7,907.8,2425,912.3,2423.4,920.2z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M6180.6,982.2c-6,3.2-10.4,6.7-16.9,5.7c-3.2-0.5-4.5-1.5-4.7-4.8c-0.6-7.8,0.9-11.2,6.1-12.4
		c2-0.5,4.1-0.7,5.1,1.6s1.9,5,4.2,6.5C6176.1,979.9,6178,980.8,6180.6,982.2z"
                />
                <path
                    className="st12"
                    style={{ fill: "#EBF1B7" }}
                    d="M3137.6,583.3c0,13.6-8.9,20.2-22.1,16.6c-1.3-0.3-2.4-1-3.3-2c-3.4-4.1-3.1-18.2,0.6-22.5
		c6-7,13.3-7.9,20.9-3.3C3138.5,574.9,3137.6,579.1,3137.6,583.3z"
                />
                <path
                    className="st12"
                    style={{ fill: "#EBF1B7" }}
                    d="M1013.9,772.5c0-0.6,0.1-1.2,0.3-1.8c7.6-1.8,15.4-2.5,23.1-2c4.3,0.2,6.4,2.3,6.6,6.7
		c0.4,6.5-0.2,13-1.7,19.3l-0.2,0.2c-6-0.1-11.8,0-17.6-0.3c-8.1-0.3-13.3-6.5-11.8-14.4C1013.1,777.6,1014.3,775.2,1013.9,772.5z"
                />
                <path
                    className="st14"
                    style={{ fill: "#C5D3B3" }}
                    d="M1013.9,772.5c0.4,2.7-0.8,5.1-1.3,7.6c-1.5,7.8,3.7,14,11.8,14.4c6,0.2,11.8,0.2,17.7,0.3
		c-7.3,7.7-16.6,4-24.4,2.5c-5.7-1-9.2-7.9-9.4-14.6C1008.2,778.4,1008.6,774,1013.9,772.5z"
                />
                <path
                    className="st12"
                    style={{ fill: "#EBF1B7" }}
                    d="M2200.8,733.4c-0.7,3.6,2,8.8-3.4,12.5c-7.3,5.1-20.1,3.3-25.5-4.2s-3-20.8,5.2-25c5.7-2.7,12.4-2.6,18,0.3
		C2202,720.3,2201,726.5,2200.8,733.4z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M5598.4,1082.9c0.8,0.2,1.5,0.5,2.2,0.8c2.9,2.1,4.3,13.6,2,16.2c-0.4,0.5-0.9,0.9-1.5,1.2
		c-1,0.4-11.5-6.1-11.9-7.4c-1-4,0-6.9,4.4-8.1C5595.4,1084.9,5596.9,1084,5598.4,1082.9z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M2375.2,589.9c4.4,0.5,10.1-1.7,11.9,4.4c1.9,5.8,0.6,12.1-3.6,16.5c-4.3,4.6-9.7,3.5-14.7,2.3
		c-4.6-1.1-8.3-3.8-8.9-9.3c-0.7-4.6,1.2-9.2,5.1-11.9C2368.1,589.3,2371.8,590.4,2375.2,589.9z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M4550.5,50.2c4-0.6,6.4,1.4,7.8,5.3c0.8,2.2,1.6,4.3,2.4,6.4c3,7.5,2.4,9.9-4,15.3c-4.8,3.6-11.4,3.8-16.5,0.4
		c-4.4-2.9-5.8-7.7-4.1-13.9C4538.9,53.4,4542.3,50.1,4550.5,50.2z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M4001.4,1197.4c-12-0.3-17.2-8.3-12.4-19.4c1.1-2.8,2.7-5.3,4.6-7.5c4.2-4.1,10.9-4.1,15,0.1c0,0,0,0,0,0
		c0.7,0.7,1.3,1.5,1.8,2.4c1.1,2.4,2.1,4.9,2.9,7.4C4017,1189.9,4011.7,1197.6,4001.4,1197.4z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M4306.6,1114.6c4-0.3,15.6,9,16.1,12.8c1,6.8-6,15.4-13,16.2c-4.9,0.5-8.1-2.7-10.7-6
		c-5.2-6.4-1.8-19,5.8-22.5C4305.4,1114.9,4306,1114.8,4306.6,1114.6z"
                />
                <path
                    className="st14"
                    style={{ fill: "#C5D3B3" }}
                    d="M5271.5,1306.4c-6,1.5-10.1-2.1-13.7-6.7s-2.9-8.7,1.6-11.7c5.4-3.7,17.4-3.4,22.4,0.4
		c4.1,3.1,3.9,7.5,3,11.7c-0.7,3.7-4,6.4-7.8,6.2C5275.3,1306.5,5273.7,1306.4,5271.5,1306.4z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M2701.3,609.9c-6.1,0-10.2-3.2-10.1-8.1c0.1-4.2,5.4-9.5,9.7-9.6c4.6-0.1,10.3,5.6,10.1,10.2
		S2707,609.9,2701.3,609.9z"
                />
                <path
                    className="st14"
                    style={{ fill: "#C5D3B3" }}
                    d="M3295.8,1191.4c-5.8,1.3-10.7-1.3-14.6-6.4s-3.3-9.2,2.6-11.7c6.3-2.7,12.9-2.1,19.3-0.7
		c4.6,1,6.4,7,5.3,13.4c-0.8,4.7-4,5.6-7.9,5.5C3299.2,1191.3,3297.9,1191.4,3295.8,1191.4z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M3147.8,435.3c0,8.2-1.6,10.1-8.5,9.9c-6.4-0.2-11.6-5.1-11.5-10.9c0-5.3,4.5-8.9,10.9-8.7
		C3144.2,425.6,3148.7,427.3,3147.8,435.3z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M813.4,1731.3c-5.1,0.1-9.4-3.9-9.7-9c0-5.8,6.5-11,13.1-10.6c3.6,0.3,6.6,4,6.7,8.3
		C823.6,1725.5,818.4,1731.4,813.4,1731.3z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M2336.1,1704.7c0,5-5.2,10.5-9.9,10.6s-10.1-6-10-11.6c0-4.7,6-10.4,10.4-9.9S2336.2,1699.8,2336.1,1704.7z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M4693.9,1720.6c0,2.6,0,5.3-2,7.3s-3.7,5.5-7.9,4.3c-5.8-1.7-9.6-5.1-9.9-9.5c-0.4-4,2.1-7.8,6-9.1
		c0.9-0.4,2-0.5,2.8-0.8C4691.1,1710.2,4694,1712.2,4693.9,1720.6z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M2210.9,593.7c-0.2,6.3-3.8,10-9.6,10c-5.2,0-8.3-4-8.1-10.2c0.2-6.6,3.3-9.5,9.9-9.4
		S2211.1,586.1,2210.9,593.7z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M1470.1,1320.9c-0.4,3.1-2,6.1-5.7,6.2c-3.4,0.4-6.6-2-7-5.4v0l0,0c-0.2-3.7,2.6-6.9,6.3-7.2c0,0,0,0,0,0l0,0
		c3.3-0.2,6.1,2.2,6.4,5.5C1470.1,1320.3,1470.1,1320.6,1470.1,1320.9z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M5938.2,170.1c0.1-4.9,5.4-10.5,9.9-10.6s11.5,7.4,11.4,11.9c-0.2,5.1-6,9.9-11.6,9.8S5938,176.6,5938.2,170.1
		z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M4465,1024.7c-6-0.2-11.4-5.2-11.2-10.3c0.1-4.2,7.4-11.5,11.1-11.2s10.5,8,10.4,11.9c0,5.3-4.2,9.6-9.5,9.6
		c0,0,0,0,0,0L4465,1024.7z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M618.4,1395.7c0,9.2-10.3,20.3-19.2,20.7c-8.5,0.3-17.9-10.2-18-20.2c-0.1-9.2,8.6-19.8,16.1-19.6
		C605.5,1376.8,618.4,1388.5,618.4,1395.7z M596.4,1381.8c-0.6,0.2-1.2,0.4-1.8,0.6c-7.6,3.5-11,16-5.8,22.5c2.6,3.2,5.8,6.4,10.7,6
		c7-0.8,13.9-9.5,13-16.2C612,1390.9,600.3,1381.6,596.4,1381.8L596.4,1381.8z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M768.5,1283.2c-0.5,10.1-5.3,15-14.5,14.6s-15.2-7.9-14.6-17.6c0.5-6.8,8.7-15.1,14.8-14.8
		C762.4,1265.8,769,1274.1,768.5,1283.2z M754.8,1292c5.3,0.4,9.9-3.5,10.3-8.8c0,0,0,0,0,0c0-0.2,0-0.5,0-0.7
		c0.1-4-6.6-11.7-10.4-11.9s-11,7-11.1,11.2C743.4,1286.8,748.9,1291.9,754.8,1292L754.8,1292z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M812.4,1442.8c-0.5,4.7-4.6,8.1-9.3,7.6h-0.4c-4.9-0.7-9.9-7.4-9.2-12.4c0.8-4.4,4.9-7.4,9.3-7
		C808.5,1432,812.6,1437,812.4,1442.8z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M487.5,1192c-5.4-0.4-10.6-6.3-9.9-11.4c0.5-4,3.7-7.1,7-6.9c4.3,0.2,10.7,7.5,10.2,11.7
		C494.4,1189.1,491.3,1191.8,487.5,1192z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M550.6,1299.7c-0.4,3.8-3.7,7.4-6.8,7.3c-3.5-0.1-6.8-4.4-6.5-8.5c0.4-3.7,3.7-6.3,7.4-5.9
		c3.5,0.4,6.1,3.5,5.9,7C550.6,1299.6,550.6,1299.7,550.6,1299.7z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M702.9,1216.3c-2.9-0.3-5-2.8-4.9-5.6c0-3,1.4-5.4,4.4-5.4c3-0.3,5.6,1.9,5.8,4.9c0,0.2,0,0.3,0,0.5
		C708.4,1214.2,705.4,1215.3,702.9,1216.3z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M596.4,1381.9c4-0.3,15.6,9,16.1,12.8c1,6.8-6,15.4-13,16.2c-4.9,0.5-8.1-2.7-10.7-6c-5.2-6.4-1.8-19,5.8-22.5
		C595.2,1382.2,595.8,1382,596.4,1381.9z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M754.8,1291.9c-6-0.2-11.4-5.2-11.2-10.3c0.1-4.2,7.4-11.5,11.1-11.2c3.7,0.3,10.5,8,10.4,11.9
		c0,5.3-4.2,9.6-9.5,9.6c0,0,0,0,0,0L754.8,1291.9z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M872.8,332.4c0,9.2-10.3,20.3-19.2,20.7c-8.5,0.3-17.9-10.2-18-20.2c-0.1-9.2,8.6-19.8,16.1-19.6
		C859.9,313.5,872.8,325.2,872.8,332.4z M850.8,318.5c-0.6,0.2-1.2,0.4-1.8,0.6c-7.6,3.5-11,16-5.8,22.5c2.6,3.2,5.8,6.4,10.7,6
		c7-0.8,13.9-9.5,13-16.2C866.4,327.6,854.7,318.3,850.8,318.5L850.8,318.5z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M1022.9,220c-0.5,10.1-5.3,15-14.5,14.6s-15.2-7.9-14.6-17.6c0.5-6.8,8.7-15.1,14.8-14.8
		C1016.7,202.5,1023.4,210.8,1022.9,220z M1009.2,228.7c5.3,0.4,9.9-3.5,10.3-8.8c0,0,0,0,0,0c0-0.2,0-0.5,0-0.7
		c0.1-4-6.6-11.7-10.4-11.9s-11,7-11.1,11.2C997.8,223.5,1003.3,228.7,1009.2,228.7L1009.2,228.7z"
                />

                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M3831.1,1100.1c-6.6-0.1-10.2-2.8-10.4-7.7c-0.1-5.8,5.7-10.2,13.1-10c6.7,0.2,8.4,1.7,8.4,7.9
		C3842.3,1097.4,3839.2,1100.1,3831.1,1100.1z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M2477.2,693.4c-7.7,0-9.9-1.4-10-6.2c0-6.1,4.1-9.9,10.9-9.8c6.1,0,8.8,3,8.6,9.1
		C2486.5,691.9,2484.6,693.3,2477.2,693.4z"
                />
                
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M1677,1690.1c0.1,6.5-1.7,9.2-6.1,9.1c-4.4-0.1-7.9-3.7-7.8-8.1c0,0,0,0,0,0c0-0.1,0-0.2,0-0.2
		c-0.1-5.3,3.3-9.2,8-9.2C1675.4,1681.8,1677,1684.1,1677,1690.1z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M575.3,70.5c3.1,2.2,2,5.2,3.1,7.5s1.7,4.7-1.4,5.6c-3.2,0.9-6.7-0.3-8.6-3.1c-1.4-1.8-1.2-4.4,0.6-5.8
		l0.3-0.2C571.2,72.9,573.5,71.7,575.3,70.5z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M5419.5,1302c0.2,4.4-8.4,11.3-12.7,10.3c-2.6-0.3-4.5-2.6-4.2-5.2c0,0,0,0,0,0c0.1-0.8,0.4-1.6,0.9-2.3
		c1.8-2.4,3.8-4.6,6.1-6.5c1.6-1.5,3.1-4,5.8-2.6S5418.6,1299.6,5419.5,1302z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M2553,592c6.6-0.2,8.7,1.3,9.1,6.3c0,5.8-4.4,10.7-10.2,11.2c-3.6,0.1-7-4.2-7.2-9.1
		C2544.4,595,2547.3,592.2,2553,592z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M3511.7,73.7c-3.1,0-8.5-5.5-8.5-8.5s7.3-10.6,10.6-10.7s8.6,5.3,8.3,8.4C3521.8,66.8,3515,73.8,3511.7,73.7z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M2256.4,544.3c-5.5-0.1-7.5-1.9-7.6-6.8c-0.1-6.8,3.5-10.8,9.9-10.8c5.3,0,9.2,2.8,9.4,6.7
		C2268.3,539,2262.5,544.4,2256.4,544.3z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M2964.5,258.6c-1.3-0.1-2.5-0.3-3.8-0.6c-2-0.5-3.3-2.4-3.2-4.4c0-3.6,5-6.8,10.4-6.8c4.6,0,7.2,2.2,6.9,6.1
		c-0.3,4.7-3.5,5.7-7.5,5.7H2964.5z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M2474.9,568.1c-4.1-0.1-7.5-3.2-7.8-7.3c-0.2-4.2,4.2-8,9.3-8.2c4-0.1,6.2,1.7,6.3,5.8
		C2482.8,563.2,2478.9,568,2474.9,568.1z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M476.8,977.4c0-8.1,5-13.1,12.7-13c6.4,0.1,13.5,7.7,13.6,14.5c0.2,7.6-4.9,12-13.6,11.9
		C480.3,990.7,476.6,986.8,476.8,977.4z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M244.9,761.1c7.9,0.1,13,6,12.6,14.4c-0.4,9.9-6.4,17.2-14.3,17.2c-7.9-0.3-14.2-6.9-13.9-14.9
		c0-0.1,0-0.2,0-0.2C229.6,767.2,235.4,760.9,244.9,761.1z M252.2,776.6c0.2-7.6-1.3-9.5-7.7-9.6s-9.7,2.8-9.9,9.4
		c-0.2,6.2,3,10.2,8.1,10.2c5.7,0,9.4-3.8,9.6-10L252.2,776.6z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M300.4,705.5c9.2,0,12.8,3,12.9,10.8c0,10-6.2,16.8-15.5,16.5c-7.9-0.3-11.9-5.3-11.9-14.8
		S289.3,705.5,300.4,705.5z M297.8,727.3c6.1,0.1,11.9-5.3,11.7-10.9c-0.2-4-4-6.7-9.4-6.7c-6.4,0-10,4-9.9,10.8
		c0.1,4.9,2.1,6.6,7.6,6.7L297.8,727.3z"
                />
                <path
                    className="st8"
                    style={{ fill: "#C0A4AC" }}
                    d="M677.1,892.6c-0.1,4.5-2.6,7.2-6.4,6.9c-4-0.3-8.8-6-8.7-10.5c0-4,4-7.9,7.4-7.7
		C673.3,881.8,677.3,887.6,677.1,892.6z"
                />

                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M742.8,792.8c-6.1,0-10.2-3.2-10.1-8.1c0.1-4.2,5.4-9.5,9.7-9.6c4.6-0.1,10.3,5.6,10.1,10.2
		S748.4,792.8,742.8,792.8z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M252.3,776.7c-0.2,6.3-3.8,10-9.6,10c-5.2,0-8.3-4-8.1-10.2c0.2-6.6,3.3-9.5,9.9-9.4S252.5,769,252.3,776.7z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M518.6,876.3c-7.7,0-9.9-1.4-10-6.2c0-6.1,4.1-9.9,10.9-9.8c6.1,0,8.8,3,8.6,9.1
		C527.9,874.9,526,876.2,518.6,876.3z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M594.4,775c6.6-0.2,8.7,1.3,9.1,6.3c0,5.8-4.4,10.7-10.2,11.2c-3.6,0.1-7-4.2-7.2-9.1
		C585.8,778,588.7,775.1,594.4,775z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M297.8,727.2c-5.5-0.1-7.5-1.9-7.6-6.8c-0.1-6.8,3.5-10.8,9.9-10.8c5.3,0,9.2,2.8,9.4,6.7
		C309.7,721.9,304,727.3,297.8,727.2z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M516.3,751c-4.1-0.1-7.5-3.2-7.8-7.3c-0.2-4.2,4.2-8,9.3-8.2c4-0.1,6.2,1.7,6.3,5.8
		C524.3,746.1,520.3,750.9,516.3,751z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M3816.5,1001.8c-0.4,5.6-2.4,7.3-8.4,7.1c-5.6-0.2-7.8-2.6-7.3-8.1c0.2-5.2,4.3-9.4,9.5-9.6
		C3814.4,991.2,3816.9,995.5,3816.5,1001.8z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M3836.2,1348.4c4.3-0.1,7.8,3.3,7.9,7.6c0.1,4.3-3.3,7.8-7.6,7.9l0,0c-4.3,0.1-7.8-3.3-7.9-7.6
		c-0.1-4.2,3.2-7.7,7.4-7.9L3836.2,1348.4z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M2693.8,939.6c-0.4,3.8-4.7,6.7-9.5,6.3c-4.3-0.4-6.3-2.7-5.9-7.1c0.2-3.4,4.4-6.3,8.5-6
		S2694.2,936.5,2693.8,939.6z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M2668.6,1217.4c6.6-6.6,11.3-7.2,17-1.9c5,4.1,5.7,11.4,1.6,16.4c-0.3,0.4-0.6,0.7-1,1.1
		c-4.6,4.6-13.4,4.9-17.9,0.7C2663,1228.8,2663.1,1222.9,2668.6,1217.4z M2683.2,1219.1c-2-2.4-6.8-2.3-9.8,0.1
		c-3,2.4-4,7.6-1.8,10.2c2.9,3.3,5.9,3.6,9.2,0.8C2684.6,1227.2,2685.6,1222.1,2683.2,1219.1L2683.2,1219.1z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M2742.3,1256.1c3-2.8,8.9-2.3,12.4,1.2c3.9,4,4.4,9.8,0.9,13.1c-3.8,3.3-9.7,2.9-13-0.9l-0.1-0.1
		C2738.9,1265.8,2738.8,1259.9,2742.3,1256.1z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M2683.1,1219.1c2.5,3,1.4,8.1-2.3,11.2c-3.4,2.8-6.4,2.5-9.2-0.8c-2.2-2.6-1.3-7.6,1.8-10.2
		C2676.5,1216.5,2681.2,1216.7,2683.1,1219.1z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M4829,1766.8c-4,0.1-6.3-2.6-6.3-7c0-3.3,1.1-5.9,4.7-6.4c3-0.4,6.8,3.4,7.1,6.8c0.5,3.1-1.6,6-4.7,6.5
		C4829.6,1766.8,4829.3,1766.9,4829,1766.8z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M1862.9,1684.1c-0.3,4-4.9,9.3-8.1,9.1c-4.4-0.3-5.1-3.5-5-7.1c0.2-4.8,2.8-6.9,7.9-6.4
		C1860.6,1679.9,1862.8,1680.9,1862.9,1684.1z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M5328.7,1370.1c3.4,0.2,6.1,1.6,5.8,5.2c-0.2,3.4-3,6.2-6.4,6.5c-2.8,0.4-7.1-3.6-6.5-6.1
		C5322.4,1372.4,5325.3,1370.1,5328.7,1370.1z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M5066.6,685.3c5.8-0.6,11.2,3.4,12.2,9.2c2.8,11.1-5.6,21.1-16.5,19.8c-2-0.1-3.8-0.9-5.3-2.3
		c-3.5-3.2-4.9-13-2.7-18.6C5056.3,688.5,5061.2,685.2,5066.6,685.3z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M5183.6,567.2c-10.1-0.1-20.1-8.3-21.3-17.1c-0.6-4.4,1.7-8.8,5.8-10.6c8.1-4,17.3-5,26-2.6
		c6,1.4,7.9,7.2,8.4,13.2c0.6,6.6-1.2,11.9-7.4,14.7C5191.6,566.6,5187.6,567.5,5183.6,567.2z M5183.2,561.6h5.5
		c3.8,0.1,7.1-2.5,7.8-6.2c0.9-4.2,1.1-8.6-3-11.7c-5-3.8-17-4-22.4-0.4c-4.5,3.1-5.2,7.3-1.6,11.7S5177.3,563.2,5183.2,561.6z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M5335.5,557.3c0.2,6.8-6,14.5-12.5,15.7c-3.3,0.6-6.7-0.2-9.4-2.1c-4.3-2.9-5.3-8.5-2.8-14.1
		c3.4-7.5,10.2-12.7,15.4-11.9S5335.3,551,5335.5,557.3z M5331.2,557.1c-0.9-2.3-1.5-5.1-4.1-6.3s-4.2,1.1-5.8,2.6
		c-2.2,2-4.3,4.1-6.1,6.5c-1.6,2.1-1.1,5.1,1,6.6c0.7,0.5,1.5,0.8,2.3,0.9C5322.8,568.5,5331.4,561.6,5331.2,557.1L5331.2,557.1z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M5228.8,630.2c0.8-6.5,6.5-11.2,13-10.7c5.8,0.5,10.6,6,9.9,11.3c-0.8,6.1-7.4,12.4-12.5,11.8
		C5233.2,641.6,5228.8,636.3,5228.8,630.2z M5240.4,625.2c-3.4,0-6.3,2.4-7,5.6c-0.6,2.5,3.6,6.5,6.5,6.1c3.4-0.3,6.2-3,6.4-6.5
		C5246.5,626.8,5243.8,625.5,5240.4,625.2L5240.4,625.2z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M5180.8,698c0,4.5-3.5,8.2-7.9,8.4c-4.7-0.3-8.5-4.1-8.8-8.8c0.4-4.8,4.5-8.4,9.2-8.3
		C5177.7,689.8,5181,693.6,5180.8,698z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M5322.5,700.4c-4.4-0.1-8.1-3.5-8.4-7.9c-0.1-4.5,3.3-8.3,7.7-8.8c4-0.5,7.7,3.2,7.9,8.2
		c0.5,4.2-2.5,8-6.7,8.5c0,0,0,0,0,0C5322.9,700.4,5322.7,700.4,5322.5,700.4z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M5121.2,656.3c-0.2,4.4-2.5,6.7-6.7,6.6c-4.4-0.1-8-3.4-8.4-7.8c0.1-4.4,3.7-7.9,8.1-7.8h0.1
		C5118.5,647.5,5121.4,651.2,5121.2,656.3z"
                />
                <path
                    className="st14"
                    style={{ fill: "#C5D3B3" }}
                    d="M5183.2,561.6c-6,1.5-10.1-2.1-13.7-6.7s-2.9-8.7,1.6-11.7c5.4-3.7,17.4-3.4,22.4,0.4c4.1,3.1,3.9,7.5,3,11.7
		c-0.7,3.7-4,6.4-7.8,6.2C5187,561.7,5185.4,561.6,5183.2,561.6z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M5331.2,557.2c0.2,4.4-8.4,11.3-12.7,10.3c-2.6-0.3-4.5-2.6-4.2-5.2c0,0,0,0,0,0c0.1-0.8,0.4-1.6,0.9-2.3
		c1.8-2.4,3.8-4.6,6.1-6.5c1.6-1.5,3.1-4,5.8-2.6S5330.3,554.8,5331.2,557.2z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M5240.4,625.2c3.4,0.2,6.1,1.6,5.8,5.2c-0.2,3.4-3,6.2-6.4,6.5c-2.8,0.4-7.1-3.6-6.5-6.1
		C5234.1,627.6,5237,625.3,5240.4,625.2z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M538.4,439.2c3.1,0.3,7.1-0.8,7.2,4.3c0,4.2-2.8,5.6-6.5,5.6s-8.1-0.5-8.4-4.8
		C530.1,439.2,534.7,439.3,538.4,439.2z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M958.5,1766.9c-3.6-0.3-6.6-2.9-7.5-6.5c0.3-3.6,3.2-6.5,6.8-7c3,0,6.5,4,6.7,7.3c0.3,3.1-2,5.8-5.1,6.1
		c0,0,0,0,0,0C959,1766.9,958.7,1766.9,958.5,1766.9z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M5755.3,1637.4c-0.2,2.9-4,6.4-6.6,5.8c-3-0.7-5.4-2.4-5-6.1c0.2-2.9,2.6-5.2,5.6-5c0,0,0,0,0,0h0.4
		c2.8-0.3,5.3,1.8,5.6,4.6C5755.3,1636.9,5755.3,1637.2,5755.3,1637.4z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M6338.5,1632c-0.3,3-4.7,6.3-7.3,5.5c-3-0.6-5.2-3.1-5.6-6.2c-0.3-2.6,4.1-5.6,7.2-5
		C6335.7,1626.8,6338,1629.1,6338.5,1632z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M3899.8,291.7c-0.2,5.9-4.7,10.6-10.6,11c-6.2,0.4-11.6-4.3-12.1-10.5c-0.3-6.1,4.4-11.2,10.4-11.5
		c0,0,0,0,0.1,0h0.5C3894.3,280.6,3899.5,285.4,3899.8,291.7z M3895.2,291.7c-0.5-2.9-2.8-5.1-5.7-5.6c-3.1-0.7-7.5,2.4-7.2,5
		c0.3,3,2.6,5.5,5.6,6.2C3890.5,298.1,3894.9,294.7,3895.2,291.7L3895.2,291.7z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M3990.6,299c-2.9,0.3-6.1-3.3-6.6-7.2c-0.4-3.5,2.8-7.5,6.2-7.2s6.2,1.8,6.6,5.8S3994,298.7,3990.6,299z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M3895.2,291.7c-0.3,3-4.7,6.3-7.3,5.5c-3-0.6-5.2-3.1-5.6-6.2c-0.3-2.6,4.1-5.6,7.2-5
		C3892.4,286.5,3894.7,288.8,3895.2,291.7z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M1734.5,1434.1c-0.2,5.9-4.7,10.6-10.6,11c-6.2,0.4-11.6-4.3-12.1-10.5c-0.3-6.1,4.4-11.2,10.4-11.5
		c0,0,0,0,0.1,0h0.5C1729,1422.9,1734.2,1427.8,1734.5,1434.1z M1729.9,1434.1c-0.5-2.9-2.8-5.1-5.7-5.6c-3.1-0.7-7.5,2.4-7.2,5
		c0.3,3,2.6,5.5,5.6,6.2C1725.2,1440.4,1729.6,1437.1,1729.9,1434.1L1729.9,1434.1z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M1825.3,1441.4c-2.9,0.3-6.1-3.3-6.6-7.2c-0.4-3.5,2.8-7.5,6.2-7.2s6.2,1.8,6.6,5.8
		S1828.7,1441.1,1825.3,1441.4z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M1729.9,1434.1c-0.3,3-4.7,6.3-7.3,5.5c-3-0.6-5.2-3.1-5.6-6.2c-0.3-2.6,4.1-5.6,7.2-5
		C1727.1,1428.9,1729.4,1431.2,1729.9,1434.1z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M4001.1,995.7c0,4.9-2.4,7.3-7.2,7.2c-3.8,0-6.2-1.7-6.4-5.8c-0.1-4.3,3.3-7.8,7.6-7.9c0,0,0,0,0,0h0.4
		C3999.2,989.4,4001.1,991.6,4001.1,995.7z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M6043.9,793.4c-2.3-5-2.8-10.6-1.4-15.9c1.7-8.4,9.9-13.8,18.3-12.1c2.3,0.5,4.5,1.5,6.4,2.9
		c3.5,2.9,6.7,6.2,9.5,9.9c7.2,8.1,0.7,24.1-9.1,26.3C6054.1,807.5,6048.1,805.4,6043.9,793.4z M6065.1,799.1
		c9.6-3.4,11.9-12.5,5.1-20.2c-1.7-2.1-3.4-4-5.3-5.9c-4.4-3.8-11.1-3.3-14.9,1.1c0,0,0,0,0,0c-0.7,0.8-1.2,1.6-1.6,2.5
		c-1,2.8-1.6,5.7-1.6,8.7C6046,797.4,6053.8,803.1,6065.1,799.1L6065.1,799.1z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M6346.6,618.8c3.3,8.6-2.5,22.7-10.7,26.1c-7.8,3.3-20.3-3.3-24-12.6c-3.4-8.6,1-21.6,8.1-24
		C6327.8,605.7,6344,612,6346.6,618.8z M6321.1,613.6c-0.5,0.4-1,0.8-1.5,1.2c-5.9,5.9-4.6,18.9,2.6,23.1c3.6,2.1,7.7,3.9,12.2,1.8
		c6.3-3.2,9.6-13.8,6.4-19.8C6338.9,616.5,6324.7,612,6321.1,613.6L6321.1,613.6z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M5873.9,773.2c-9.5,3.6-15.7,1.4-18.6-6.7c-3.3-9.1,0.6-17.2,10.1-20.5c7.3-2.8,15.6,0.9,18.4,8.2
		c0.1,0.1,0.1,0.3,0.2,0.5C5887,762.4,5883,769.7,5873.9,773.2z M5871.3,768.5c7.7-2.8,9.6-6.5,7-13.2c-2.2-5.8-4.3-6.6-10.7-4.4
		c-7,2.5-10.9,8.7-8.7,14C5860.7,769.4,5865.1,770.7,5871.3,768.5L5871.3,768.5z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M5647.4,880.1c7.6-3,13.3,1.5,12.3,9.3c-0.2,1.3-0.5,2.6-0.9,3.8c-1.8,6-5.8,9.8-9.8,9.3
		c-4-0.5-6.5-4.2-7.3-10.2C5640.8,886.2,5643,881.8,5647.4,880.1z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M5827.3,678.1c2.7,7-0.4,14.9-7.2,18.2c-7.3,3-13,0.3-16-7.8s-0.8-15,6.5-17.9c6.4-2.6,13.8,0.5,16.4,6.9
		C5827.2,677.7,5827.3,677.9,5827.3,678.1z M5822.8,681.8c-1.8-6-5.7-9.1-9.6-7.7c-4.8,2.1-7.1,7.4-5.5,12.3c1.5,5.3,4.4,6.8,9.7,5
		C5823.1,689.4,5824.4,687.1,5822.8,681.8L5822.8,681.8z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M5997,609.2c2.9,8.5,0.9,13.8-6,16.2c-6.3,2.2-11.4-1-14-8.6c-2.4-6.9,0.3-13,6.7-15.3
		S5994.5,601.7,5997,609.2z M5993.3,610.6c-1.5-3.9-4-5.3-7.5-4.2c-4,1.4-6.2,5.9-4.7,9.9c0,0,0,0,0,0l0.1,0.3
		c1.6,3.7,4.4,4.4,8,3.1C5993.6,618.4,5995,615.2,5993.3,610.6L5993.3,610.6z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M5599.5,714.7c1.1,3.5-0.5,7.3-3.8,9c-4.5,1.5-9.4-0.6-11.2-5c-1.3-3.9,0.6-8.7,4-9.9
		C5592.3,707.5,5598.1,710.6,5599.5,714.7z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M6249.2,553.1c1,3.7-0.9,8.2-3.8,9.2c-3.4,1.1-7.9-1.7-9.1-5.7c-0.9-3.6,1.2-7.2,4.8-8.1c3.4-0.9,7,1.1,8,4.5
		C6249.2,553,6249.2,553,6249.2,553.1z"
                />

                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M6632.1,1157.6c-3.7-2.4-8.9-3-12.3-7c-4.4-5.2-3.5-6.9,2.3-10.9c5.4-3.7,9-2.4,14,0.6
		c9.9,5.9,10.1,6.3,1.4,14.3C6636.1,1156,6634.9,1157.7,6632.1,1157.6z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M6503.5,1061c7.6-3,13.3,1.5,12.3,9.3c-0.2,1.3-0.5,2.6-0.9,3.8c-1.8,6-5.8,9.8-9.8,9.3
		c-4-0.5-6.5-4.2-7.3-10.2C6496.9,1067.1,6499.1,1062.7,6503.5,1061z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M6455.6,895.6c1.1,3.5-0.5,7.3-3.8,9c-4.5,1.5-9.4-0.6-11.2-5c-1.3-3.9,0.6-8.7,4-9.9
		C6448.4,888.4,6454.2,891.5,6455.6,895.6z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M6497,981.8c3-1.1,6.2,0.5,7.3,3.5c0.1,0.3,0.2,0.7,0.3,1.1c0.6,3.1,0,6.1-3.3,7.2c-3.3,0.9-6.8-1-7.7-4.4
		C6492.8,986.2,6494.2,983,6497,981.8L6497,981.8z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M6065.1,799.1c-11.3,4-19.1-1.7-18.4-13.8c0.1-3,0.6-5.9,1.6-8.7c2.4-5.3,8.7-7.7,14-5.3c0,0,0,0,0,0
		c0.9,0.4,1.8,1,2.5,1.6c1.9,1.8,3.7,3.8,5.3,5.9C6077,786.6,6074.7,795.7,6065.1,799.1z"
                />

                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M5871.3,768.5c-6.2,2.2-10.6,1-12.4-3.6c-2.1-5.3,1.7-11.5,8.7-14c6.4-2.2,8.4-1.4,10.7,4.4
		C5880.9,762,5878.9,765.6,5871.3,768.5z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M5822.9,681.7c1.6,5.3,0.3,7.7-5.3,9.6c-5.3,1.8-8.2,0.3-9.7-5c-1.7-4.9,0.7-10.3,5.5-12.3
		C5817.1,672.6,5821,675.7,5822.9,681.7z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M5993.3,610.7c1.7,4.6,0.3,7.7-4.2,9.3c-3.6,1.4-6.4,0.6-8-3.1c-1.6-4,0.3-8.5,4.3-10.1c0,0,0,0,0,0l0.3-0.1
		C5989.3,605.4,5991.9,606.7,5993.3,610.7z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M3351.5,1255.1c3.7,0,6,1.5,6.4,5s-2.9,7.9-5.8,7.9c-2.3,0-7.6-4.9-7.2-7.2
		C3345.5,1257.6,3348.2,1255.3,3351.5,1255.1z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M3711.7,674.2c-7.8,0-9.9-3.2-9.8-14.5c0-9.6,4.3-14.3,13.4-14.5c7.3-0.2,12.2,5.4,11.9,13.7
		C3727,668.3,3720.7,674.4,3711.7,674.2z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M3523.5,839.1c-7.8,0-9.9-3.2-9.8-14.5c0-9.6,4.3-14.3,13.4-14.5c7.3-0.2,12.2,5.4,11.9,13.7
		C3538.7,833.2,3532.4,839.3,3523.5,839.1z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M3899.7,590.9c-0.5,6.5-5.9,11.6-12.4,11.6c-5-0.2-10.3-6.2-10.2-11.6s6.8-11.1,12.2-11.2
		S3899.8,585.2,3899.7,590.9z M3887.8,585c-3.3,0.2-6,2.7-6.5,6c-0.4,2.3,4.9,7.2,7.2,7.2c2.9,0,6.2-4.7,5.8-7.9
		S3891.6,585.1,3887.8,585L3887.8,585z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M3813.1,658c0.1-5.2,2.8-7.7,8.1-7.6s7.7,2.7,7.6,8.1c0.3,4.5-3.1,8.3-7.6,8.7
		C3816.5,667.2,3813.1,663.3,3813.1,658z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M3769.4,615.6c0,4.2-3.3,8.2-6.8,8.2s-8.6-5.6-8.4-9.4c0.2-3.5,4.4-7,8.3-6.9S3769.2,611.5,3769.4,615.6z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M3887.9,585c3.7,0,6,1.5,6.4,5s-2.9,7.9-5.8,7.9c-2.3,0-7.6-4.9-7.2-7.2
		C3881.9,587.6,3884.6,585.2,3887.9,585z"
                />
            </g>
            <g className="flicker-4">
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M3094.3,909.7c-7.8,0-9.9-3.2-9.8-14.5c0-9.6,4.3-14.3,13.4-14.5c7.3-0.2,12.2,5.4,11.9,13.7
		C3109.5,903.7,3103.2,909.8,3094.3,909.7z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M3316.7,736.4c-0.5,6.5-5.9,11.6-12.4,11.6c-5-0.2-10.3-6.2-10.2-11.6s6.8-11.1,12.2-11.2
		S3316.7,730.7,3316.7,736.4z M3304.8,730.5c-3.3,0.2-6,2.7-6.5,6c-0.4,2.3,4.9,7.2,7.2,7.2c2.9,0,6.2-4.7,5.8-7.9
		S3308.5,730.6,3304.8,730.5L3304.8,730.5z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M3195.6,893.4c0.1-5.2,2.8-7.7,8.1-7.6s7.7,2.7,7.6,8.1c0.3,4.5-3.1,8.3-7.6,8.7
		C3199.1,902.7,3195.6,898.7,3195.6,893.4z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M3151.9,851.1c0,4.2-3.3,8.2-6.8,8.2s-8.6-5.6-8.4-9.4c0.2-3.5,4.4-7,8.3-6.9S3151.8,846.9,3151.9,851.1z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M3304.8,730.6c3.7,0,6,1.5,6.4,5s-2.9,7.9-5.8,7.9c-2.3,0-7.6-4.9-7.2-7.2
		C3298.8,733.2,3301.5,730.8,3304.8,730.6z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M2212.8,313.6c-7.8,0-9.9-3.2-9.8-14.5c0-9.6,4.3-14.3,13.4-14.5c7.3-0.2,12.2,5.4,11.9,13.7
		C2228.1,307.6,2221.8,313.7,2212.8,313.6z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M2334.6,135.5c10.8,0,16.4,5,16.9,14.4c0.4,9.9-5,15.6-15.5,16.4c-11.3,0.8-17.5-2.6-22.5-12.5
		c-2.8-4.8-1.3-10.9,3.5-13.8c0,0,0,0,0,0c0.3-0.2,0.7-0.4,1-0.5C2323,136.5,2328.7,135.2,2334.6,135.5z M2333.3,160.6h4.7
		c4,0,7.1-0.8,7.9-5.5c1.1-6.4-0.7-12.4-5.3-13.4c-6.4-1.4-13-2-19.3,0.7c-6,2.6-6.6,6.5-2.6,11.7S2327.4,161.9,2333.3,160.6
		L2333.3,160.6z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M2400.8,230.2c-0.5,6.5-5.9,11.6-12.4,11.6c-5-0.2-10.3-6.2-10.2-11.6s6.8-11.1,12.2-11.2
		S2400.9,224.6,2400.8,230.2z M2388.9,224.4c-3.3,0.2-6,2.7-6.5,6c-0.4,2.3,4.9,7.2,7.2,7.2c2.9,0,6.2-4.7,5.8-7.9
		S2392.7,224.4,2388.9,224.4L2388.9,224.4z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M2469.1,170.3c-4,0.2-7.9-4-7.9-8.4s5.6-10.8,9.8-10.9c3.4,0,7,4.6,7.2,9.2S2473.4,170.1,2469.1,170.3z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M2472.1,300.8c-4.4,0-8.2-3.2-8.9-7.5c-0.5-5.1,3.4-10.3,8-10.7c3.6-0.3,7.9,4,8.5,8.2
		S2476.1,300.5,2472.1,300.8z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M2314.1,297.3c0.1-5.2,2.8-7.7,8.1-7.6s7.7,2.7,7.6,8.1c0.3,4.5-3.1,8.3-7.6,8.7
		C2317.6,306.6,2314.1,302.6,2314.1,297.3z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M2270.4,255c0,4.2-3.3,8.2-6.8,8.2s-8.6-5.6-8.4-9.4c0.2-3.5,4.4-7,8.3-6.9S2270.3,250.8,2270.4,255z"
                />
                <path
                    className="st14"
                    style={{ fill: "#C5D3B3" }}
                    d="M2333.3,160.7c-5.8,1.3-10.7-1.3-14.6-6.4s-3.3-9.2,2.6-11.7c6.3-2.7,12.9-2.1,19.3-0.7c4.6,1,6.4,7,5.3,13.4
		c-0.8,4.7-4,5.6-7.9,5.5C2336.6,160.6,2335.3,160.7,2333.3,160.7z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M2388.9,224.4c3.7,0,6,1.5,6.4,5s-2.9,7.9-5.8,7.9c-2.3,0-7.6-4.9-7.2-7.2
		C2383,227,2385.7,224.6,2388.9,224.4z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M6120.3,1364.8c-7.8,0-9.9-3.2-9.8-14.5c0-9.6,4.3-14.3,13.4-14.5c7.3-0.2,12.2,5.4,11.9,13.7
		C6135.5,1358.8,6129.2,1364.9,6120.3,1364.8z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M4288.1,1661.8c-4,0.1-6.3-2.6-6.3-7c0-3.3,1.1-5.9,4.7-6.4c3-0.4,6.8,3.4,7.1,6.8c0.5,3.1-1.6,6-4.7,6.5
		C4288.7,1661.8,4288.4,1661.8,4288.1,1661.8z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M131.7,213.4c-0.8,3.3-3.9,5.4-7.2,4.9c-2.9,0.1-5.3-2.2-5.3-5.1c0-0.3,0-0.6,0.1-0.9c0.4-3.4,3.2-6,6.6-6.1
		C129.2,206.9,131.7,209.9,131.7,213.4z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M6242,1186.6c10.8,0,16.4,5,16.9,14.4c0.4,9.9-5,15.6-15.5,16.4c-11.3,0.8-17.5-2.6-22.5-12.5
		c-2.8-4.8-1.3-10.9,3.5-13.8c0,0,0,0,0,0c0.3-0.2,0.7-0.4,1-0.5C6230.4,1187.7,6236.2,1186.4,6242,1186.6z M6240.8,1211.8h4.7
		c4,0,7.1-0.8,7.9-5.5c1.1-6.4-0.7-12.4-5.3-13.4c-6.4-1.4-13-2-19.3,0.7c-6,2.6-6.6,6.5-2.6,11.7
		C6230.2,1210.5,6234.9,1213.1,6240.8,1211.8L6240.8,1211.8z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M6308.3,1281.4c-0.5,6.5-5.9,11.6-12.4,11.6c-5-0.2-10.3-6.2-10.2-11.6s6.8-11.1,12.2-11.2
		S6308.4,1275.7,6308.3,1281.4z M6296.4,1275.6c-3.3,0.2-6,2.7-6.5,6c-0.4,2.3,4.9,7.2,7.2,7.2c2.9,0,6.2-4.7,5.8-7.9
		C6302.4,1277.5,6300.1,1275.6,6296.4,1275.6L6296.4,1275.6z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M6376.5,1221.5c-4,0.2-7.9-4-7.9-8.4s5.6-10.8,9.8-10.9c3.4,0,7,4.6,7.2,9.2
		C6385.8,1216.1,6380.9,1221.3,6376.5,1221.5z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M6379.6,1352c-4.4,0-8.2-3.2-8.9-7.5c-0.5-5.1,3.4-10.3,8-10.7c3.6-0.3,7.9,4,8.5,8.2
		S6383.6,1351.7,6379.6,1352z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M6221.6,1348.5c0.1-5.2,2.8-7.7,8.1-7.6c5.3,0.1,7.7,2.7,7.6,8.1c0.3,4.5-3.1,8.3-7.6,8.7
		C6225.1,1357.8,6221.6,1353.8,6221.6,1348.5z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M6177.9,1306.2c0,4.2-3.3,8.2-6.8,8.2s-8.6-5.6-8.4-9.4c0.2-3.5,4.4-7,8.3-6.9S6177.8,1302,6177.9,1306.2z"
                />
                <path
                    className="st14"
                    style={{ fill: "#C5D3B3" }}
                    d="M6240.8,1211.9c-5.8,1.3-10.7-1.3-14.6-6.4c-3.9-5.1-3.3-9.2,2.6-11.7c6.3-2.7,12.9-2.1,19.3-0.7
		c4.6,1,6.4,7,5.3,13.4c-0.8,4.7-4,5.6-7.9,5.5C6244.1,1211.8,6242.8,1211.9,6240.8,1211.9z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M6296.4,1275.6c3.7,0,6,1.5,6.4,5c0.5,3.5-2.9,7.9-5.8,7.9c-2.3,0-7.6-4.9-7.2-7.2
		C6290.5,1278.1,6293.2,1275.8,6296.4,1275.6z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M1583.7,1175.4c-7.8,0-9.9-3.2-9.8-14.5c0-9.6,4.3-14.3,13.4-14.5c7.3-0.2,12.2,5.4,11.9,13.7
		C1598.9,1169.5,1592.7,1175.6,1583.7,1175.4z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M1474.7,918.2c0,6.6-6.1,13.7-11.7,13.9s-13.5-8.1-13.6-13.7c0.1-6.5,5.4-11.7,11.9-11.6h0.3
		C1469.9,906.9,1474.7,911,1474.7,918.2z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M1705.4,997.3c10.8,0,16.4,5,16.9,14.4c0.4,9.9-5,15.6-15.5,16.4c-11.3,0.8-17.5-2.6-22.5-12.5
		c-2.8-4.8-1.3-10.9,3.5-13.8c0,0,0,0,0,0c0.3-0.2,0.7-0.4,1-0.5C1693.9,998.4,1699.6,997,1705.4,997.3z M1704.2,1022.5h4.7
		c4,0,7.1-0.8,7.9-5.5c1.1-6.4-0.7-12.4-5.3-13.4c-6.4-1.4-13-2-19.3,0.7c-6,2.6-6.6,6.5-2.6,11.7S1698.3,1023.8,1704.2,1022.5
		L1704.2,1022.5z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M1837.9,926c-0.1,5.2-4,9.2-8.6,8.9c-5-0.4-10.7-6.4-10.7-11.4c0-4.1,4.9-8.8,9.1-9
		C1832.7,914.4,1837.9,920.3,1837.9,926z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M1771.7,1092.1c-0.5,6.5-5.9,11.6-12.4,11.6c-5-0.2-10.3-6.2-10.2-11.6s6.8-11.1,12.2-11.2
		S1771.8,1086.4,1771.7,1092.1z M1759.8,1086.2c-3.3,0.2-6,2.7-6.5,6c-0.4,2.3,4.9,7.2,7.2,7.2c2.9,0,6.2-4.7,5.8-7.9
		S1763.6,1086.3,1759.8,1086.2L1759.8,1086.2z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M1628.8,902.6c0,4.8-3.9,8.7-8.7,8.6c-4.4-0.5-7.7-4.2-7.8-8.6c0.3-4.4,3.8-7.9,8.2-8.1
		C1624.9,894.7,1628.5,898.2,1628.8,902.6z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M1685,1159.2c0.1-5.2,2.8-7.7,8.1-7.6s7.7,2.7,7.6,8.1c0.3,4.5-3.1,8.3-7.6,8.7
		C1688.5,1168.4,1685,1164.5,1685,1159.2z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M1641.3,1116.9c0,4.2-3.3,8.2-6.8,8.2s-8.6-5.6-8.4-9.4c0.2-3.5,4.4-7,8.3-6.9S1641.2,1112.7,1641.3,1116.9z"
                />
                <path
                    className="st14"
                    style={{ fill: "#C5D3B3" }}
                    d="M1704.2,1022.6c-5.8,1.3-10.7-1.3-14.6-6.4s-3.3-9.2,2.6-11.7c6.3-2.7,12.9-2.1,19.3-0.7
		c4.6,1,6.4,7,5.3,13.4c-0.8,4.7-4,5.6-7.9,5.5C1707.5,1022.5,1706.2,1022.6,1704.2,1022.6z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M1759.8,1086.2c3.7,0,6,1.5,6.4,5s-2.9,7.9-5.8,7.9c-2.3,0-7.6-4.9-7.2-7.2
		C1753.9,1088.8,1756.6,1086.4,1759.8,1086.2z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M3088.8,185.5c3.7,0.3,5,2.5,5.2,5.8c0.3,4.4-2.5,9.1-5.6,8.9c-4-0.2-4.2-3.4-4.2-6.4
		C3084.2,190.1,3084,186.4,3088.8,185.5z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M4231.3,624.5c3.3,0.7,5.7,3.4,6,6.8c0.5,3-1.5,5.8-4.5,6.3c-0.4,0.1-0.8,0.1-1.2,0.1c-3.1,0.3-5.7-2-6-5.1
		c0,0,0,0,0,0c0-0.3,0-0.6,0-0.9C4225.8,628.2,4228.1,625.3,4231.3,624.5z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M1015.1,1091.2c0,6-5.4,11.7-11,11.7s-10.6-6-10.5-11.9c0-5.5,5.5-11.4,10.2-11.3
		C1009.3,1079.9,1015.1,1085.8,1015.1,1091.2z M1003.1,1083.9c-3.3,0.8-5.6,3.7-5.7,7.1c-0.3,3,2,5.7,5,6c0.3,0,0.6,0,0.9,0
		c3,0.1,5.6-2.2,5.7-5.2c0-0.4,0-0.8-0.1-1.2C1008.8,1087.3,1006.4,1084.6,1003.1,1083.9z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M983,984.2c4.1,0.1,7.4,3.3,7.7,7.3c-0.2,3.7-3.2,6.7-6.9,7c-3.9-0.2-5.6-3-6.2-6.4c-0.8-3.5,1.3-6.9,4.8-7.7
		c0,0,0,0,0,0C982.5,984.3,982.7,984.3,983,984.2z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M1003.1,1083.9c3.3,0.7,5.7,3.4,6,6.8c0.5,3-1.5,5.8-4.5,6.3c-0.4,0.1-0.8,0.1-1.2,0.1c-3.1,0.3-5.7-2-6-5.1
		c0,0,0,0,0,0c0-0.3,0-0.6,0-0.9C997.6,1087.6,999.9,1084.7,1003.1,1083.9z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M1023.8,1721c-0.9,3.1-2.8,5.8-6.5,5.8c-3.1,0-3.2-2.7-3.1-5c0.1-4.2,2.6-6.7,5.8-6
		C1022.5,1716.2,1024.1,1718.5,1023.8,1721z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M4895.8,1720.5c-0.6,3.8-3.8,6.5-7.6,6.7c-3.7-0.2-4-2.9-3.8-5.7c-0.2-3,2-5.6,5.1-5.9c0,0,0,0,0,0
		c0.5,0,0.9,0,1.4,0.1c2.6-0.1,4.8,1.9,4.9,4.5c0,0,0,0,0,0C4895.8,1720.2,4895.8,1720.3,4895.8,1720.5z"
                />
                <path
                    className="st6"
                    style={{ fill: "#A9B9B2" }}
                    d="M4478.1,461.4c0,9.4-6.5,17.4-14.1,17.4c-8.3,0-17.2-8.6-16.9-16.3s9.7-15.3,18.2-15.3
		C4473.3,447.2,4478.1,452.4,4478.1,461.4z M4472.3,462.1c0-8.4-2.8-10.4-11-7.8c-0.9,0.3-2,0.5-2.8,0.8c-3.9,1.3-6.4,5-6,9.1
		c0.2,4.3,4,7.8,9.9,9.5c4.3,1.2,5.8-2.1,7.9-4.3s1.8-4.7,1.8-7.3L4472.3,462.1z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M4595.3,500.8c0-6,5-10.9,11-10.9h0.6c6.5,0.4,11.5,5.9,11.4,12.4c0,6.5-5,12.1-10.7,11.9
		S4595.2,506.6,4595.3,500.8z M4607.3,508.4c3.1,0,5.7-2.6,5.6-5.7c0,0,0,0,0,0c0-0.3,0-0.6-0.1-0.9c-0.4-3.5-4.1-7.3-7.1-6.8
		c-3.6,0.5-4.7,3.1-4.7,6.4C4601.1,505.8,4603.4,508.6,4607.3,508.4L4607.3,508.4z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M4566.6,352.5c4.4,0.4,7.9,3.7,8.4,8.1c0.2,5.3-4.6,10.9-9.2,10.7c-4.4-0.3-7.9-4-7.8-8.4
		C4557.9,358.2,4562.6,352.6,4566.6,352.5z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M4677.8,463.5c-0.8,6.3-6.6,11.4-12,10.7c-4.5-0.6-9-6.9-8.5-11.9s7.2-10.6,11.8-10.1
		C4674.7,453,4678.5,458,4677.8,463.5z M4674.1,462c0.1-2.6-2-4.8-4.6-4.9h-0.4c-3-0.6-5.9,1.4-6.4,4.3c0,0,0,0,0,0
		c-0.1,0.5-0.1,0.9-0.1,1.4c-0.2,2.8,0.1,5.5,3.8,5.7C4670.2,468.5,4673.5,465.8,4674.1,462L4674.1,462z"
                />

                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M4607.3,508.4c-4,0.1-6.3-2.6-6.3-7c0-3.3,1.1-5.9,4.7-6.4c3-0.4,6.8,3.4,7.1,6.8c0.5,3.1-1.6,6-4.7,6.5
		C4607.9,508.4,4607.6,508.4,4607.3,508.4z"
                />
                <path
                    className="st8"
                    style={{ fill: "#C0A4AC" }}
                    d="M345.6,877.7c-4.3-0.1-7.3-1.2-7.2-5.5s2.8-5.7,6.4-5.8s6.3,1.7,6.5,5.6C351.4,876.3,348.4,877.4,345.6,877.7z
		"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M4674.1,462c-0.6,3.8-3.8,6.5-7.6,6.7c-3.7-0.2-4-2.9-3.8-5.7c-0.2-3,2-5.6,5.1-5.9c0,0,0,0,0,0
		c0.5,0,0.9,0,1.4,0.1c2.6-0.1,4.8,1.9,4.9,4.5c0,0,0,0,0,0C4674.1,461.8,4674.1,461.9,4674.1,462z"
                />
                <path
                    className="st0"
                    style={{ fill: "#A9BBB7" }}
                    d="M4276.1,1654.2c0-6,5-10.9,11-10.9h0.6c6.5,0.4,11.5,5.9,11.4,12.4c0,6.5-5,12.1-10.7,11.9
		S4275.9,1660,4276.1,1654.2z M4288.1,1661.8c3.1,0,5.7-2.6,5.6-5.7c0,0,0,0,0,0c0-0.3,0-0.6-0.1-0.9c-0.4-3.5-4.1-7.3-7.1-6.8
		c-3.6,0.5-4.7,3.1-4.7,6.4C4281.8,1659.2,4284.2,1662,4288.1,1661.8L4288.1,1661.8z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M4247.3,1505.9c4.4,0.4,7.9,3.7,8.4,8.1c0.2,5.3-4.6,10.9-9.2,10.7c-4.4-0.3-7.9-4-7.8-8.4
		C4238.6,1511.6,4243.3,1506,4247.3,1505.9z"
                />
                <path
                    className="st7"
                    style={{ fill: "#A0B5B3" }}
                    d="M4358.6,1616.9c-0.8,6.3-6.6,11.4-12,10.7c-4.5-0.6-9-6.9-8.5-11.9s7.2-10.6,11.8-10.1
		C4355.4,1606.4,4359.3,1611.4,4358.6,1616.9z M4354.9,1615.4c0.1-2.6-2-4.8-4.6-4.9h-0.4c-3-0.6-5.9,1.4-6.4,4.3c0,0,0,0,0,0
		c-0.1,0.5-0.1,0.9-0.1,1.4c-0.2,2.8,0.1,5.5,3.8,5.7C4351,1621.9,4354.2,1619.2,4354.9,1615.4L4354.9,1615.4z"
                />
                <path
                    className="st1"
                    style={{ fill: "#DEEBBF" }}
                    d="M4153,1615.5c0,2.6,0,5.3-2,7.3s-3.7,5.5-7.9,4.3c-5.8-1.7-9.6-5.1-9.9-9.5c-0.4-4,2.1-7.8,6-9.1
		c0.9-0.4,2-0.5,2.8-0.8C4150.2,1605.1,4153.1,1607.1,4153,1615.5z"
                />

                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M4354.9,1615.4c-0.6,3.8-3.8,6.5-7.6,6.7c-3.7-0.2-4-2.9-3.8-5.7c-0.2-3,2-5.6,5.1-5.9c0,0,0,0,0,0
		c0.5,0,0.9,0,1.4,0.1c2.6-0.1,4.8,1.9,4.9,4.5c0,0,0,0,0,0C4354.9,1615.2,4354.9,1615.3,4354.9,1615.4z"
                />
                <path
                    className="st4"
                    style={{ fill: "#AFB195" }}
                    d="M6479,626.1c6.2-0.4,14.1,2.8,22.4,2.6c11.4-0.4,12.7-1,15.9-12c1.2-4.1,2-8.3,3.3-12.4c1-2.9,3-5.5,6.3-5.5
		c3,0,4.3,2.9,5.1,5.4c1.1,3.1,2,6.3,2.5,9.5c1.8,14.1,10.9,22.5,22.9,27.3c7.8,3.2,12.2,9.8,19,13.9c3,1.8,1.7,4.6-1.1,6.2
		c-2.4,1.2-5,1.9-7.6,2c-6.9,0.7-12.1,5.1-17.6,8.6c-3,1.8-2.6,5.2-2.4,8.1c0.2,5.3-1.1,10.6-3.5,15.3c-2.9,6-2.7,5.8-7.6,1.5
		c-6.2-5.5-13-10.3-22-8.9c-3.2,0.5-6.2,2.4-9.4,3c-8.2,1.2-16.2,3.1-24.1,5.4c-2.5,0.8-5.5,1.4-7.2-1.3c-2-2.8-1.7-6.7,0.6-9.2
		c2-2.2,4-4.4,5.8-6.7c6.9-8.7,7.2-16.3,0.9-25.3c-3.6-5.1-7.3-10.1-11-15.2c-1.2-1.5-2.3-3.2-3.1-5c-1.7-4.5,0-6.7,4.9-7
		C6474.3,626.1,6476.6,626,6479,626.1z M6484.6,686.2c1.8,1.7,3.6,0.9,5.4,0.2c5.5-2.1,11.2-4,16.6-6.3c9.6-4.1,18.5-3.6,26.5,3.8
		c1.6,1.5,3.3,3.3,6.3,1.8c0.2-2.4,0.1-5.1,0.5-7.6c1.8-11.8,5.8-15.9,17.9-18.8c1.6-0.4,3.2-0.9,4.7-1.5c3.5-1.3,2.8-3.6,0.4-5
		c-5.7-3.3-11.1-7.4-16.9-10.1c-9.3-4.3-13.1-11.1-14.6-20.5c-0.7-4.5-1.5-9.5-5.6-13.8c-3.2,5.8-3.7,11.4-4.9,16.7
		c-3.5,15.2-6.9,12.8-18.7,12.6c-1.6,0-3.2-1-4.8-1c-7.3-0.3-14.4-3.3-22.3-2.3C6500.8,666.6,6492.9,667.6,6484.6,686.2
		L6484.6,686.2z"
                />
                <path
                    className="st13"
                    style={{ fill: "#D2E6CC" }}
                    d="M3024.4,489.7c0,2.9-2.4,5.1-5.2,5.1c-0.3,0-0.6,0-1-0.1c-2.4-0.2-4.8-1.1-4.6-3.9c0.2-3.1,2.7-5.4,5.8-5.4
		C3022.5,485,3023.9,487.1,3024.4,489.7z"
                />
                <path
                    className="st3"
                    style={{ fill: "#B5B599" }}
                    d="M1376.3,297c5,0,9.9-0.4,14.9,0c9.2,0.8,14.7-4.5,17.6-11.7c6.2-14.5,11.5-29.4,15.9-44.5
		c1.7-6.1,2.7-12.6,4.9-18.8c1.3-3.8,3.4-6.6,7.5-6.8c3.7-0.2,4.9,3.1,6.1,5.7c6.1,12.4,11.3,25.2,15.7,38.3
		c2.5,7.8,4.6,15.7,7.1,23.5c3.2,9.7,6.8,14.2,17.2,13.9c11.8-0.3,22.3,5.2,33.7,5.7c2.3-0.1,4.6,0.9,6.2,2.6c2.2,2.4,3.5,4.4,0,7.3
		c-7.3,6.1-15.8,10.6-25,13.1c-9,2.7-12.7,9.4-11.6,18.2c1.4,11.4,5.7,22.3,5.4,34c0,2.3-0.1,4.6-0.4,6.9c-0.6,3.2-2.5,4.5-5.7,3.2
		c-1.6-0.6-3-1.3-4.4-2.2c-12.9-9.6-26.8-17.9-37.5-30.3c-5.2-6.4-14.3-8-21.3-3.6c-7.2,4.6-13.9,10-20,15.9
		c-5.1,4.6-9.9,9.5-15.2,14c-1.8,1.5-3.8,2.7-6,3.5c-2.6,1-5.4,3.5-8.3,1.4c-3.3-2.4-3.6-6.3-2.7-9.9c3-13.1,5.8-26.4,9.5-39.3
		c1.5-5.1,0.1-8-3.1-11.4c-5.6-5.7-12.3-9.9-18.4-15c-3.3-2.8-9.3-5.1-7.6-10.3c1.6-4.7,7.4-3.1,11.5-3.3
		C1366.9,296.8,1371.6,297,1376.3,297L1376.3,297z M1484.2,378.5c1-8.8,0.2-17.7-2.2-26.2c-5.6-17.8-0.4-27.8,17.1-33.5
		c4.9-1.6,8.7-4.6,13.3-6.4c1.6-0.6,3.2-1.7,2.7-3.6s-2.3-2.3-4-2.5c-12-1.5-24-3.3-36-5.4c-8.2-1.5-12.5-4.6-14.6-12.1
		s-3.3-15.3-5.7-22.9c-4.2-12.8-9-25.3-14.4-37.6c-0.6-2-2.4-3.4-4.4-3.4c-2.7,0.1-2.8,2.3-3.1,4.3c-2.8,22.2-11.9,42.3-21,62.3
		c-2.8,6.1-7,9.9-14.7,9c-4.9-0.6-9.9,0.1-14.9-0.2c-5.2-0.4-10.5,0.8-15.1,3.3c-4,2.2-4.4,3.2-1,6c5.1,4.2,10.9,7.4,15.5,12.2
		c5,4.6,6.7,11.8,4.2,18.2c-3.5,10.3-6.2,20.8-7.9,31.5c-0.3,2-1.4,4.1,0.5,5.5c2.3,1.6,4.3-0.2,6.2-1.5c10.5-7.5,19.3-16.9,29-25.3
		c10-8.8,25.4-9,34.4,0.5C1458.6,361.7,1469.2,372.2,1484.2,378.5L1484.2,378.5z"
                />
                <path
                    className="st2"
                    style={{ fill: "#A4BBC2" }}
                    d="M5640.9,800.9c3-1.1,6.2,0.5,7.3,3.5c0.1,0.3,0.2,0.7,0.3,1.1c0.6,3.1,0,6.1-3.3,7.2c-3.3,0.9-6.8-1-7.7-4.4
		C5636.7,805.2,5638.1,802.1,5640.9,800.9L5640.9,800.9z"
                />
            </g>
        </g>
    );
};

export default BackgroundStarsLayer;
