import CardItem from "components/CardItem";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";

const CardMenuItem = (props: Props) => {
    return (
        <Link to={props.link || ''} className={` ${props.disabled ? 'opacity-40' : ''}`}>
            <CardItem style={{ ...props.style }} className={`${props.className}`}>
                <div className={`card-menu-item menu-animation `}>
                    {
                        props.hideLogo !== true &&
                        <img src="/assets/img/moon-menu.png" className="sun-animation" />
                    }

                    <h3 className="capitalize">{props.menuLabel}</h3>
                    <div className="h-[3px] w-[40px] rounded bg-primary line-animation"></div>
                </div>
            </CardItem>
        </Link>
    )
}

type Props = {
    menuLabel: string,
    link?: string,
    class?: string,
    style?: CSSProperties
    className?: string
    disabled?: boolean
    hideLogo?: boolean
}

export default CardMenuItem;