import { AppRouteProps } from "components/AppRoutes";
import Cube from "./containers/Cube";


export const CubeRoutes: Array<AppRouteProps> = [
    {
        path: 'cube',
        title: 'home cube',
        element: <Cube />
    },
]