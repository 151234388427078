import { useTranslations } from "hooks/translation";
import { useTranslationLang } from "hooks/translation/useTranslationLang";
import { useBreakpoints } from "hooks/utils/useBreakpoints";
import { useIsHome } from "hooks/utils/useIsHome";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {

    const { i18n } = useTranslation()

    const [
        NEWSLETTER,
        CGU
    ] = useTranslations(i18nKeys)

    const { lang } = useTranslationLang()

    const boldStyle = useCallback((curentlang: Array<string | undefined>) => {
        return curentlang.includes(lang!) ? "text-primary" : "text-secondary";
    }, [lang])
    
    const { setLang } = useTranslationLang()

    const changeLanguage = (lang?: string) => {
        setLang(lang)
    }

    return (
        <footer className={`relative text-primary flex flex-col sm:flex-row justify-between`}>
            <div className="switch-lang-btns flex gap-[4px] items-center">
                <button onClick={() => changeLanguage('fr')}  className={boldStyle(["fr"])}>
                    FR
                </button>
                <span className="langs-separator w-[23px] h-[1px] bg-primary"></span>
                <button onClick={() => changeLanguage()}  className={boldStyle([undefined, "en"])}>
                    EN
                </button>
            </div>
            <div className={`socials-infos flex justify-between gap-1 sm:gap-5`}>
                <div className="cgu-newsletter flex gap-[20px]">
                    <a href="https://sme.mtl.fm/JainNL#!/connect/form" target="_blank">{NEWSLETTER}</a>
                    <a href="https://www.sonymusic.fr/mentions-legales" target="_blank" style={{whiteSpace: 'nowrap'}}>{CGU}</a>
                </div>
                <div className="socials font-[Bookmania] text-black font-bold flex gap-[10px] justify-between">
                    <a href="https://www.facebook.com/JAINMUSIC" target="_blank">
                        <span className="bg-primary rounded-full w-[27px] h-[27px] flex justify-center items-center">
                            FB
                        </span>
                    </a>
                    <a href="https://www.instagram.com/jainmusic/" target="_blank">
                        <span className="bg-primary rounded-full w-[27px] h-[27px] flex justify-center items-center">
                            IG
                        </span>
                    </a>
                    <a href="https://www.tiktok.com/@jainmusic" target="_blank">
                        <span className="bg-primary rounded-full w-[27px] h-[27px] flex justify-center items-center">
                            TK
                        </span>
                    </a>
                    <a href="https://www.youtube.com/@JainOfficialChannel" target="_blank">
                        <span className="bg-primary rounded-full w-[27px] h-[27px] flex justify-center items-center">
                            YT
                        </span>
                    </a>
                </div>
            </div>
        </footer>
    )
}

const i18nKeys = [
    "App.Footer.NewsLetter",
    "App.Footer.LegalMention"    
]

export default Footer;