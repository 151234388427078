import { CSSProperties, ReactNode, useMemo } from "react"

const CardItem = (props: Props) => {
    const cardWidth = useMemo(() => props.width ? `w-[${props.width}]` : 'w-auto', [])
    const cardHeight = useMemo(() => props.height ? `h-[${props.height}]` : 'h-auto', [])
    const border1 = useMemo(() => props.bigCard ? 'border-[2px] border-solid border-[#ed4e01]' : 'border-[1px] border-solid border-[#ed4e01]', [])
    const border2 = useMemo(() => props.bigCard ? 'border-[3px] border-solid border-[#ed4e01]' : 'border-2 border-solid border-[#ed4e01]', [])
    const bigCardExtra = useMemo(() => props.bigCard ? `${cardWidth} ${cardHeight} ${border1} rounded-[43px] border-[2px] border-solid border-[#ed4e01] py-[10px] px-[12px]` : `border-[1px] border-solid border-[#ed4e01] py-[6px] px-[8px] rounded-[26px]`, [])

    return (
        <div className={`${bigCardExtra} max-w-[312px] ${props.className}`} style={{...props.style}}>
            <div className={`${border2} h-full flex flex-col justify-center items-center ${props.bigCard ? 'rounded-[70px]' : 'rounded-[32px] md:rounded-[42px]'}`}>
                {props.children}
            </div>
        </div>
    )
}

type Props = {
    width?: string | number
    height?: string | number
    bigCard?: boolean
    children: ReactNode
    style?: CSSProperties
    className?: string
}

export default CardItem;