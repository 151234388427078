import { AppRouteProps } from "components/AppRoutes";
import Home from "./containers/Home";
import ThreeD from "./containers/ThreeD";

export const HomeRoutes: Array<AppRouteProps> = [
    {
        path: '',
        title: `App.Home.Title`,
        element: <ThreeD hideSunRay />
    },
    {
        path: 'home',
        title: `App.Home.Title`,
        element: <ThreeD hideSunRay />
    },
    {
        path: 'spotlight',
        title: `App.Home.Title`,
        element: <ThreeD />
    },
]

export const HomeEmptyLayoutRoute: Array<AppRouteProps> = [
    {
        path: 'home-empty-layout',
        title: `App.Home.Title`,
        element: <ThreeD animateCameraOnStart hideSunRay onlyTheFool />
    },
    {
        path: 'home-empty-element',
        title: `App.Home.Title`,
        element: <ThreeD animateCameraOnStart removeImages />
    },
]
