import i18n from "i18n"
import { useCallback, useEffect, useMemo } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

export const useTranslationLang = (): UseTranslationLang  => {

    const navigate = useNavigate()
    const location = useLocation()
    const supportedLanguages = useMemo(() => process.env.REACT_APP_LANG_SUPPORTED?.split(',') || [], [])
    const { lang } = useParams()

    useEffect(() => {
        if ((lang && !supportedLanguages.includes(lang))) {
            setDefaultLang()
            return;
        }
        if (lang) {
            i18n.changeLanguage(lang)
        }
    }, [location])

    const getTranslatedRoute = useCallback((value?: string) => {
        let url = value ? `/${value}` : ''
        if (location.pathname !== '/') {
            url += `/${location.pathname.replace(lang || '', '').replace(/^\/+/, '')}`
        }
        if (location.search) {
            url += `?${location.search}`
        }

        return url
    }, [lang, location])

    const defaultLang = useMemo(() => process.env.REACT_APP_LANG_FALLBACK || supportedLanguages[0], [])
    
    const setLang = useCallback((value?: string) => {
        const url = getTranslatedRoute(value)
        navigate(url, { replace: true })
        i18n.changeLanguage(value || defaultLang)
    }, [lang, getTranslatedRoute])

    const setDefaultLang = useCallback(() => {
        setLang(process.env.REACT_APP_LANG_FALLBACK || supportedLanguages[0])
    }, [setLang])

    return {
        lang,
        setLang,
        getTranslatedRoute
    }
}

export type UseTranslationLang = {
    lang: string | undefined
    setLang: (value?: string) => void
    getTranslatedRoute: (value: string) => string
}
