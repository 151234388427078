import { Swiper, SwiperSlide } from "swiper/react";
import CardItem from "../components/CardItem";
import { TCard, useCardList } from "../hooks/useCardList";
import SwiperClass, { Navigation, EffectCoverflow } from "swiper";
import "swiper/css";
import { useTranslations } from "hooks/translation";
import { useRef, useState } from "react";

const Cartes = () => {
    const cardList = useCardList();
    const [
        CREDIT
    ] = useTranslations(i18n)

    return (
        <section className="flex flex-row flex-1 justify-center items-center w-full">

            <div className="swiper-container  lg:w-[50vw] w-full overflow-auto">
                <Swiper
                    modules={[Navigation, EffectCoverflow]}
                    navigation={{
                        prevEl: ".btn-swiper-prev",
                        nextEl: ".btn-swiper-next",
                    }}
                    effect={"coverflow"}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 0,
                        modifier: 1,
                        scale: 0.85,
                        slideShadows: false,
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        1200: {
                            slidesPerView: 3,
                        },
                    }}
                    centeredSlides={true}
                    loop={true}
                    grabCursor={true}
                    slideNextClass="next-card"
                    slidePrevClass="prev-card"
                >
                    {
                        cardList.map((card: TCard, index) => (
                            <SwiperSlide key={index}>
                                <div className="flex flex-col justify-center items-center md:gap-[20px] gap-[20px] w-full">
                                    <CardItem
                                        srcImg={card.srcImg}
                                        srcVideo={card.srcVideo}
                                        title={card.title}
                                    />
                                    <div className="text-content">
                                        <h3 className="md:text-[22px] text-[16px] mb-2">{card.title}</h3>
                                        {/* <h4 className="capitalize">{card.subtitle}</h4> */}
                                        <p className="leading-[1.3em] md:text-[18px] text-[12px]">
                                            {card.content} <br />
                                            <span className="italic text-xs">{CREDIT}</span>
                                        </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                    <button className="btn-card-prev ">
                        <img
                            src="/assets/img/icons/left-arrow.svg"
                            className="btn-swiper-prev rounded-full md:top-[35%] top-[25%]"
                            alt="previous button"
                        />
                    </button>
                    <button className="btn-card-next">
                        <img
                            src="/assets/img/icons/right-arrow.svg"
                            className="btn-swiper-next rounded-full md:top-[35%] top-[25%]"
                            alt="next button"
                        />
                    </button>
                </Swiper>
            </div>
        </section>
    );
};

const i18n = [
    'App.Cartes.Item.Credits'
]

export default Cartes;
