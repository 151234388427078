import { useBreakpoints } from 'hooks/utils/useBreakpoints';
import { useMemo } from "react";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"

export const useThreeJsControls = ({ camera, renderer }: ThreeJsControlsProps) => {

    const {xs} = useBreakpoints()

    return useMemo(() => {
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.listenToKeyEvents(window); // optional
        controls.minDistance = MIN_DISTANCE
        controls.maxDistance = INITIAL_CAMERA_DISTANCE
        // controls.minPolarAngle = Math.PI / 2
        // controls.maxPolarAngle = Math.PI / 2
        controls.panSpeed = 0.02;
        controls.enableDamping = true;
        controls.dampingFactor = 0.025;  // adjust damping factor to add inertia
        controls.rotateSpeed = 0.2;  // reduce rotation speed
        controls.zoomSpeed = 0.2;  // reduce zoom speed
        controls.maxZoom = 300
        return controls
    }, [])
}


type ThreeJsControlsProps = {
    renderer: THREE.WebGLRenderer,
    camera: THREE.PerspectiveCamera
}


export const MIN_DISTANCE = 100
export const MAX_DISTANCE = 165
export const MAX_DISTANCE_SM = 240
export const INITIAL_CAMERA_DISTANCE = 900

// export const MOBILE_MIN_DISTANCE = 300
// export const MOBILE_MAX_DISTANCE = 800

