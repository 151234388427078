import { useEffect } from 'react';
import { useRef, useState } from 'react';

export const useVideoLoader = () => {

    const videoRef = useRef(null)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        let video = videoRef.current;

        if(!video) return; // Return early if video is null

        // @ts-ignore
        video.style.display = 'none'


        function handleLoadedData() {
            setLoaded(true)
            // @ts-ignore
            video.style.display = 'block'
        }

        // @ts-ignore
        video.addEventListener('loadeddata', handleLoadedData);
        // @ts-ignore
        video.addEventListener('load', handleLoadedData);

        return () => {
            // @ts-ignore
            video.removeEventListener('loadeddata', handleLoadedData);
            // @ts-ignore
            video.removeEventListener('load', handleLoadedData);
        }

    }, [])

    // console.log(video)

    return {
        isLoading: !loaded,
        videoRef
    }
}