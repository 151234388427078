import { useCallback, useRef } from "react"
import { Object3D } from "three"
import { ThreeDAnimationCallbackParams } from "../use3DAnimation"
import { use3DObject } from "../use3DObject"
import { use3DElementFromTexture } from "../use3DTexture"
import { useThreeJsAnimations } from "../useThreeJsAnimations"

export const useElementAstronaute = () => {

    const loadImage = use3DElementFromTexture()
    const getObject = use3DObject()
    const { bounce } = useThreeJsAnimations()

    const element = useRef<Object3D>()

    const astronauteFn = useCallback(async () => {
        const [clouds, astronaute] = await Promise.all([
            loadImage({
                path: '/assets/img/texture/astronaute-1.png',
                width: 20
            }),
            loadImage({
                path: '/assets/img/texture/astronaute-2.png',
                width: 20
            }),
        ])

        const obj = getObject([clouds, astronaute])
        clouds.position.z = 0.9
        
        bounce(clouds.scale, 1.2, 0.8, 8000).start()
        bounce(astronaute.scale, 0.8, 1.2, 8000).start()

        element.current = obj
        return obj

    }, [])

    const animation = useCallback((params?: ThreeDAnimationCallbackParams) => {
        
        element.current?.lookAt(params?.camera.position!)
    }, [])

    return {
        astronauteFn,
        animation
    }
}

const name = 'ASTRONAUTE'
