import { useTranslations } from './../../../hooks/translation/useTranslations';
import { useTranslation } from "react-i18next";
import { ReactNode } from 'react';
import { useMemo } from 'react';
export const useCardList = () => {

    const { i18n } = useTranslation()

    const [
        TITLE1,
        SUBTITLE1,
        CONTENT1,
        TITLE2,
        SUBTITLE2,
        CONTENT2,
        TITLE3,
        SUBTITLE3,
        CONTENT3,
        TITLE4,
        SUBTITLE4,
        CONTENT4,
        TITLE5,
        SUBTITLE5,
        CONTENT5,
        TITLE6,
        SUBTITLE6,
        CONTENT6,
        TITLE7,
        SUBTITLE7,
        CONTENT7,
        TITLE8,
        SUBTITLE8,
        CONTENT8,
        TITLE9,
        SUBTITLE9,
        CONTENT9,
        TITLE10,
        SUBTITLE10,
        CONTENT10,
        TITLE11,
        SUBTITLE11,
        CONTENT11,
    ] = useTranslations(i18nKeys)

    return useMemo(() : Array<TCard> => (
        [
            {
                srcImg: '/assets/img/cards/the-fool.png', 
                srcVideo: '/assets/video/the-fool.mp4',
                title: TITLE1,
                subtitle: SUBTITLE1,
                content: CONTENT1
            },
            {
                srcImg: '/assets/img/cards/the-devil.png', 
                srcVideo: '/assets/video/the-devil.mp4',
                title: TITLE2,
                subtitle: SUBTITLE2,
                content: CONTENT2
            },
            {
                srcImg: '/assets/img/cards/the-lover.png', 
                srcVideo: '/assets/video/the-lover.mp4',
                title: TITLE3,
                subtitle: SUBTITLE3,
                content: CONTENT3
            },
            {
                srcImg: '/assets/img/cards/the-star.jpg', 
                srcVideo: '/assets/video/the-star.mp4',
                title: TITLE4,
                subtitle: SUBTITLE4,
                content: CONTENT4
            },
            {
                srcImg: '/assets/img/cards/the-force.png', 
                srcVideo: '/assets/video/the-force.mp4',
                title: TITLE5,
                subtitle: SUBTITLE5,
                content: CONTENT5
            },
            {
                srcImg: '/assets/img/cards/the-wheel-of-fortune.jpg', 
                srcVideo: '/assets/video/the-wheel-of-fortune.mp4',
                title: TITLE6,
                subtitle: SUBTITLE6,
                content: CONTENT6
            },
            {
                srcImg: '/assets/img/cards/the-house-of-god.png', 
                srcVideo: '/assets/video/the-house-of-god.mp4',
                title: TITLE7,
                subtitle: SUBTITLE7,
                content: CONTENT7
            },
            {
                srcImg: '/assets/img/cards/save-the-world.jpg', 
                srcVideo: '/assets/video/the-world.mp4',
                title: TITLE8,
                subtitle: SUBTITLE8,
                content: CONTENT8
            },
            {
                srcImg: '/assets/img/cards/the-sun.png', 
                srcVideo: '/assets/video/the-sun.mp4',
                title: TITLE9,
                subtitle: SUBTITLE9,
                content: CONTENT9
            },
            {
                srcImg: '/assets/img/cards/the-justice.png', 
                srcVideo: '/assets/video/the-justice.mp4',
                title: TITLE10,
                subtitle: SUBTITLE10,
                content: CONTENT10
            },
            {
                srcImg: '/assets/img/cards/the-chariot.png', 
                srcVideo: '/assets/video/the-chariot.mp4',
                title: TITLE11,
                subtitle: SUBTITLE11,
                content: CONTENT11
            }
        ]
    ), [i18n.language])
}

const i18nKeys = [
    "App.Cartes.Item1.Title",
    "App.Cartes.Item1.SubTitle",
    "App.Cartes.Item1.Content",
    "App.Cartes.Item2.Title",
    "App.Cartes.Item2.SubTitle",
    "App.Cartes.Item2.Content",
    "App.Cartes.Item3.Title",
    "App.Cartes.Item3.SubTitle",
    "App.Cartes.Item3.Content",
    "App.Cartes.Item4.Title",
    "App.Cartes.Item4.SubTitle",
    "App.Cartes.Item4.Content",
    "App.Cartes.Item5.Title",
    "App.Cartes.Item5.SubTitle",
    "App.Cartes.Item5.Content",
    "App.Cartes.Item6.Title",
    "App.Cartes.Item6.SubTitle",
    "App.Cartes.Item6.Content",
    "App.Cartes.Item7.Title",
    "App.Cartes.Item7.SubTitle",
    "App.Cartes.Item7.Content",
    "App.Cartes.Item8.Title",
    "App.Cartes.Item8.SubTitle",
    "App.Cartes.Item8.Content",    
    "App.Cartes.Item9.Title",
    "App.Cartes.Item9.SubTitle",
    "App.Cartes.Item9.Content",    
    "App.Cartes.Item10.Title",
    "App.Cartes.Item10.SubTitle",
    "App.Cartes.Item10.Content",    
    "App.Cartes.Item11.Title",
    "App.Cartes.Item11.SubTitle",
    "App.Cartes.Item11.Content"
]

export type TCard = {
    srcImg: string,
    srcVideo: string,
    title?: string,
    subtitle?: string,
    content?: string,
}