import { useEffect, useMemo } from "react"
import * as THREE from 'three';

export const useThreeJsRayCaster = () => {

    const mousePosition = useMemo(() => new THREE.Vector2(), [])

    useEffect(() => {
        window.addEventListener('mousemove', (e) => {
            mousePosition.x = (e.clientX / window.innerWidth) * 2 - 1;
            mousePosition.y = (e.clientY / window.innerHeight) * 2 - 1;
        })
    }, [])

    const rayCaster = useMemo(() => new THREE.Raycaster(), [])

    return {
        rayCaster,
        mousePosition
    }
}
