import { TAlbum, useAlbumsList } from "../hooks/useAlbumsList";
// @ts-ignore
import Tilt from "react-tilt";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useBreakpoints } from "hooks/utils/useBreakpoints";
import { useMemo } from "react";
import _ from "lodash";

const Albums = () => {
    const albums = useAlbumsList();
    const { xs, sm, md } = useBreakpoints();

    const smAlbums = useMemo(() => {
        const _albums = _.cloneDeep(albums)
        const a = _albums[0]
        _albums[0] = _albums[1]
        _albums[1] = a
        return _albums
    }, [albums])

    return (
        <section className="flex items-center flex-1 justify-center w-full">
            {
                !sm &&
                <div className="container flex md:flex-col lg:flex-row md:gap-[20px] gap-[30px]">
                    {
                        albums.map((album) => <AlbumItem {...album} />)
                    }
                </div>
            }

            {
                sm &&
                <Swiper
                    className="albums flex items-center"
                    modules={[Navigation]}
                    navigation={{
                        prevEl: ".btn-swiper-prev",
                        nextEl: ".btn-swiper-next",
                    }}
                    loop
                    slidesPerView={1}
                >
                    {smAlbums.map((album, index: number) => {
                        return (
                            <SwiperSlide className={"w-full items-center"} key={index}>
                                <AlbumItem {...album} width={sm ? '240px' : album.width} />
                            </SwiperSlide>
                        );
                    })}
                    <button>
                        <img
                            src="/assets/img/icons/left-arrow.svg"
                            className="btn-swiper-prev rounded-full"
                            alt="previous slide button"
                        />
                    </button>
                    <button>
                        <img
                            src="/assets/img/icons/right-arrow.svg"
                            className="btn-swiper-next rounded-full"
                            alt="next slide button"
                        />
                    </button>
                </Swiper>

            }
        </section>
    );
};

const AlbumItem = (props: TAlbum) => {
    const disabled = useMemo(() => props.link === '#', [props])
    return (
        <div
            className={`flex flex-col gap-[20px] items-center animate__animated animate__fadeIn`}
        >
            {!props.isCustom ? (
                <div>
                    <Tilt
                        className="Tilt"
                        options={{ max: 20 }}
                    >
                        <div className="Tilt-inner flex justify-center parallax-bg">
                            <img
                                src={props.srcImg}
                                style={{
                                    width: props.width,
                                }}
                                alt={props.title}
                            />
                        </div>
                    </Tilt>
                </div>
            ) : (
                <div style={{ width: props.width }}>
                    <Tilt
                        className="Tilt flex h-full w-full flex-col justify-center items-center"
                        options={{ max: 20 }}
                    >
                        <div className="Tilt-inner h-full flex flex-col justify-center items-center parallax-bg">
                            <img src={props.srcImg} alt={props.title} className="w-full" />
                        </div>
                    </Tilt>
                </div>
            )}
            <a href={props.link} target={!disabled ? '_blank' : ''}>
                <div className="flex justify-center animated-button">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <button className={`btn-contained w-[98%] ${disabled && 'opacity-70'}`} disabled={disabled}>
                        <p className="font-bold whitespace-nowrap text-[20px]">{props.label}</p>
                    </button>
                </div>
            </a>
        </div>
    );
};

export default Albums;
