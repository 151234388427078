import { Easing } from "@tweenjs/tween.js"
import { useCallback, useRef } from "react"
import { Object3D } from "three"
import { ThreeDAnimationCallbackParams } from "../use3DAnimation"
import { use3DObject } from "../use3DObject"
import { use3DElementFromTexture } from "../use3DTexture"
import { useThreeJsAnimations } from "../useThreeJsAnimations"

export const useElementStarsBlink = () => {

    const loadImage = use3DElementFromTexture()
    const getObject = use3DObject()
    
    const { rotateZ, bounce } = useThreeJsAnimations()

    const element = useRef<Object3D>()

    const planetStarsBlinkLoadFn = useCallback(async () => {
        const [star, starOrbit] = await Promise.all([
            loadImage({
                path: '/assets/img/texture/star-blink-1.svg',
                width: 20
            }),
            loadImage({
                path: '/assets/img/texture/star-blink-2.svg',
                width: 20
            }),
        ])

        starOrbit.position.z = 0.9
        const obj = getObject([star, starOrbit])
        rotateZ({
            rotation: star.rotation,
            angle: Math.PI / 4,
            duration: 20000
        }).start()
        bounce(star.scale, 0.8, 1.2, 5000).start()

        rotateZ({
            rotation: starOrbit.rotation,
            angle: (Math.PI * 2) * 10,
            duration: 90000,
            behavior: Easing.Bounce.InOut
        }).start()
        element.current = obj
        return obj

    }, [])

    const animation = useCallback((params?: ThreeDAnimationCallbackParams) => {
        
        element.current?.lookAt(params?.camera.position!)
    }, [])

    return {
        planetStarsBlinkLoadFn,
        animation
    }
}

const name = 'STAR_BLINK'
