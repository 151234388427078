import { Outlet } from "react-router-dom";

export const EmptyLayout = () => {
    return (
        <>
            <div
                className={`extra-page-wrapper h-screen relative overflow-hidden`}
                style={{
                    backgroundImage: "url(/assets/img/home/bg-nebula.jpg)",
                    backgroundSize: "cover",
                }}
            >
                <div className="w-full relative m-auto h-[max-content] min-h-screen xl:p-0 sm:py-[30px] py-[20px] px-[20px] flex flex-col">
                    <div className={`flex flex-col items-center flex-1 justify-center`}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
}
