import { AppRouteProps } from "components/AppRoutes";
import Concerts from "./containers/Concerts";


export const ConcertRoutes: Array<AppRouteProps> = [
    {
        path: 'concerts',
        title: `App.Concerts.Title`,
        element: <Concerts />
    },
]