import LandscapeBlockedPopup from "components/LandscapeBlockedPopup";
import { useTranslationRoute } from "hooks/translation/useTranslationRoute";
import { useBreakpoints } from "hooks/utils/useBreakpoints";
import { useIsHome } from "hooks/utils/useIsHome";
import { useMobileDetector } from "hooks/utils/useMobileDetector";
import ThreeD from "pages/home/containers/ThreeD";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { FirstNavigateHomeState } from "state/FirstHomeState";
import Footer from "./Footer";
import Header from "./Header";
import { Navigation } from "./Navigation";

const MainLayout = () => {

    const isHome = useIsHome();
    const location = useLocation()
    const [hasFirstNavigateHome, setFirstNavigateHome] = useRecoilState(FirstNavigateHomeState)
    const url = useTranslationRoute()

    const isMobile = useMobileDetector()
    const getOrientation = useCallback(() => {
        if (window.screen?.orientation?.type) {
            return window.screen.orientation.type
        }

        return [90, -90].includes(window.orientation) ? 'landscape' : 'portrait'
    }, [])
    const [orientation, setOrientation] = useState(() => getOrientation())
    const { md } = useBreakpoints()

    useEffect(() => {
        window.addEventListener('orientationchange', handleOrientationChange)
        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange)
        }
    }, [])

    useEffect(() => {
        if (!isHome && hasFirstNavigateHome) {
            setFirstNavigateHome(false)
        }
    }, [location])

    const handleOrientationChange = useCallback((e: any) => {
        setOrientation(getOrientation())
    }, [])

    const menus = useMemo(() => ([
        url(''),
        url('/'),
        url('home'),
        url('menu')
    ]), [url])

    const blockLandscapeView = useMemo(() => {
        if (location.pathname === '/videos') {
            return false
        }

        return isMobile && md && /^landscape/.test(orientation)
    }, [location, isMobile])

    if (blockLandscapeView) {
        return (
            <LandscapeBlockedPopup />
        )
    }

    return (
        <>
            <div
                className={`h-screen relative overflow-x-hidden overflow-y-auto lg:overflow-y-hidden`}
                style={{
                    backgroundImage: "url(/assets/img/home/bg-nebula.jpg)",
                    backgroundSize: "cover",
                }}
            >
                <div className="w-full relative m-auto h-[max-content] min-h-screen sm:py-[30px] py-[20px] px-[20px] flex flex-col gap-2 sm:gap-5 z-50">
                    <Header />
                    {
                        !menus.includes(location.pathname) &&
                        <Navigation />
                    }
                    {
                        !isHome &&
                        <div className={`fixed inset-0 z-[-1]`}>
                            <ThreeD removeImages />
                        </div>
                    }

                    <div className={`flex flex-col items-center flex-1 justify-center ${!isHome && 'z-10'}`}>
                        <Outlet />
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
};


export default MainLayout;
