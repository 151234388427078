import interact from "interactjs";
import { createRef, useEffect, useMemo } from "react";
// import "../css/background.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Parallax, EffectCoverflow, EffectCube } from "swiper";
import "swiper/css/effect-cube";
import BackgroundStarsLayer from "pages/home/components/BackgroundStarsLayer";

export const BackgroundContainerCubeEffect = (props: BackgroundContainerCubeEffectProps) => {
    const workspaceRef = createRef<HTMLDivElement>();
    const zoomSpaceRef = createRef<HTMLDivElement>();
    const svgRefElement = createRef<SVGSVGElement>();
    const extraMaxTranslationRef = createRef<HTMLDivElement>();
    // zoomin and zoomout on mouse wheel state

    const initialScale = 1.7;

    // const [scale, setScale] = useState(initialScale);

    useEffect(() => {
        // initInteract();
    }, []);

    // const initialScale = useMemo(() => {
    //     if (window.outerWidth <= 640) {
    //         return 2;
    //     }

    //     if (window.outerWidth <= 1024) {
    //         return 2;
    //     }

    //     if (window.outerWidth <= 1536) {
    //         return 0.7;
    //     }

    //     return 0.85;
    // }, []);

    const initInteract = () => {
        const position = { x: 0, y: 0 };

        interact(workspaceRef.current as any)
            .draggable({
                listeners: {
                    start(event: any) {
                        // console.log(event.type, event.target);
                    },

                    move(event: any) {
                        position.x += event.dx;
                        position.y += event.dy;

                        if (position.y >= 76) {
                            position.y = 76;
                        }

                        if (position.y <= -76) {
                            position.y = -76;
                        }

                        // if (position.x >= 140) {
                        //     position.x = 140
                        // }

                        // if (position.x <= -190) {
                        //     position.x = -190
                        // }

                        event.target.style.transform = `translateX(${position.x}px`;
                        // (svgRefElement.current as any)!.style.transform = `translateX(${position.x
                        //     }px) scale(${scale - 0.65})`;
                        // (svgRefElement.current as any)!.style.transition = `transform .8s cubic-bezier(0.19, 0.84, 1, 1) 0s`;
                    },
                },
                inertia: {
                    minSpeed: 10,
                    endSpeed: 100,
                    smoothEndDuration: 1000,
                    enabled: true,
                },
                autoScroll: true,
                modifiers: [
                    // interact.modifiers.restrictRect({
                    //     restriction: extraMaxTranslationRef.current as any,
                    // }),
                ],
            })
            .styleCursor(false);
    };

    // zoomin and zoomout on mouse wheel logic
    // const handleWheel = (event: React.WheelEvent) => {
    //     if (!workspaceRef.current) {
    //         return;
    //     }

    //     if (event.deltaY < 0) {
    //         if (scale > 1.7) {
    //             return;
    //         }
    //         setScale(scale + 0.003);
    //     } else {
    //         if (scale < 1.28) {
    //             return;
    //         }
    //         setScale(scale - 0.003);
    //     }
    // };

    const itemDimension = useMemo(() => {
        const width = window.outerWidth;
        return {
            width: width,
            height: (1800 * width) / 7000,
        };
    }, []);

    return (
        <div ref={extraMaxTranslationRef} className="w-full h-full relative">
            <div
                className="w-full h-full"
            >
                <div
                    className="background-container"
                    style={{
                        transition: "transform 0.5s cubic-bezier(0.19, 0.84, 1, 1) 0s",
                    }}
                >
                    <Swiper
                        modules={[
                            Navigation,
                            FreeMode,
                            Parallax,
                            EffectCoverflow,
                            EffectCube,
                        ]}
                        freeMode={true}
                        loop={true}
                        parallax={true}
                        className={`w-full h-full 2xl:scale-[1.7] xl:scale-[3] md:scale-[4] scale-[6]`}
                        // effect="coverflow"
                        // coverflowEffect={{
                        //     rotate: 50,
                        //     stretch: 0,
                        //     depth: 100,
                        //     modifier: 1,
                        //     slideShadows: true,
                        // }}
                        effect="cube"
                        cubeEffect={{
                            slideShadows: true,
                            shadow: true,
                            shadowOffset: 100,
                            shadowScale: 0.94,
                        }}
                        slidesPerView={1}
                        grabCursor={true}
                    >
                        <SwiperSlide
                            style={{
                                alignSelf: "center",
                            }}
                            // className='half-cylinder'
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "calc(100% + 10px)",
                                    height: "100%",
                                    background:
                                        "linear-gradient(90deg, rgba(14,46,79,1) 25%, rgba(0,0,0,1) 46%, rgba(14,46,79,1) 100%)",
                                    // background: 'linear-gradient(90deg, #27348b 8%, #222e6e 46%, #27348b 97%)',
                                    // background: 'rgb(4 25 47)',
                                    zIndex: -1,
                                }}
                            ></div>
                            <div
                                style={{
                                    width: "calc(32%)",
                                    height: "35%",
                                    zIndex: -1,
                                    background: "rgb(14, 46, 79)",
                                    transform: "rotate(47deg)",
                                    boxShadow:
                                        "rgb(14 46 79) 0px 0px 98px 99px, rgba(14,46,79,1) 0px 0px 106px 104px",
                                    borderRadius: "50%",
                                }}
                                className="sm:block hidden absolute md:top-[-16px] top-[98px] md:left-[-47px] left-[36px]"
                            ></div>
                            <div
                                slot="container-start"
                                className="parallax-bg"
                                style={{
                                    // background: 'linear-gradient(90deg, rgba(14,46,79,1) 8%, rgba(0,0,0,1) 46%, rgba(14,46,79,1) 97%)',
                                    // background: 'url("assets/img/home/bg-stars-layer.svg")',
                                    // backgroundFit: 'cover',
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: "100%",
                                    zIndex: -1,
                                }}
                                data-swiper-parallax={"40%"}
                            >
                                <svg
                                    viewBox="0 0 7000 1800"
                                    style={{
                                        width: '100%',
                                        height: "100%",
                                    }}
                                >
                                    <BackgroundStarsLayer />
                                </svg>
                            </div>

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 7000 1800"
                                style={{
                                    width: 'calc(100% + 20px)',
                                    height: "100%",
                                }}
                                // ref={svgRefElement}
                            >
                                {props.children}
                            </svg>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

const items = [-3, -2, -1, 0, 1, 2, 3];

export type BackgroundContainerCubeEffectProps = {
    children?: React.ReactNode;
};
