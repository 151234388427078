import _ from "lodash";
import { useCallback, useMemo } from "react"
import * as THREE from 'three';
import { useThreeJsControls } from "./useThreeJsControls";
import { useThreeJsRayCaster } from "./useThreeJsRayCaster";
import * as TWEEN from '@tweenjs/tween.js';

export const use3DAnimation = ({
    camera,
    renderer,
    scene,
    animations
}: ThreeDAnimationProps) => {

    const { rayCaster, mousePosition } = useThreeJsRayCaster()
    const controls = useThreeJsControls({ camera, renderer })
    const intersects = useMemo<{ objects: Array<any> }>(() => ({
        objects: []
    }), [])

    return {
        controls,
        intersects: intersects,
        animate: () => {
            rayCaster.setFromCamera(mousePosition, camera)
            intersects.objects = rayCaster.intersectObjects(scene.children)
            animations?.forEach((item) => item?.({ camera, scene, renderer, intersects: intersects.objects }))
            TWEEN.update()
            controls.update()
            renderer.render(scene, camera);
        }
    }
}

export type ThreeDAnimationProps = {
    renderer: THREE.WebGLRenderer,
    scene: THREE.Scene,
    camera: THREE.PerspectiveCamera
    starsNumber?: number
    animations?: Array<ThreeDAnimationCallback>
}

type ThreeDAnimationCallback = (params?: ThreeDAnimationCallbackParams) => void

export type ThreeDAnimationCallbackParams = ThreeDAnimationProps & { intersects: THREE.Intersection<THREE.Object3D<THREE.Event>>[] }
