import { ExteriorAstersLayer } from "pages/home/components/ExteriorAstersLayer"
import { InteriorAstersLayer } from "pages/home/components/InteriorAstersLayer"
import { StarsMiddleLayer } from "pages/home/components/StarsMiddleLayer"
import { TheFoolLayer } from "pages/home/components/TheFoolLayer"
import { BackgroundContainerCubeEffect } from "./components/BackgroundContainerCubeEffect"

const Cube = () => {
    
    return (
        <div className="absolute inset-0 left-[-35px] right-[-35px] flex justify-center items-center">
            <BackgroundContainerCubeEffect>
                <StarsMiddleLayer x='10%' _key={1}/>
                <StarsMiddleLayer x='57%' y='4%' _key={2}/>
                <StarsMiddleLayer x='21%' y='59%' _key={3}/>
                <StarsMiddleLayer x='70%' y='57%' _key={4}/>
                <ExteriorAstersLayer />
                <InteriorAstersLayer />
                <TheFoolLayer />
            </BackgroundContainerCubeEffect>
        </div>
    )
}

export default Cube
