import { useBreakpoints } from 'hooks/utils/useBreakpoints';
import { useCallback, useRef } from "react"
import { Object3D } from "three"
import { ThreeDAnimationCallbackParams } from "../use3DAnimation"
import { use3DObject } from "../use3DObject"
import { use3DElementFromTexture } from "../use3DTexture"
import { useThreeJsAnimations } from "../useThreeJsAnimations"

export const useElementSun = () => {

    const loadImage = use3DElementFromTexture()
    const getObject = use3DObject()
    const {bounce} = useThreeJsAnimations()
    const {sm} = useBreakpoints()

    const element = useRef<Object3D>()

    const sunLoadFn = useCallback(async () => {
        const [sun] = await Promise.all([
            loadImage({
                path: '/assets/img/icons/sun.svg',
                width: sm ? 60 : 100
            }),
        ])

        const obj = getObject([sun])
        bounce(sun.scale, 0.9, 1.08, 3500).start()

        element.current = obj
        return obj

    }, [])

    const animation = useCallback((params?: ThreeDAnimationCallbackParams) => {
        
        element.current?.lookAt(params?.camera.position!)
    }, [])

    return {
        sunLoadFn,
        animation
    }
}

const name = 'SUN'
