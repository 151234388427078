import { useCallback, useRef } from "react"
import { Object3D } from "three"
import { ThreeDAnimationCallbackParams } from "../use3DAnimation"
import { use3DObject } from "../use3DObject"
import { use3DElementFromTexture } from "../use3DTexture"
import { useThreeJsAnimations } from "../useThreeJsAnimations"

export const useElementAngel = () => {

    const loadImage = use3DElementFromTexture()
    const getObject = use3DObject()
    const { rotateZ, bounce } = useThreeJsAnimations()

    const element = useRef<Object3D>()

    const angelFn = useCallback(async () => {
        const [angel, redRose, yellowRose] = await Promise.all([
            loadImage({
                path: '/assets/img/texture/angel-1.png',
                width: 15
            }),
            loadImage({
                path: '/assets/img/texture/angel-2.png',
                width: 15
            }),
            loadImage({
                path: '/assets/img/texture/angel-3.png',
                width: 15
            }),
        ])

        redRose.position.z = 0.9
        yellowRose.position.z = 0.95
        const obj = getObject([angel, redRose, yellowRose])
        rotateZ({
            rotation: angel.rotation,
            angle: Math.PI / 4,
            duration: 15000,
        }).start()
        bounce(angel.scale, 0.8, 1.3, 5000).start()

        rotateZ({
            rotation: redRose.rotation,
            angle: (Math.PI / 6),
            duration: 90000
        }).start()
        bounce(angel.scale, 0.8, 1.2, 10000).start()

        rotateZ({
            rotation: yellowRose.rotation,
            angle: (Math.PI / 6) * -1,
            duration: 90000
        }).start()
        bounce(angel.scale, 0.8, 1.2, 15000).start()

        element.current = obj
        return obj

    }, [])

    const animation = useCallback((params?: ThreeDAnimationCallbackParams) => {
        
        element.current?.lookAt(params?.camera.position!)
    }, [])

    return {
        angelFn,
        animation
    }
}

const name = 'ASTRONAUTE'
