import { screen } from '@testing-library/react';
import { useCallback } from "react";
import { Tween } from "@tweenjs/tween.js";
import * as TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';

export const useThreeJsAnimations = () => {

    const translate = useCallback(({
        position,
        targetUp,
        targetDown,
        behavior,
        duration = 1000
    }: TranslationParams) => {

        const translateUp = new Tween(position!)
            .to(targetUp!, duration)
            .easing(behavior || TWEEN.Easing.Linear.None)
            .onComplete(function () {
                // start the second tween after the first one is complete
                translateDown.start();
            });

        const translateDown = new Tween(position!)
            .to(targetDown!, duration)
            .easing(behavior || TWEEN.Easing.Linear.None)
            .onComplete(function () {
                // start the second tween after the first one is complete
                translate({ position, targetDown, targetUp, behavior, duration })
                    .start()
            });

        return translateUp
    }, [])

    const translateUp = useCallback(({
        position,
        targetUp,
        behavior,
        duration = 1000
    }: TranslationParams) => {

        const translateUp = new Tween(position!)
            .to(targetUp!, duration)
            .easing(behavior || TWEEN.Easing.Linear.None)
        return translateUp
    }, [])

    const fadeInOut = useCallback(({
        material,
        maxOpacity = 1,
        minOpacity = 0,
        duration = 1000
    }: FadeInFadeOutParams) => {
        const fadeOut = new Tween(material)
            .to({ opacity: minOpacity }, duration)
            .easing(TWEEN.Easing.Linear.None)
            .onComplete(function () {
                // start the second tween after the first one is complete
                fadeIn.start();
            });

        const fadeIn = new Tween(material)
            .to({ opacity: maxOpacity }, duration)
            .easing(TWEEN.Easing.Linear.None)
            .onComplete(function () {
                // start the second tween after the first one is complete
                fadeOut.start();
            });

        return fadeOut
    }, [])

    const rotateZ = useCallback(({ rotation, angle = 0.001, duration = 1000 }: RotateParams) => {
        const rotate = new Tween(rotation)
            .to({ z: angle }, duration)
            .easing(TWEEN.Easing.Linear.None)
            .onComplete(function () {
                rotateZ({ rotation, angle: angle * -1, duration: duration }).start()
            })

        return rotate;
    }, [])

    const rotateX = useCallback(({ rotation, angle = 0.001, duration = 1000, behavior }: RotateParams) => {
        const rotate = new Tween(rotation)
            .to({ x: angle }, duration)
            .easing(behavior || TWEEN.Easing.Linear.None)
            .onComplete(function () {
                rotateX({ rotation, angle: angle * -1, duration: duration }).start()
            })

        return rotate;
    }, [])

    const rotateY = useCallback(({ rotation, angle = 0.001, duration = 1000, behavior }: RotateParams) => {
        const rotate = new Tween(rotation)
            .to({ y: angle }, duration)
            .easing(behavior || TWEEN.Easing.Linear.None)
            .onComplete(function () {
                rotateY({ rotation, angle: angle * -1, duration: duration }).start()
            })

        return rotate;
    }, [])


    const bounceUp = (position: THREE.Vector3, translate: number = 2, duration: number = 1000) => {
        return new TWEEN.Tween(position)
            .to({ x: translate, y: translate }, duration)
            .easing(TWEEN.Easing.Linear.None)
    }

    const bouceDown = (position: THREE.Vector3, translate: number = 0, duration: number = 1000) => {
        return new TWEEN.Tween(position)
            .to({ z: translate }, duration)
            .easing(TWEEN.Easing.Linear.None)
    }

    const bounce = useCallback((scale: THREE.Vector3, down: number = 0, up: number = 1, duration: number = 4000) => {

        const _bounceDown = new Tween(scale)
            .to({ x: down, y: down }, duration)
            .easing(TWEEN.Easing.Linear.None)
            .onComplete(function () {
                // start the second tween after the first one is complete
                _bounceUp.start();
            });

        const _bounceUp = new TWEEN.Tween(scale)
            .to({ x: up, y: up }, duration)
            .easing(TWEEN.Easing.Linear.None)
            .onComplete(function () {
                bounce(scale, down, up, duration).start()
            });

        return _bounceDown
    }, [])

    return {
        fadeInOut,
        bounce,
        bounceUp,
        bouceDown,
        rotateZ,
        rotateX,
        rotateY,
        translate,
        translateUp
    }
}

type RotateParams = {
    rotation: THREE.Euler,
    angle?: number,
    duration?: number
    behavior?: any
}

type FadeInFadeOutParams = {
    material: THREE.MeshBasicMaterial,
    minOpacity?: number
    maxOpacity?: number
    duration?: number
}

type TranslationParams = {
    position: THREE.Vector3
    targetUp: Partial<THREE.Vector3>
    targetDown: Partial<THREE.Vector3>
    duration?: number
    behavior?: any
}

