import { useIsHome } from 'hooks/utils/useIsHome';
import { useBreakpoints } from 'hooks/utils/useBreakpoints';
import { use3DStars } from "./use3DStars"
import { use3DRandomPlanets } from './use3DRandomPlanets';


export const use3DElements = (removeImages?: boolean) => {
    const {md} = useBreakpoints()
    const isHome = useIsHome()

    // STARS
    const starsTickingLimit1 = {
        min: md ? 10 : 50,
        max: md ? 15 : 150
    }
    const starsTickingLimit2 = {
        min: md ? 20 : 100,
        max: md ? 30 : 500
    }
    const starsTickingLimit3 = {
        min: md ? isHome ? 5 : 0 : 20,
        max: md ? isHome ? 10 : 0 : 100
    }

    // PLANETS
    const size = {
        min: md ? 3 : 4,
        max: md ? 5 : 8
    }

    const planet1 = {
        min: md ? 5 : 20,
        max: md ? 15 : 50
    }

    const { elements: starsElements, animation: starsAnimations } = use3DStars(200, starsTickingLimit1, starsTickingLimit2, starsTickingLimit3 )
    const { elements: planetsElements, animation: planetsAnimations } = use3DRandomPlanets(size, planet1)


    return {
        elements: [
            ...starsElements,
            ...planetsElements
        ],
        animations: [
            starsAnimations,
            planetsAnimations
        ]
    }
}

