import CardItem from "components/CardItem";
import { useTranslations } from "hooks/translation";
import { useTranslationRoute } from "hooks/translation/useTranslationRoute";
import { useBreakpoints } from "hooks/utils/useBreakpoints";
import { useIsHome } from "hooks/utils/useIsHome";
import CardMenuItem from "pages/home/components/CardMenuItem";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {

    const [MENU_BTN, BACK_BTN] = useTranslations(i18nKeys)
    const { sm, md } = useBreakpoints()

    const isHome = useIsHome();
    const location = useLocation()
    const url = useTranslationRoute()
    const isMenuPage = useMemo(() => location.pathname == '/menu', [location])

    return (
        <header className="relative z-50 flex px-3 md:px-5 justify-center items-center text-secondary">
            <div className="logo-jain flex flex-col gap-3 items-center">
                <Link to={url("")}><img src="/assets/img/logo.png" alt="Jain logo" style={{ height: md ? '60px' : '80px' }} /></Link>
                {
                    isHome &&
                    <Link to={url("menu")}>
                        <CardItem>
                            <div className="px-4 sm:px-6 py-1">
                                <h4 className="capitalize font-bold text-primary text-sm md:text-lg">{MENU_BTN}</h4>
                            </div>
                        </CardItem>
                    </Link>
                }
            </div>
            {
                !isHome &&
                <div className="menu-sun-animation absolute right-0">
                    <Link to={!isMenuPage ? '/menu' : '/'}>
                        <div className="menus-btn flex justify-center items-center">
                            <img src="/assets/img/moon-menu.png" style={{ height: md ? '60px' : '80px' }} alt="Menu" className="z-[-1]" />
                            <h5 className="absolute" style={{ fontSize: '18px' }}>{!isMenuPage ? MENU_BTN : BACK_BTN}</h5>
                        </div>
                    </Link>
                </div>
            }
        </header>
    )
}

const i18nKeys = [
    "App.Header.MenuButton",
    "App.Header.BackButton"
]

export default Header;