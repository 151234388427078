import { AppRouteProps } from "components/AppRoutes";
import Menu from "./containers/Menu";
// const Menu = React.lazy(() => import('./containers/Menu'))

export const MenuRoutes: Array<AppRouteProps> = [
    {
        path: 'menu',
        title: `App.Menu.Title`,
        element: <Menu />
    },
]