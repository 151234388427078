import { useCallback, useRef } from "react"
import { Mesh, Object3D } from "three"
import { ThreeDAnimationCallbackParams } from "../use3DAnimation"
import { use3DMesh } from "../use3DMesh"
import { use3DObject } from "../use3DObject"
import { use3DElementFromTexture } from "../use3DTexture"
import { useThreeJsAnimations } from "../useThreeJsAnimations"

export const useElementPlanetTwins = () => {

    const loadImage = use3DElementFromTexture()
    const getObject = use3DObject()
    const { rotateZ, bounce, translate } = useThreeJsAnimations()

    const element = useRef<Object3D>()

    const planetTwinsLoadFn = useCallback(async () => {
        const [planetLeft, planetRight] = await Promise.all([
            loadImage({
                path: '/assets/img/texture/planet-twins-1.png',
                width: 25
            }),
            loadImage({
                path: '/assets/img/texture/planet-twins-2.png',
                width: 25
            }),
        ])

        const obj = getObject([planetLeft, planetRight])
        planetLeft.position.z = 0.9
        translate({
            position: planetLeft.position,
            targetDown: { x: 2, y: -2 },
            targetUp: { x: -1, y: 1 },
            duration: 7000
        }).start()
        rotateZ({
            rotation: planetLeft.rotation,
            angle: (Math.PI / 10),
            duration: 10000
        }).start()

        translate({
            position: planetRight.position,
            targetUp: { x: 2, y: -2 },
            targetDown: { x: -1, y: 1 },
            duration: 7000
        }).start()
        rotateZ({
            rotation: planetRight.rotation,
            angle: (Math.PI / 12) * -1,
            duration: 10000
        }).start()

        element.current = obj
        return obj

    }, [])

    const animation = useCallback((params?: ThreeDAnimationCallbackParams) => {
        
        element.current?.lookAt(params?.camera.position!)
    }, [])

    return {
        planetTwinsLoadFn,
        animation
    }
}

const name = 'PLANET_TWINS'
