import _ from "lodash";
import { useCallback, useMemo } from "react";
import * as THREE from 'three';
import { ThreeDAnimationProps } from "./use3DAnimation";
import { useThreeJsAnimations } from "./useThreeJsAnimations";
import { MAX_DISTANCE, MAX_DISTANCE_SM } from "./useThreeJsControls";

export const use3DStars = (starsNumber = 150, starsTickingLimit1 = {min: 25, max: 100}, starsTickingLimit2 = {min: 50, max: 200}, starsTickingLimit3 = {min: 20, max: 60}) => {

    const getRandomPosition = () => {
        return {
            x: _.random((window.innerWidth) * -1, (window.innerWidth)),
            y: _.random((window.innerHeight) * -1, (window.innerHeight)),
            z: _.random((MAX_DISTANCE_SM) * -1, (MAX_DISTANCE_SM))
        }
    }

    const { fadeInOut } = useThreeJsAnimations()

    const getCloserRandomPosition = () => {
        const width = window.innerWidth / 4
        const height = window.innerWidth / 4

        return {
            x: _.random(width * -1, (width)),
            y: _.random(height * -1, (height)),
            z: _.random(height * -1, (height))
        }
    }

    const getStar = useCallback(() => {
        const material = new THREE.MeshBasicMaterial({
            color: '#f3f3a9',
        })
        const geometry = new THREE.SphereGeometry(0.8, 5, 5);
        const mesh = new THREE.Mesh(geometry, material);
        const position = getRandomPosition()
        mesh.position.set(position.x, position.y, position.z).normalize();
        mesh.position.multiplyScalar(90 + (Math.random() * 700));
        mesh.rotation.set(Math.random() * 2, Math.random() * 2, Math.random() * 2);
        return mesh
    }, [])

    const getStarTexture = useCallback((texture?: THREE.Texture, tick?: boolean) => {
        const geometry = new THREE.PlaneGeometry(5, 5);
        const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true });
        const mesh = new THREE.Mesh(geometry, material);
        const position = getCloserRandomPosition()
        mesh.position.set(position.x, position.y, position.z);
        if (tick !== false) {
            fadeInOut({
                material,
                duration: _.random(1000, 2500)
            }).start()
        }
        return mesh
    }, [])

    const starsRotatorRight = useMemo(() => {
        const particle = new THREE.Object3D();
        for (var i = 0; i < starsNumber; i++) {
            const star = getStar()
            particle.add(star)
        }
        return particle
    }, [])

    const starsRotatorLeft = useMemo(() => {
        const particle = new THREE.Object3D();
        for (var i = 0; i < starsNumber; i++) {
            const star = getStar()
            particle.add(star)
        }
        return particle
    }, [])

    const starsTicking1 = useMemo(() => {
        var texture = new THREE.TextureLoader().load('/assets/img/texture/star-5.svg');
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        const particle = new THREE.Object3D();
        const limit = _.random(starsTickingLimit1.min, starsTickingLimit1.max)
        for (var i = 0; i < limit; i++) {
            const star = getStarTexture(texture)
            particle.add(star)
        }
        return particle
    }, [])

    const starsTicking2 = useMemo(() => {
        var texture = new THREE.TextureLoader().load('/assets/img/texture/star-6.svg');
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        const particle = new THREE.Object3D();
        const limit = _.random(starsTickingLimit2.min, starsTickingLimit2.max)
        for (var i = 0; i < limit; i++) {
            const star = getStarTexture(texture)
            particle.add(star)
        }
        return particle
    }, [])

    const starsTicking3 = useMemo(() => {
        var texture = new THREE.TextureLoader().load('/assets/img/texture/star-3.svg');
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        const particle = new THREE.Object3D();
        const limit = _.random(starsTickingLimit3.min, starsTickingLimit3.max)
        for (var i = 0; i < limit; i++) {
            const star = getStarTexture(texture, false)
            particle.add(star)
        }
        return particle
    }, [])

    const animation = useCallback((params?: ThreeDAnimationProps) => {
        starsRotatorRight.rotateY(0.002)
        starsRotatorLeft.rotateY(-0.0009)
        starsTicking1.children.forEach((item) => {
            item.lookAt(params?.camera.position!)
        })
        starsTicking2.children.forEach((item) => {
            item.lookAt(params?.camera.position!)
        })

        starsTicking3.children.forEach((item) => {
            item.lookAt(params?.camera.position!)
        })
    }, [])

    return {
        elements: [starsRotatorRight, starsRotatorLeft, starsTicking1, starsTicking2, starsTicking3],
        animation
    }
}
