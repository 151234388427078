import { useTranslations } from "hooks/translation";

const LandscapeBlockedPopup = () => {
    const [LANDSCAPE_BLOCKED] = useTranslations(i18nKeys);
        

    return (
        <div
            className={`fixed z-[9999] inset-0 flex justify-center items-center text-[#a79fc9]`}
            style={{
                backgroundImage: "url(/assets/img/home/bg-nebula.jpg)",
                backgroundSize: "cover",
                animationDelay: "2000ms",
            }}
        >
            <div className="w-[80vmin] text-center flex flex-col justify-center items-center">
                <h2
                    className={`animate__animated animate__fadeIn`}
                    style={{
                        animationDelay: "300ms",
                    }}
                >
                    <span 
                        className="whitespace-nowrap" 
                        style={{ fontFamily: 'KCObraLetra' }}
                        dangerouslySetInnerHTML={{ __html: LANDSCAPE_BLOCKED }}
                        />
                </h2>
            </div>
        </div>
    );
};

const i18nKeys = [
    "App.Home.LandscapeBlocked.Message",
];

type Props = {
    isLoading?: boolean
    onFading?: () => void
}

export default LandscapeBlockedPopup;
