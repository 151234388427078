import { useTranslations } from "hooks/translation"
import { HTMLAttributes } from "react"

export const ThreeDTips = (props: ThreeDTipsProps) => {

    const [
        TIPS
    ] = useTranslations(i18n)

    return (
        <div
            className={`fixed z-[99999] inset-0 flex bg-[#0008] animate__animated animate__fadeIn`}
            {...props}
        >
            <div className="text-center flex flex-col m-auto gap-10">
                <h2 
                    className="text-[#a79fc9]"
                    style={{
                        animationDelay: "300ms",
                    }}>
                    <span style={{ fontFamily: 'KCObraLetra' }}> {TIPS}</span>
                </h2>
                <div 
                    className="m-auto animate__animated animate__fadeIn"
                    style={{
                        animationDelay: "600ms",
                    }}>
                    <img
                        src="/assets/img/icons/hand-move.png"
                        alt="hand move"
                        className="h-10 animate__animated animate__wobble animate__infinite"
                        style={{ animationDuration: '10000ms' }}
                    />
                </div>
            </div>
        </div>
    )
}

export type ThreeDTipsProps = HTMLAttributes<HTMLDivElement> & {
    onClick?: () => void
}

const i18n = [
    'App.Home.Tips.message'
]
