import { useCallback, useRef } from "react"
import { Object3D } from "three"
import { ThreeDAnimationCallbackParams } from "../use3DAnimation"
import { use3DObject } from "../use3DObject"
import { use3DElementFromTexture } from "../use3DTexture"
import { useThreeJsAnimations } from "../useThreeJsAnimations"

export const useElementMoonSun = () => {

    const loadImage = use3DElementFromTexture()
    const getObject = use3DObject()
    const { rotateX, bounce, rotateZ, fadeInOut } = useThreeJsAnimations()

    const element = useRef<Object3D>()

    const planetMoonSunFn = useCallback(async () => {
        const [moon, sun] = await Promise.all([
            loadImage({
                path: '/assets/img/texture/moon-sun-1.png',
                width: 20
            }),
            loadImage({
                path: '/assets/img/texture/moon-sun-2.png',
                width: 20
            }),
        ])

        const obj = getObject([moon, sun])
        sun.position.z = 0.9

        rotateZ({
            rotation: moon.rotation,
            angle: (Math.PI / 8) * -1,
            duration: 8000
        }).start()
        
        rotateZ({
            rotation: sun.rotation,
            angle: Math.PI / 8,
            duration: 8000
        }).start()

        element.current = obj
        return obj

    }, [])

    const animation = useCallback((params?: ThreeDAnimationCallbackParams) => {
        
        element.current?.lookAt(params?.camera.position!)
    }, [])

    return {
        planetMoonSunFn,
        animation
    }
}

const name = 'MOON_SUN'
