import { useTranslation } from 'react-i18next';
import { useTranslations } from 'hooks/translation';
import { useMemo } from 'react';
export const useAlbumsList = () => {

    const [
        ZANAKA,
        THE_FOOL,
        SOULDIER
    ] = useTranslations(i18nKeys);

    const { i18n } = useTranslation();

    return useMemo(() : Array<TAlbum> => (
        [
            {
                link: 'https://jain.lnk.to/Zanaka', 
                label: ZANAKA,
                title: 'Zanaka album',
                srcImg: '/assets/img/albums/jain-zanaka-cover.jpg',
                width: '250px',
            },
            {
                link: 'https://jain.lnk.to/THEFOOLALBUMSITE', 
                label: THE_FOOL,
                srcImg: '/assets/img/albums/jain-the-fool-cover.jpg',
                title: 'Jain the fool cover',
                width: '335px',
                height: '360px',
                isCustom: true,
            },
            {
                link: 'https://jain.lnk.to/Souldier', 
                label: SOULDIER,
                srcImg: '/assets/img/albums/jain-souldier-cover.jpg',
                title: 'Jain souldier cover',
                width: '250px',
            }
        ]
    ), [i18n.language])
}


const i18nKeys = [
    "App.Menu.Albums.ListenToZanaka",
    "App.Menu.Albums.ListenToTheFool",
    "App.Menu.Albums.ListenToSouldier",
];

export type TAlbum = {
    link: string,
    label: string,
    srcImg?: string,
    title?: string,
    width?: string,
    height?: string,
    isCustom?: boolean
}