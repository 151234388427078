import i18n from "i18n"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useTranslationLang } from "./useTranslationLang"

export const useTranslationRoute = () => {
    
    const { lang } = useTranslationLang()

    return useCallback((route: string) => {
        if (!lang) {
            return `/${route}`.replace(/\/$/, '')
        }
        return `/${lang}/${route}`.replace(/\/$/, '')
    }, [lang])
}
