import { AppRouteProps } from "components/AppRoutes";
import { EmptyLayout } from "components/layout/EmptyLayout";
import { LangInitiator } from "components/layout/LangInitiator";
import { Outlet } from "react-router-dom";
import MainLayout from "../components/layout/MainLayout";
import { AlbumsRoutes } from "./albums/AlbumsRoutes";
import { CartesRoutes } from "./cartes/CartesRoutes";
import { ClipsRoutes } from "./clips/ClipsRoutes";
import { ConcertRoutes } from "./concerts/ConcertRoutes";
import { CubeRoutes } from "./cube/CubeRoutes";
import { HomeEmptyLayoutRoute, HomeRoutes } from "./home/HomeRoutes";
import { MenuRoutes } from "./menu/MenuRoutes";
import { PicturesRoutes } from "./pictures/PicturesRoutes";
import { ShopRoutes } from "./shop/ShopRoutes";

export const JainUiRoutes: Array<AppRouteProps> = [
    {
        path: ':lang',
        title: `App.title`,
        element: <Outlet />,
        children: [
            {
                path: '',
                element: <MainLayout />,
                children: [
                    ...HomeRoutes,
                    ...MenuRoutes,
                    ...ShopRoutes,
                    ...ClipsRoutes,
                    ...ConcertRoutes,
                    ...AlbumsRoutes,
                    ...CartesRoutes,
                    ...PicturesRoutes,
                    ...CubeRoutes
                ]
            },
            {
                path: '',
                element: <EmptyLayout />,
                children: [
                    ...HomeEmptyLayoutRoute,
                ]
            },
        ]
    },
    {
        path: '',
        element: <MainLayout />,
        children: [
            ...HomeRoutes,
            ...MenuRoutes,
            ...ShopRoutes,
            ...ClipsRoutes,
            ...ConcertRoutes,
            ...AlbumsRoutes,
            ...CartesRoutes,
            ...PicturesRoutes,
            ...CubeRoutes
        ]
    },
    {
        path: '',
        element: <EmptyLayout />,
        children: [
            ...HomeEmptyLayoutRoute,
        ]
    },
]
