import { useCallback } from "react";
import * as THREE from 'three';

export const use3DMesh = () => {

    return useCallback((name?: string) => {
        var geometry = new THREE.PlaneGeometry(0, 0);
        const material = new THREE.MeshBasicMaterial({ transparent: true});
        var plane = new THREE.Mesh(geometry, material);
        plane.name = name || ''
        return plane
    }, [])
}
