import { useRouterMetadataSync } from "hooks/url/useRouterMetadataSync"
import { AppRouteProps, AppRoutes } from "./AppRoutes"

export const AppShell = (props: AppShellProps) => {

    useRouterMetadataSync()

    return (
        <AppRoutes routes={props.routes} />
    )
}

export type AppShellProps = {
    routes: Array<AppRouteProps>
    /** 
     * Routes that authorized when user is logged 
     */
    protectedRoutes?: Array<string> | '*',
    /** Initial routes */
    initialPath?: string
    /** Path where redirect if current route is unAuthorized */
    loginPath?: string
}

