import { useVideoLoader } from "pages/cartes/hooks/useVideoLoader";
import { VideoSkeleton } from "./VideoSkeleton";

export const YoutubeVideo = ({ video }: any) => {

    const { isLoading, videoRef } = useVideoLoader()

    return (
        <div className="lg:w-[368px] md:h-[250px] md:w-[310px] h-[300px] xs:w-[350px]">
            {
                isLoading && <VideoSkeleton src={video?.snippet.thumbnails.high.url} title={video?.snippet.title} />
            }
            <iframe
                ref={videoRef}
                className="animate__animated animate__fadeIn"
                width="100%"
                height="100%"
                src={`https://www.youtube-nocookie.com/embed/${video?.snippet.resourceId.videoId}`}
                title={video?.snippet.title}
                allowFullScreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        </div>
    );
};