import { AppShell } from 'components/AppShell';
import { LangInitiator } from 'components/layout/LangInitiator';
import { JainUiRoutes } from 'pages/JainUiRoutes';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MutableSnapshot, RecoilRoot } from 'recoil';
import { RoutesState } from 'state/RoutesState';
import './i18n'

function App() {

    const initializeState: (mutableSnapshot: MutableSnapshot) => void = ({ set }) => {
        set(RoutesState, JainUiRoutes)
    }

    return (
        <RecoilRoot initializeState={initializeState}>
            <BrowserRouter>
                <LangInitiator />
                <AppShell routes={JainUiRoutes} />
            </BrowserRouter>
        </RecoilRoot>
    )
}

export default App;
