import { Easing } from "@tweenjs/tween.js"
import { useCallback, useRef } from "react"
import { Object3D } from "three"
import { ThreeDAnimationCallbackParams } from "../use3DAnimation"
import { use3DObject } from "../use3DObject"
import { use3DElementFromTexture } from "../use3DTexture"
import { useThreeJsAnimations } from "../useThreeJsAnimations"

export const useElementPlanetMissile = () => {

    const loadImage = use3DElementFromTexture()
    const getObject = use3DObject()
    const { rotateZ, bounce } = useThreeJsAnimations()

    const element = useRef<Object3D>()

    const planetMissileFn = useCallback(async () => {
        const [violetPlanet, violetPlanetMissile, pinkPlanet, pinkPlanetMissile] = await Promise.all([
            loadImage({
                path: '/assets/img/texture/planet-missile-1.png',
                width: 20
            }),
            loadImage({
                path: '/assets/img/texture/planet-missile-2.png',
                width: 20
            }),
            loadImage({
                path: '/assets/img/texture/planet-missile-3.png',
                width: 20
            }),
            loadImage({
                path: '/assets/img/texture/planet-missile-4.png',
                width: 20
            }),
        ])

        const violetPlanetWrapper = getObject([violetPlanet, violetPlanetMissile])
        violetPlanetWrapper.position.x = -5
        violetPlanetWrapper.position.y = 10
        violetPlanetWrapper.position.z = 0.7
        violetPlanetMissile.position.z = 1.1

        bounce(violetPlanet.scale, 0.8, 1.2, 5000).start()
        rotateZ({
            rotation: violetPlanetMissile.rotation,
            angle: (Math.PI * 2) * 100,
            duration: 800000,
            behavior: Easing.Bounce.InOut
        }).start()
        
        const pinkPlanetWrapper = getObject([pinkPlanet, pinkPlanetMissile])
        pinkPlanetWrapper.position.x = 5
        pinkPlanetWrapper.position.y = -5
        pinkPlanetMissile.position.z = 1.1

        rotateZ({
            rotation: pinkPlanetMissile.rotation,
            angle: (Math.PI * 2) * 100,
            duration: 1000000,
            behavior: Easing.Bounce.InOut
        }).start()
        bounce(pinkPlanet.scale, 0.7, 1.1, 6000).start()

        const obj = getObject([violetPlanetWrapper, pinkPlanetWrapper])

        element.current = obj
        return obj
    }, [])

    const animation = useCallback((params?: ThreeDAnimationCallbackParams) => {
        
        element.current?.lookAt(params?.camera.position!)
    }, [])

    return {
        planetMissileFn,
        animation
    }
}

const name = 'ASTRONAUTE'
