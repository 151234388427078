import { useCallback } from "react"
import * as THREE from 'three';

export const use3DObject = () => {

    return useCallback((el?: Array<THREE.Object3D>) => {
        const obj = new THREE.Object3D()
        el && obj.add(...el)
        return obj
    }, [])
}
