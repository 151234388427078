import { Easing } from "@tweenjs/tween.js"
import { useCallback, useRef } from "react"
import { Object3D } from "three"
import { ThreeDAnimationCallbackParams } from "../use3DAnimation"
import { use3DMesh } from "../use3DMesh"
import { use3DObject } from "../use3DObject"
import { use3DElementFromTexture } from "../use3DTexture"
import { useThreeJsAnimations } from "../useThreeJsAnimations"

export const useElementPlanetExplosion = () => {

    const loadImage = use3DElementFromTexture()
    const getObject = use3DObject()
    const getMesh = use3DMesh()
    const { rotateZ } = useThreeJsAnimations()
    const element = useRef<Object3D>()


    const planetLoadFn = useCallback(async () => {
        const [planetInterior, planetExterior] = await Promise.all([
            loadImage({
                path: '/assets/img/texture/planet-explosion-1.png',
                width: 30
            }),
            loadImage({
                path: '/assets/img/texture/planet-explosion-2.png',
                width: 30
            })
        ])
        
        const obj = getObject([planetInterior, planetExterior])
        rotateZ({
            rotation: planetInterior.rotation,
            angle: Math.PI * 2 * -1,
            duration: 40000
        }).start()

        planetInterior.position.z = 1.1
        rotateZ({
            rotation: planetExterior.rotation,
            angle: (Math.PI * 2) * 10,
            duration: 400000,
            behavior: Easing.Bounce.InOut
        }).start()
        element.current = obj
        return obj
    }, [])

    const animation = useCallback((params?: ThreeDAnimationCallbackParams) => {
        element.current?.lookAt(params?.camera.position!)
    }, [element.current])


    return {
        planetLoadFn,
        animation
    }
}

const name = 'PLANET_EXPLOSION'
