import { useAppRoutes } from "hooks/routes/useAppRoutes"
import { Link } from "react-router-dom"

export const Navigation = () => {

    const { nextUrl, prevUrl } = useAppRoutes()
    
    return (
        <div className="hidden md:block absolute">
            <Link to={prevUrl || '#'}>
                <button className="fixed top-[50%] translate-y-[-50%] left-8 z-[9999]" type="button" disabled={prevUrl === undefined}>
                    <img
                        src="/assets/img/icons/left-arrow.svg"
                        className={`bg-primary text-secondary rounded-full h-10 ${prevUrl === undefined && 'opacity-30'}`}
                    />
                </button>
            </Link>

            <Link to={nextUrl || '#'}>
                <button className="fixed top-[50%] translate-y-[-50%] right-8 z-[9999]" type="button" disabled={nextUrl === undefined}>
                    <img
                        src="/assets/img/icons/right-arrow.svg"
                        className={`bg-primary text-secondary rounded-full h-10 ${nextUrl === undefined && 'opacity-30'}`}
                    />
                </button>
            </Link>
        </div>
    )
}
