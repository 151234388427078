export const VideoSkeleton = ({src, title}: {src: string, title: string}) => {
    return (
            <>
                {src && (
                    <div className="flex w-full h-full relative">
                        <img src="/assets/img/icons/Spin-1s-200px.gif" className="absolute top-[50%] left-[50%] w-[100px] z-[2]" style={{transform: 'translate(-50%, -50%)'}} alt={title}/> 
                        <img src={src} alt={title} width="100%" height="100%" className="animate__animated animate__fadeIn"/>
                    </div>
                    )
                }
            </>
    )
}
