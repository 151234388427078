import { useGalleryPicturesList } from "../hooks/useGalleryPicturesList";
import { Swiper, SwiperSlide } from "swiper/react";
import { useCallback, useRef } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useTranslations } from "hooks/translation";
import { FreeMode } from "swiper";


const Pictures = () => {

    const [
        CREDIT
    ] = useTranslations(i18nKeys)
    const pictures = useGalleryPicturesList()
    const noDisplayNextPict = useRef(false)
    const particlesInit = useCallback(async (engine: any) => {
        // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    return (
        <section className="flex sm:justify-center items-center flex-1 w-full p-5 xl:p-0">
            <div className="swiper-container picture-gallery-container relative flex h-full w-full xl:w-[80vw]">
                <Swiper
                    className="w-full h-full sm:h-[240px] lg:h-full"
                    breakpoints={{
                        320: {
                            slidesPerView: 1.2,
                        },
                        540: {
                            slidesPerView: 4.5,
                        },
                        768: {
                            slidesPerView: 5,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                        1280: {
                            slidesPerView: 4,
                        },
                    }}
                    modules={[FreeMode]}
                    freeMode
                >
                    {
                        pictures.map((picture, index) => {

                            if (noDisplayNextPict.current) {
                                noDisplayNextPict.current = false
                                return <></>;
                            }

                            if (picture.height < 1 && (pictures[index + 1]?.height + picture.height) <= 1) {
                                noDisplayNextPict.current = true
                            }
                            return (
                                <SwiperSlide className="relative" key={index}>
                                    <div style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 9 }}>
                                        <Particles id={`picture${index}`} className="w-full h-full particles-picture opacity-[0]" url={'/assets/json/particlesjs-picture-config.json'} init={particlesInit} />
                                    </div>
                                    <div className="flex flex-col justify-between gap-[15px] animate__animated animate__fadeIn" style={{ animationDelay: (100 * index).toString().concat('ms') }}>
                                        <img
                                            src={picture.src}
                                            className="h-full object-cover animate__animated animate__fadeIn"
                                            loading="lazy"
                                            alt={picture.title}
                                        />
                                        {
                                            (pictures[index + 1] && (pictures[index + 1].height + (picture.height) <= 1)) &&
                                            <img
                                                src={pictures[index + 1].src}
                                                className="object-cover h-[250px] w-full"
                                                style={{ height: `calc(100% * ${pictures[index + 1].height > 1 ? 1 : pictures[index + 1].height})` }}
                                                alt={picture.title}
                                            />
                                        }
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
                <span className="absolute top-2 left-[100%] ml-8 rotate-90 origin-top-left whitespace-nowrap text-primary">
                    {CREDIT}
                </span>
            </div>
        </section>
    )
}

const i18nKeys = [
    'App.Pictures.Credits.Title'
]

export default Pictures;